.articles-list {
  padding-bottom: var(--spacing-08);
}

.articles-list__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* csslint-disable-next-line */
.articles-list__container .article-card {
  margin-bottom: 50px;
  margin-top: 0;
  width: 100%;
}

@media screen and (min-width: 768px) {
  /* csslint-disable-next-line */
  .articles-list__container .article-card {
    width: 48%;
  }
}

.articles-list__pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.articles-list__button {
  background-color: var(--teal-04);
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: block;
  font-family: var(--ff--sans-medium);
  font-weight: normal;
  height: 48px;
  top: 20px;
  width: 48px;
}

.articles-list__previous {
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='22' viewBox='0 0 13 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 20L2 11L11 2' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: calc(100% - 19px) center;
  margin-right: var(--spacing-05);
}

.articles-list__previous--disabled {
  background-color: var(--grey-02);
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='22' viewBox='0 0 13 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 19.6533L2 10.6533L11 1.65332' stroke='%23CCCCCC' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  pointer-events: none;
}

.articles-list__next {
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='22' viewBox='0 0 13 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 20L11 11L2 2' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: calc(100% - 16px) center;
  margin-left: var(--spacing-05);
}

.articles-list__next--disabled {
  background-color: var(--grey-02);
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='22' viewBox='0 0 13 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 20L11 11L2 2' stroke='%23CCCCCC' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  pointer-events: none;
}
