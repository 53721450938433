.featured-article {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 80%,
    rgba(247, 247, 247, 1) 100%
  );
  padding-bottom: 100px;
}

@media screen and (min-width: 769px) {
  .featured-article {
    padding-bottom: 180px;
  }
}

@media screen and (min-width: 1200px) {
  .featured-article {
    padding-bottom: 272px;
  }
}

.featured-article--extra-padding {
  padding: 160px 0;
}

.featured-article__content {
  display: flex;
  flex-direction: column;
  margin: 0 var(--spacing-sides);
}

@media screen and (min-width: 800px) {
  .featured-article__content {
    align-items: center;
    height: 485px;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.featured-article__info-box {
  background-color: var(--teal-07);
  border-radius: 16px;
  font-family: var(--ff--sans-medium);
  margin-top: -50px;
  order: 2;
  padding: 40px 30px;
  position: relative;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  .featured-article__info-box {
    flex: 0 0 46%;
    height: 100%;
    margin-top: 0;
    order: 1;
    padding: 40px 90px 80px 48px;
  }
}

@media screen and (min-width: 1100px) {
  .featured-article__info-box {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1300px) {
  .featured-article__info-box {
    padding-top: 80px;
  }
}

.featured-article__category {
  color: var(--teal-03);
  font-size: var(--fs--ultra-small);
  margin: 0 0 var(--spacing-04) 0;
}

.featured-article__title {
  color: white;
  font-size: var(--fs--medium);
  font-weight: 500;
  line-height: var(--lh--133);
  margin: 0 0 var(--spacing-04);
}

.featured-article__date {
  color: var(--teal-02);
  display: block;
  font-size: var(--fs--micro);
  letter-spacing: 0.06em;
  margin: 0 0 var(--spacing-04);
  text-transform: uppercase;
}

.featured-article__excerpt {
  color: white;
  font-family: var(--ff--sans);
  line-height: var(--lh--150);
  margin: 0 0 var(--spacing-05);
}

.featured-article__button {
  background-color: var(--teal-06);
  border-radius: 16px;
  color: white;
  display: inline-block;
  font-size: var(--fs--super-small);
  font-weight: 500;
  line-height: var(--lh--150);
  min-height: 48px;
  margin: 0;
  padding: 11px 24px 12px;
  text-decoration: none;
  transition: background-color 0.1s ease-in;
}

.featured-article__button:hover {
  background-color: var(--teal-05);
}

.featured-article__image-container {
  height: 230px;
  margin: auto;
  width: 95%;
}

@media screen and (min-width: 800px) {
  .featured-article__image-container {
    flex: 0 0 54%;
    height: calc(100% - 14px);
    margin: unset;
    margin-left: -1%;
    order: 2;
    width: 100%;
  }
}

.featured-article__image {
  border-radius: 8px;
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
