.article-card {
  position: relative;
  width: 310px;
  height: 530px;
  border-radius: 0 0 8px 8px;
  overflow-y: hidden;
  display: block;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .article-card {
    width: 352px;
    height: 550px;
  }
}

.article-card::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 80px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 38%,
    rgba(255, 255, 255, 0) 100%
  );
}

.article-card__image-wrapper {
  height: 214px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .article-card__image-wrapper {
    height: 234px;
  }
}

.article-card__image {
  border-radius: 8px 8px 0 0;
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.article-card__content {
  background-color: white;
  border-radius: 0 0 8px 8px;
  font-family: var(--ff--sans-medium);
  min-height: 310px;
  padding: 24px 24px 28px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .article-card__content {
    padding: 24px 32px 48px;
  }
}

.article-card__topic {
  color: var(--teal-04);
  font-size: var(--fs--ultra-small);
}

.article-card__title {
  font-size: var(--fs--extra-small);
  line-height: 27px;
  font-weight: 500;
  color: var(--grey-09);
}

.article-card__date {
  color: var(--grey-06);
  font-size: var(--fs--micro);
  letter-spacing: 0.06em;
  opacity: 0.4;
  text-transform: uppercase;
}

.article-card__excerpt {
  color: var(--grey-06);
  font-family: var(--ff--sans);
  font-size: var(--fs--ultra-small);
  line-height: 21px;
  letter-spacing: var(--ls--default);
}
