.people-rail {
  margin-bottom: var(--spacing-08);
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

.people-rail__content {
  padding: 0 var(--spacing-sides);
  position: relative;
}

/*
.people-rail__content .section-intro {
  max-width: var(--width-regular);
  margin: 0 auto;
  text-align: center;
}
*/

.people-rail__content .people-rail__section-intro {
  padding-left: 0;
  padding-right: 0;
}

.people-rail__rail {
  position: relative;
}

/* csslint-disable-next-line */
.people-rail__rail .rail {
  padding-top: 21px;
}

.people-rail::after {
  background: linear-gradient(
    90.99deg,
    rgba(247, 247, 247, 0) 4.45%,
    #f7f7f7 95.14%
  );
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 96px;
  z-index: 1;
}

.people-rail__item {
  opacity: 1;
  height: 432px;
  margin: 0 16px 40px 0;
  width: 256px;
  transition: opacity 0.2s ease-in;
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .people-rail__item {
  position: absolute;
  top: 21px;
  opacity: 0;
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .people-rail__item:nth-child(1) {
  opacity: 1;
  top: 0;
  position: relative;
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .people-rail__item:nth-child(2) {
  opacity: 1;
  transform: translateX(calc((100% + 16px) * 1));
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .people-rail__item:nth-child(3) {
  opacity: 0.8;
  transform: translateX(calc((100% + 16px) * 2));
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .people-rail__item:nth-child(4) {
  opacity: 0.6;
  transform: translateX(calc((100% + 16px) * 3));
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .people-rail__item:nth-child(5) {
  opacity: 0.4;
  transform: translateX(calc((100% + 16px) * 4));
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .people-rail__item:nth-child(6) {
  opacity: 0.2;
  transform: translateX(calc((100% + 16px) * 5));
}
