.feature-boxes {
  margin-bottom: var(--spacing-08);
  padding: 0 var(--spacing-sides);
}

.feature-boxes__features {
  column-gap: 16px;
  display: grid;
  grid-column: span 3;
  grid-template-columns: var(--grid-columns);
}

.feature-boxes__feature {
  grid-column: span 8;
  margin-bottom: 16px;
}

@media screen and (min-width: 1024px) {
  .feature-boxes__feature {
    grid-column: span 4;
  }
}

@media screen and (min-width: 1280px) {
  .feature-boxes__feature {
    grid-column: span 6;
  }
}
