/* The following class names are referenced as React classNames in /assets/js/nba.js */

.nba {
  align-items: center;
  background-color: var(--teal-07);
  bottom: 0;
  color: white;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  margin-bottom: 80px;
  overflow-y: auto;
  padding: 32px;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  transform: translateY(1000%);
  transition: transform 0.4s ease-in-out;
  z-index: 11;
}

@media screen and (min-width: 1000px) {
  .nba {
    transform: none;
    transition: transform 0.2s ease-in-out;
    z-index: 1;
  }
}

@media screen and (min-width: 1000px) {
  .nba--navigation-visible {
    transform: translateY(80px);
    margin-bottom: 160px;
  }
}

.nba--active {
  transform: translateY(0);
}

@media screen and (min-width: 1000px) {
  .nba--active {
    transform: none;
  }
}

@media screen and (min-width: 1000px) {
  .nba {
    border-radius: 16px;
    height: auto;
    margin-left: auto;
    max-width: 320px;
    min-height: 432px;
    overflow: auto;
    position: sticky;
    /* stylelint-disable */
    position: -webkit-sticky;
    top: 2em;
    /* stylelint-endable */
  }
}

.nba__close {
  background-color: var(--teal-04);
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6L6 18' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6 6L18 18' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  height: 40px;
  position: absolute;
  right: 16px;
  top: 16px;
  transition: background-color 0.1s ease-in;
  width: 40px;
}

@media screen and (min-width: 1000px) {
  .nba__close {
    display: none;
  }
}

.nba__close:hover {
  background-color: var(--teal-05);
}

.nba__content {
  width: 100%;
}

.nba__kicker {
  color: var(--teal-04);
  display: inline-block;
  font-size: var(--fs--micro);
  font-weight: 600;
  line-height: var(--lh--150);
  margin-bottom: 8px;
  text-transform: uppercase;
}

.nba__headline {
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--extra-small);
  font-weight: normal;
  line-height: var(--lh--150);
  margin: 0 0 16px;
}

.nba__text {
  font-size: var(--fs--ultra-small);
  line-height: var(--lh--150);
  margin-bottom: 32px;
}

/* csslint-disable-next-line */
.nba__text > p {
  margin: 0;
}

/* csslint-disable-next-line */
.nba__text a {
  color: var(--teal-04);
  text-decoration: none;
}

.nba__text--help {
  margin-bottom: 0;
}

.nba__dropdown,
.nba__input,
.nba__autosuggest {
  margin-bottom: 32px;
}

.nba__button {
  background-color: var(--teal-04);
  border-radius: 16px;
  color: white;
  display: inline-block;
  font-size: var(--fs--super-small);
  font-weight: 600;
  line-height: var(--lh--150);
  margin-bottom: 24px;
  min-height: 48px;
  padding: 16px 24px 16px;
  text-decoration: none;
  transition: background-color 0.1s ease-in;
  width: 100%;
}

.nba__button:last-child {
  margin-bottom: 0;
}

.nba__button[aria-disabled="false"]:hover {
  background-color: var(--teal-05);
}

.nba__button[aria-disabled="true"] {
  opacity: 0.2;
}
