.team-list {
  margin-bottom: var(--spacing-08);
}

.team-list__panel {
  background-color: white;
  border-radius: 8px;
  margin-bottom: var(--spacing-04);
  padding: 24px;
}

@media screen and (min-width: 767px) {
  .team-list__panel {
    display: flex;
    padding: 32px;
  }

  .team-list__content {
    flex: 1 1 60%;
  }

  .team-list__photo {
    flex: 1 0 40%;
  }
}

.team-list__panel:last-child {
  margin-bottom: 0;
}

.team-list__photo {
  margin: 0 auto var(--spacing-05);
  max-width: 280px;
  align-self: flex-start;
}

@media screen and (min-width: 767px) {
  .team-list__photo {
    margin: 0 48px 0 0;
  }
}

/* csslint-disable-next-line */
.team-list__photo img {
  border-radius: 6px;
  display: block;
  width: 100%;
}

.team-list__name {
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--extra-small);
  font-weight: normal;
  line-height: var(--lh--150);
  margin: 0 0 var(--spacing-03);
}

.team-list__job-title {
  color: var(--teal-05);
  display: block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--micro);
  font-weight: normal;
  line-height: var(--lh--150);
  text-transform: uppercase;
}

.team-list__text {
  color: var(--grey-06);
  font-size: var(--fs--ultra-small);
  line-height: var(--lh--150);
}

/* csslint-disable-next-line */
.team-list__text p a {
  color: var(--teal-05);
  text-decoration: none;
}
