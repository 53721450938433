.long-copy {
  color: var(--grey-07);
  margin-bottom: var(--spacing-08);
}

.long-copy__content {
  margin: var(--spacing-sides) 0;
}

.long-copy--full-width .long-copy__content {
  margin: 0 var(--spacing-sides);
  padding-bottom: var(--spacing-08);
  padding-top: var(--spacing-08);
}

@media screen and (min-width: 800px) {
  /*
  .long-copy--two-col .long-copy__content {
    padding-left: var(--spacing-05);
    padding-right: var(--spacing-05);
  }
  */
}

/* csslint-disable-next-line */
.long-copy__intro h2 {
  font-family: var(--ff--sans);
  font-size: var(--fs--medium);
  line-height: var(--lh--150);
  margin-top: 80px;
  margin-bottom: 80px;
  font-weight: 400;
}

.long-copy__text {
  margin-bottom: 128px;
}

.long-copy--full-width .long-copy__text {
  max-width: 800px;
}

/* csslint-disable-next-line */
.long-copy__text p {
  font-size: var(--fs--extra-small);
  line-height: var(--lh--150);
}

/* csslint-disable-next-line */
.long-copy__text strong {
  font-family: var(--ff--sans-medium);
  font-weight: normal;
}

/* csslint-disable-next-line */
.long-copy__text a {
  color: var(--grey-07);
  border-bottom: 2px solid var(--teal-04);
  text-decoration: none;
  transition: color var(--link-transition) ease-in-out;
}

/* csslint-disable-next-line */
.long-copy__text a:hover,
/* csslint-disable-next-line */
.long-copy__text a:focus {
  color: var(--teal-05);
}

/* csslint-disable-next-line */
.long-copy__text u {
  display: inline-block;
  text-decoration: none;
  border-bottom: 2px solid var(--grey-07);
}

/* csslint-disable-next-line */
.long-copy__text del {
  display: inline-block;
  text-decoration: none;
  position: relative;
}

/* csslint-disable-next-line */
.long-copy__text del::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 2px solid var(--grey-07);
  height: calc(50% - 1px);
  width: 100%;
}

/* csslint-disable-next-line */
.long-copy__text h3 {
  font-family: var(--ff--serif);
  font-size: var(--fs--medium-serif);
  margin-top: var(--spacing-08);
  margin-bottom: 32px;
  font-weight: 400;
}

/* csslint-disable-next-line */
.long-copy__text blockquote {
  font-family: var(--ff--serif-medium);
  font-size: var(--fs--medium-serif);
  border-left: 2px solid var(--teal-05);
  padding: 16px 32px;
  color: var(--grey-08);
  margin-top: var(--spacing-08);
  margin-bottom: var(--spacing-08);
  margin-left: 0;
  margin-right: 0;
}

/* csslint-disable-next-line */
.long-copy__text ul,
/* csslint-disable-next-line */
.long-copy__text ol {
  font-size: var(--fs--extra-small);
  line-height: var(--lh--150);
}

/* csslint-disable-next-line */
.long-copy__text ul {
  position: relative;
  list-style-type: none;
}

/* csslint-disable-next-line */
.long-copy__text li {
  margin-bottom: 8px;
}

/* csslint-disable-next-line */
.long-copy__text ul li::before {
  position: absolute;
  left: 1em;
  content: "\2022 ";
  color: var(--teal-05);
  padding-right: 0.5em;
}

/* csslint-disable-next-line */
.long-copy__text ol {
  position: relative;
  counter-reset: li;
  list-style-type: none;
}

/* csslint-disable-next-line */
.long-copy__text ol li {
  counter-increment: li;
}

/* csslint-disable-next-line */
.long-copy__text ol li::before {
  position: absolute;
  right: calc(100% - 2em);
  content: counter(li) ".";
  color: var(--teal-05);
  padding-right: 0.5em;
  font-family: var(--ff--sans-medium);
}

/* csslint-disable-next-line */
.long-copy__text figure {
  margin: 30px 0;
  width: 100%;
}

@media screen and (min-width: 800px) {
  /* csslint-disable-next-line */
  .long-copy__text figure {
    margin-top: 110px;
    margin-bottom: 100px;
    margin-left: calc(-1 * var(--spacing-05));
    margin-right: calc(-1 * var(--spacing-05));
  }
}

/* csslint-disable-next-line */
.long-copy__text img {
  width: 100%;
}

@media screen and (min-width: 800px) {
  /* csslint-disable-next-line */
  .long-copy__text img {
    width: calc(100% + var(--spacing-05) + var(--spacing-05));
  }
}

/* csslint-disable-next-line */
.long-copy__text figcaption {
  margin-top: 24px;
}
