.how-to {
  max-width: var(--width-large);
  width: 100%;
  margin: 0 auto;
  margin-bottom: var(--spacing-08);
}

.how-to__wrapper {
  padding-left: var(--spacing-sides);
  padding-right: var(--spacing-sides);
}

/* csslint-disable-next-line */
.how-to__wrapper > .section-intro {
  padding-bottom: 0;
  margin-bottom: var(--spacing-09);
}

.how-to__steps {
  display: grid;
  gap: var(--spacing-08);
  grid-template-columns: repeat(1, minmax(10px, 1fr));
}

@media (min-width: 768px) {
  .how-to__steps {
    grid-template-columns: repeat(2, minmax(10px, 1fr));
  }
}

@media (min-width: 1554px) {
  .how-to__steps {
    gap: var(--spacing-05);
    grid-template-columns: repeat(4, minmax(10px, 1fr));
  }
}

.how-to__step-wrapper {
  padding: var(--spacing-06);
  position: relative;
  color: var(--grey-09);
  border-radius: 16px;
  box-shadow: var(--box-shadow-2);
  background-color: var(--grey-01);
}

.how-to__step-title {
  text-align: center;
  font-size: var(--fs--small);
  font-weight: 700;
}

.how-to__step-counter {
  background-color: var(--teal-06);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 99px;
  user-select: none;
  position: absolute;
  top: -25px;
  left: calc(50% - 25px);
}

.how-to__step-counter-value {
  font-weight: bold;
  color: var(--grey-01);
  font-size: 29px;
  font-family: var(--ff--sans);
  margin-bottom: 2px; /** Visueller Ausgleich */
}

.how-to__step-image {
  display: block;
  margin: var(--spacing-05) 0;
}

.how-to__step-image-src {
  width: 100%;
  display: block;
  height: auto;
}

.how-to__step-copy {
  font-size: var(--fs--extra-small);
  line-height: var(--lh--150);
  color: var(--grey-09);
}

/* csslint-disable-next-line */
.how-to__step-copy > *:first-child {
  margin-top: 0;
}

/* csslint-disable-next-line */
.how-to__step-copy > *:last-child {
  margin-bottom: 0;
}

.how-to__step-list {
  margin-top: var(--spacing-05);
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-04);
}

.how-to__step-list-item-link {
  color: var(--grey-07);
  display: inline;
  line-height: 1.5;
  text-decoration: none;
  border-bottom: 2px solid var(--teal-06);
  transition: color var(--link-transition) ease-in-out;
}

.how-to__step-list-item-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.how-to__step-list-item-link:hover,
.how-to__step-list-item-link:focus {
  color: var(--teal-06);
}

.how-to__step-list-item {
  align-items: center;
  display: flex;
  position: relative;
}

.how-to__step-list-item-icon {
  background-color: var(--grey-04);
  background-position: center center;
  background-size: 48px 48px;
  border-radius: 50%;
  height: 48px;
  margin-right: 16px;
  min-width: 48px;
  width: 48px;
}

.how-to__step-list-item-icon--app {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ4IDI0QzQ4IDEwLjc0NTIgMzcuMjU0OCAwIDI0IDBDMTAuNzQ1MiAwIDAgMTAuNzQ1MiAwIDI0QzAgMzcuMjU0OCAxMC43NDUyIDQ4IDI0IDQ4QzM3LjI1NDggNDggNDggMzcuMjU0OCA0OCAyNFoiIGZpbGw9IiNGN0Y3RjciLz4KPHBhdGggZD0iTTI5IDE0SDE5QzE3Ljg5NTQgMTQgMTcgMTQuODk1NCAxNyAxNlYzMkMxNyAzMy4xMDQ2IDE3Ljg5NTQgMzQgMTkgMzRIMjlDMzAuMTA0NiAzNCAzMSAzMy4xMDQ2IDMxIDMyVjE2QzMxIDE0Ljg5NTQgMzAuMTA0NiAxNCAyOSAxNFoiIHN0cm9rZT0iIzFBMUExQSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMjQgMzBIMjQuMDEiIHN0cm9rZT0iIzFBMUExQSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
}

.how-to__step-list-item-icon--phone {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ4IDI0QzQ4IDEwLjc0NTIgMzcuMjU0OCAwIDI0IDBDMTAuNzQ1MiAwIDAgMTAuNzQ1MiAwIDI0QzAgMzcuMjU0OCAxMC43NDUyIDQ4IDI0IDQ4QzM3LjI1NDggNDggNDggMzcuMjU0OCA0OCAyNFoiIGZpbGw9IiNGN0Y3RjciLz4KPHBhdGggZD0iTTMzLjk5OTQgMjguOTJWMzEuOTJDMzQuMDAwNiAzMi4xOTg1IDMzLjk0MzUgMzIuNDc0MiAzMy44MzIgMzIuNzI5NEMzMy43MjA0IDMyLjk4NDUgMzMuNTU2NyAzMy4yMTM2IDMzLjM1MTUgMzMuNDAxOUMzMy4xNDYzIDMzLjU5MDEgMzIuOTA0IDMzLjczMzUgMzIuNjQwMiAzMy44MjI3QzMyLjM3NjQgMzMuOTExOSAzMi4wOTY4IDMzLjk0NTEgMzEuODE5NCAzMy45MkMyOC43NDIzIDMzLjU4NTYgMjUuNzg2NCAzMi41MzQyIDIzLjE4OTQgMzAuODVDMjAuNzczMyAyOS4zMTQ3IDE4LjcyNDggMjcuMjY2MiAxNy4xODk0IDI0Ljg1QzE1LjQ5OTQgMjIuMjQxMiAxNC40NDc3IDE5LjI3MSAxNC4xMTk0IDE2LjE4QzE0LjA5NDUgMTUuOTAzNSAxNC4xMjczIDE1LjYyNDggMTQuMjE1OSAxNS4zNjE2QzE0LjMwNDYgMTUuMDk4NSAxNC40NDcgMTQuODU2NyAxNC42MzQyIDE0LjY1MTZDMTQuODIxNCAxNC40NDY2IDE1LjA0OTIgMTQuMjgyNyAxNS4zMDMyIDE0LjE3MDVDMTUuNTU3MiAxNC4wNTgzIDE1LjgzMTggMTQuMDAwMyAxNi4xMDk0IDE0SDE5LjEwOTRDMTkuNTk0OCAxMy45OTUyIDIwLjA2NTIgMTQuMTY3MSAyMC40MzMyIDE0LjQ4MzVDMjAuODAxMiAxNC44IDIxLjA0MTUgMTUuMjM5NSAyMS4xMDk0IDE1LjcyQzIxLjIzNjEgMTYuNjgwMSAyMS40NzA5IDE3LjYyMjcgMjEuODA5NCAxOC41M0MyMS45NDQgMTguODg3OSAyMS45NzMxIDE5LjI3NjkgMjEuODkzNCAxOS42NTA5QzIxLjgxMzYgMjAuMDI0OSAyMS42MjgzIDIwLjM2ODEgMjEuMzU5NCAyMC42NEwyMC4wODk0IDIxLjkxQzIxLjUxMyAyNC40MTM2IDIzLjU4NTkgMjYuNDg2NSAyNi4wODk0IDI3LjkxTDI3LjM1OTQgMjYuNjRDMjcuNjMxMyAyNi4zNzExIDI3Ljk3NDYgMjYuMTg1OSAyOC4zNDg2IDI2LjEwNjFDMjguNzIyNSAyNi4wMjYzIDI5LjExMTUgMjYuMDU1NSAyOS40Njk0IDI2LjE5QzMwLjM3NjcgMjYuNTI4NiAzMS4zMTk0IDI2Ljc2MzQgMzIuMjc5NCAyNi44OUMzMi43NjUyIDI2Ljk1ODUgMzMuMjA4OCAyNy4yMDMyIDMzLjUyNiAyNy41Nzc1QzMzLjg0MzEgMjcuOTUxOCAzNC4wMTE2IDI4LjQyOTYgMzMuOTk5NCAyOC45MloiIHN0cm9rZT0iIzFBMUExQSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
}

.how-to__step-list-item-icon--mail {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ4IDI0QzQ4IDEwLjc0NTIgMzcuMjU0OCAwIDI0IDBDMTAuNzQ1MiAwIDAgMTAuNzQ1MiAwIDI0QzAgMzcuMjU0OCAxMC43NDUyIDQ4IDI0IDQ4QzM3LjI1NDggNDggNDggMzcuMjU0OCA0OCAyNFoiIGZpbGw9IiNGN0Y3RjciLz4KPHBhdGggZD0iTTE2IDE2SDMyQzMzLjEgMTYgMzQgMTYuOSAzNCAxOFYzMEMzNCAzMS4xIDMzLjEgMzIgMzIgMzJIMTZDMTQuOSAzMiAxNCAzMS4xIDE0IDMwVjE4QzE0IDE2LjkgMTQuOSAxNiAxNiAxNloiIHN0cm9rZT0iIzFBMUExQSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMzQgMThMMjQgMjVMMTQgMTgiIHN0cm9rZT0iIzFBMUExQSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
}

.how-to__step-list-item-icon--location {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ4IDI0QzQ4IDEwLjc0NTIgMzcuMjU0OCAwIDI0IDBDMTAuNzQ1MiAwIDAgMTAuNzQ1MiAwIDI0QzAgMzcuMjU0OCAxMC43NDUyIDQ4IDI0IDQ4QzM3LjI1NDggNDggNDggMzcuMjU0OCA0OCAyNFoiIGZpbGw9IiNGN0Y3RjciLz4KPHBhdGggZD0iTTMzIDIyQzMzIDI5IDI0IDM1IDI0IDM1QzI0IDM1IDE1IDI5IDE1IDIyQzE1IDE5LjYxMzEgMTUuOTQ4MiAxNy4zMjM5IDE3LjYzNiAxNS42MzZDMTkuMzIzOSAxMy45NDgyIDIxLjYxMzEgMTMgMjQgMTNDMjYuMzg2OSAxMyAyOC42NzYxIDEzLjk0ODIgMzAuMzY0IDE1LjYzNkMzMi4wNTE4IDE3LjMyMzkgMzMgMTkuNjEzMSAzMyAyMloiIHN0cm9rZT0iIzFBMUExQSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMjQgMjVDMjUuNjU2OSAyNSAyNyAyMy42NTY5IDI3IDIyQzI3IDIwLjM0MzEgMjUuNjU2OSAxOSAyNCAxOUMyMi4zNDMxIDE5IDIxIDIwLjM0MzEgMjEgMjJDMjEgMjMuNjU2OSAyMi4zNDMxIDI1IDI0IDI1WiIgc3Ryb2tlPSIjMUExQTFBIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
}

.how-to__step-select-options {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-08) var(--spacing-06);
}

.how-to__step-select-optionsList {
  padding: var(--spacing-04);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  overflow: auto;
  list-style: none;
  background-color: var(--grey-03);
  width: 100%;
  margin: 0;
  position: relative;
  height: 100%;
}

@media (min-width: 768px) {
  .how-to__step-select-optionsList {
    width: 500px;
    max-height: 400px;
  }
}

.how-to__step-select-optionsList::before {
  content: "";
  background-image: var(--gradient-07);
  display: block;
  width: 100%;
  height: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.how-to__step-select-optionsListItem {
  position: relative;
  margin: var(--spacing-02) 0;
}

.how-to__step-select-optionsListItem:first-child {
  margin-top: 0;
}

.how-to__step-select-optionsListItem:last-child {
  margin-bottom: 0;
}

.how-to__step-select-options[hidden] {
  display: none;
}

/* csslint-disable-next-line */
.how-to__step-list-item-wrapper > .buttons {
  margin-bottom: 0;
}

/* csslint-disable-next-line */
.how-to__step-list-item-wrapper > .buttons .buttons__links button {
  margin: 0;
}
