.action-links {
  margin-bottom: var(--spacing-08);
}

.action-links .action-links__section-intro {
  padding-left: 0;
  padding-right: 0;
}

.action-links--full-width {
  margin: 0 var(--spacing-sides) var(--spacing-08);
}

.action-links__links {
  list-style: none;
  padding: 0;
}

.action-links__link-item {
  margin-top: var(--spacing-03);
}

@media screen and (min-width: 800px) {
  .action-links__link-item {
    margin-top: var(--spacing-05);
  }
}

.action-links__link-item:first-child {
  margin-top: 0;
}

.action-links__link {
  border-bottom: 2px solid var(--grey-04);
  color: var(--grey-07);
  display: block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--extra-small);
  font-weight: normal;
  padding-bottom: var(--spacing-04);
  padding-top: var(--spacing-04);
  position: relative;
  text-decoration: none;
  transition: color 0.1s ease-in;
}

@media screen and (min-width: 800px) {
  .action-links__link {
    /* margin-left: 16px; */

    /* margin-right: 16px; */
    padding-bottom: var(--spacing-04);
    padding-left: 16px;
    padding-right: 16px;
    padding-top: var(--spacing-04);
  }
}

.action-links__link:hover {
  color: var(--teal-06);
}

.action-links__file-info {
  color: var(--grey-06);
  font-size: var(--fs--ultra-small);
  font-weight: 400;
}

@media screen and (min-width: 800px) {
  .action-links__file-info {
    font-size: var(--fs--extra-small);
  }
}

.action-links__icon::after {
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 32px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
}

.action-links__icon--download::after {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28 20V25.3333C28 26.0406 27.719 26.7189 27.219 27.219C26.7189 27.719 26.0406 28 25.3333 28H6.66667C5.95942 28 5.28115 27.719 4.78105 27.219C4.28095 26.7189 4 26.0406 4 25.3333V20' stroke='%23006861' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.33301 13.3335L15.9997 20.0002L22.6663 13.3335' stroke='%23006861' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 20V4' stroke='%23006861' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.action-links__icon--arrow::after {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.0003 29.3332C23.3641 29.3332 29.3337 23.3636 29.3337 15.9998C29.3337 8.63604 23.3641 2.6665 16.0003 2.6665C8.63653 2.6665 2.66699 8.63604 2.66699 15.9998C2.66699 23.3636 8.63653 29.3332 16.0003 29.3332Z' stroke='%23006861' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 21.3332L21.3333 15.9998L16 10.6665' stroke='%23006861' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.667 16H21.3337' stroke='%23006861' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.action-links__icon--calendar::after {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.3333 5.3335H6.66667C5.19391 5.3335 4 6.5274 4 8.00016V26.6668C4 28.1396 5.19391 29.3335 6.66667 29.3335H25.3333C26.8061 29.3335 28 28.1396 28 26.6668V8.00016C28 6.5274 26.8061 5.3335 25.3333 5.3335Z' stroke='%23006861' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21.333 2.6665V7.99984' stroke='%23006861' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.667 2.6665V7.99984' stroke='%23006861' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4 13.3335H28' stroke='%23006861' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
