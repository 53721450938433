.authors {
  padding-bottom: var(--spacing-08);
}

.authors__content {
  margin: var(--spacing-sides) 0;
}

.authors__headline {
  font-family: var(--ff--serif);
  font-size: var(--fs--medium-serif);
  font-weight: normal;
  margin-bottom: 64px;
}

/** csslint-disable-next-line */
.authors__headline a {
  color: var(--teal-05);
}

.authors__author {
  align-items: center;
  display: flex;
}

@media screen and (max-width: 767px) {
  .authors__author {
    align-items: flex-start;
    flex-direction: column;
  }
}

.authors__author:not(:first-child) {
  margin-top: 100px;
}

.authors__image {
  border-radius: 6px;
  display: block;
  margin-bottom: 20px;
  width: 208px;
}

@media screen and (min-width: 768px) {
  .authors__image {
    margin-bottom: 0;
    margin-right: var(--spacing-06);
  }
}

.authors__author-info {
  max-width: 382px;
}

.authors__name {
  display: block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--extra-small);
  margin-bottom: 16px;
}

.authors__job {
  color: var(--teal-05);
  display: block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--micro);
  margin-bottom: 16px;
  text-transform: uppercase;
}

.authors__description {
  color: var(--grey-06);
  font-size: var(--fs--ultra-small);
  line-height: 21px;
  margin: 0;
}
