.featured-courses {
  margin: 0 var(--spacing-sides) var(--spacing-08);
}

.featured-courses__text {
  margin: 0 auto var(--spacing-06);
  max-width: var(--width-small);
  text-align: center;
}

.featured-courses__coursesWrapper {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: var(--width-regular);
}

.featured-courses__courses {
  --fcci-grid-gap: 12px;

  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-left: calc(var(--fcci-grid-gap) * -1);
  justify-content: center;
}

.featured-courses__course-item {
  --fcci-item-size: calc(100% / 1);

  width: var(--fcci-item-size);
  margin: 0 0 32px var(--fcci-grid-gap);
  flex: 0 0 var(--fcci-item-size);
}

/* csslint-disable-next-line */
.featured-courses__course-item .text-block__buttons {
  text-align: center;
}

@media screen and (min-width: 500px) {
  .featured-courses__course-item {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 1024px) {
  .featured-courses__course-item {
    --fcci-item-size: calc(100% / 2 - var(--fcci-grid-gap));
  }
}

@media screen and (min-width: 1280px) {
  .featured-courses__course-item {
    --fcci-item-size: calc(100% / 3 - var(--fcci-grid-gap));
  }
}

@media screen and (min-width: 1024px) {
  .featured-courses__course-item--centered {
    --fcci-item-size: 100%;
  }
}

@media screen and (min-width: 1280px) {
  .featured-courses__course-item--centered {
    --fcci-item-size: 100%;
  }
}

.featured-courses__course-item:last-child {
  margin-bottom: 0;
}
