/* stylelint-disable custom-property-empty-line-before */
:root {
  /* font-family */
  --ff--sans: Untitled-Sans;
  --ff--sans-medium: Untitled-Sans-Medium;
  --ff--serif: Untitled-Serif;
  --ff--serif-medium: Untitled-Serif-Medium;

  /* letter spacings */
  --ls--extra-dense: 0.005em;
  --ls--dense: 0.0075em;
  --ls--default: 0.01em;
  --ls--loose: 0.0125em;
  --ls--extra-loose: 0.015em;
  --ls--super-loose: 0.0175em;

  /* line height */
  --lh--100: 1;
  --lh--110: 1.1;
  --lh--120: 1.2;
  --lh--133: 1.33;
  --lh--150: 1.5;

  /* font sizes */
  --fs--micro: 0.75rem; /* 12px Mobile + Desktop */
  --fs--micro-serif: calc(var(--fs--micro) * 1.125);

  --fs--ultra-small: 0.875rem; /* 14px Mobile + Desktop */
  --fs--ultra-small-serif: calc(var(--fs--ultra-small) * 1.125);

  --fs--super-small: 1rem; /* 16px Mobile + Desktop */
  --fs--super-small-serif: calc(var(--fs--super-small) * 1.125);

  --fs--extra-small: 1.125rem; /* 18px Mobile + Desktop */
  --fs--extra-small-serif: calc(var(--fs--extra-small) * 1.125);

  --fs--small: 1.3125rem; /* 21px Mobile */
  --fs--small-serif: calc(var(--fs--small) * 1.125);

  --fs--medium: 1.5rem; /* 24px Mobile */
  --fs--medium-serif: calc(var(--fs--medium) * 1.125);

  --fs--large: 1.75rem; /* 28px Mobile */
  --fs--large-serif: calc(var(--fs--large) * 1.125);

  --fs--extra-large: 2rem; /* 32px Mobile */
  --fs--extra-large-serif: calc(var(--fs--extra-large) * 1.125);

  /* --fs--super-large: 2.625rem; */

  /* 42px Mobile */
  --fs--super-large: 2.5rem; /* 40px Mobile */
  --fs--super-large-serif: var(--fs--super-large);

  --fs--ultra-large: 3.5rem; /* 56px Mobile */
  --fs--ultra-large-serif: calc(var(--fs--ultra-large) * 1.125);
}

@media screen and (min-width: 800px) {
  :root {
    --fs--small: 1.9vw; /* 24px Desktop */
    --fs--small-serif: var(--fs--small);
    --fs--medium: 1.6875rem; /* 27px Desktop */
    --fs--medium-serif: var(--fs--medium);
    --fs--large: 2.25rem; /* 36px Desktop */
    --fs--large-serif: var(--fs--large);
    --fs--extra-large: 2.5rem; /* 40px Desktop */
    --fs--extra-large-serif: var(--fs--extra-large);
    --fs--ultra-large: 5.625rem; /* 90px Desktop */
    --fs--ultra-large-serif: var(--fs--ultra-large);
  }
}

@media screen and (min-width: 890px) {
  :root {
    /* --fs--super-large: 4.8vw; */

    /* --fs--super-large-serif: 4.8vw; */
    --fs--super-large: 3rem; /* 60px Desktop */
    --fs--super-large-serif: var(--fs--super-large);
  }
}

@media screen and (min-width: 1280px) {
  :root {
    --fs--small: 1.5rem; /* 24px Desktop */
    --fs--super-large: 3.5rem; /* 60px Desktop */
    --fs--super-large-serif: var(--fs--super-large);
  }
}
