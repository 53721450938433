.stage-section {
  margin-bottom: var(--spacing-08);
  position: relative;
}

@media screen and (min-width: 800px) {
  .stage-section {
    display: block;
    position: relative;
  }
}

.stage-section::before {
  background-image: url("data:image/svg+xml,%3Csvg width='1920' height='48' viewBox='0 0 1920 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1920 25.3503V48H0.00134277C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0C1737.74 0 1810.67 10.1541 1889.44 21.1217C1899.51 22.5244 1909.68 23.9405 1920 25.3503Z' fill='none'/%3E%3Cpath d='M0.00132628 48C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0H0V48H0.00132628Z' fill='%23F7F7F7' fill-opacity='1' /%3E%3Cpath d='M1920 25.3503C1909.68 23.9405 1899.51 22.5244 1889.44 21.1217C1810.67 10.1541 1737.74 0 1646 0H1920V25.3503Z' fill='%23F7F7F7'/%3E%3C/svg%3E%0A");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 150%;
  content: "";
  display: block;
  height: 48px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  .stage-section::before {
    position: absolute;
    top: 0;
  }
}

@media screen and (min-width: 800px) {
  .stage-section::before {
    background-size: cover;
  }
}

.stage-section::after {
  background-image: url("data:image/svg+xml,%3Csvg width='1280' height='48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M892 0C633 0 427.5 77.5 0 31v17h1280C1169.5 7 1041 0 892 0z' fill='%23F7F7F7'/%3E%3C/svg%3E");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 150%;
  bottom: 0;
  content: "";
  display: block;
  height: 48px;
  position: relative;
  width: 100%;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  .stage-section::after {
    bottom: 0;
    position: absolute;
  }
}

@media screen and (min-width: 800px) {
  .stage-section::after {
    background-size: cover;
  }
}

.stage-section__content {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 800px) {
  .stage-section__content {
    align-items: center;
    column-gap: var(--grid-gap);
    display: grid;
    grid-template-columns: var(--grid-columns);
    justify-content: center;
    margin: 0 var(--spacing-sides);
    max-height: 650px;
    min-height: 650px;
  }
}

.stage-section__intro {
  margin: 0 var(--spacing-sides);
  order: 1;
  position: relative;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  .stage-section__intro {
    grid-column: 5 / 9;
    grid-row: 1;
    margin: 0;
  }
}

@media screen and (min-width: 1000px) {
  .stage-section__intro {
    grid-column: 5 / 9;
    padding-left: var(--spacing-05);
  }
}

@media screen and (min-width: 1280px) {
  .stage-section__intro {
    grid-column: 7 / 13;
  }
}

.stage-section__image-container {
  position: relative;
}

@media screen and (min-width: 800px) {
  .stage-section__image-container {
    height: 100%;
    left: 0;
    margin-bottom: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 650px;
  }
}

.stage-section__image-container::after {
  background: linear-gradient(
      0deg,
      rgba(247, 247, 247, 1) 22.85%,
      rgba(255, 255, 255, 0) 100.08%
    ),
    linear-gradient(0deg, var(--grey-02) 13.31%, rgba(255, 255, 255, 0) 99.12%);
  bottom: -1px;
  content: "";
  display: block;
  height: 50%;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
}

@media screen and (min-width: 800px) {
  .stage-section__image-container::after {
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 30%,
        rgba(247, 247, 247, 1) 100.08%
      ),
      linear-gradient(90deg, rgba(255, 255, 255, 0) 13.31%, var(--grey-02) 100%);
    bottom: 0;
    height: 100%;
    top: 0;
    width: 50%;
  }
}

.stage-section__image {
  display: block;
  width: 100%;
}
