.topic-card {
  background-color: white;
  border-radius: 8px;
  font-family: var(--ff--sans-medium);
  height: 496px;
  margin-right: 16px;
  position: relative;
  width: 310px;
  display: block;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .topic-card {
    margin-top: 42px;
    width: 350px;
  }
}

.topic-card__header {
  padding: 32px 32px 0;
  color: var(--grey-09);
}

.topic-card__kicker {
  display: block;
  color: var(--teal-06);
  font-size: var(--fs--ultra-small);
  margin-bottom: var(--spacing-03);
  text-transform: uppercase;
}

.topic-card__headline {
  font-size: var(--fs--small);
  font-weight: 500;
  margin: 0 0 var(--spacing-05);
}

.topic-card__image-wrapper {
  height: 220px;
  width: 100%;
}

.topic-card__image {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.topic-card__excerpt {
  color: var(--grey-06);
  font-family: var(--ff--sans);
  font-size: var(--fs--ultra-small);
  line-height: 21px;
  margin-top: var(--spacing-05);
  padding: 0 32px 48px;
}

/* csslint-disable-next-line */
.topic-card__excerpt p {
  margin: 0;
  max-width: 290px;
}
