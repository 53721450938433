.flickity-viewport {
  position: relative;
}

@media screen and (max-width: 1130px) {
  .flickity-button {
    display: none;
  }
}

.flickity-page-dots {
  text-align: center;
  padding: 0;
  margin-top: 0;
  position: relative;
  z-index: 1;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
