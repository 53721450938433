.intro {
  margin: 0 var(--spacing-sides) var(--spacing-08);
}

/* csslint-disable-next-line */
.intro .text-block {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

/* csslint-disable-next-line */
.intro .text-block.text-block--centered .text-block__text {
  max-width: calc(var(--width-small) * 0.8);
  margin-left: auto;
  margin-right: auto;
}

.intro__image-wrapper {
  max-width: 300px;
  margin: 0 auto var(--spacing-06);
}
