.text-rows {
  margin-bottom: var(--spacing-08);
}

.text-rows--full-width {
  background-color: white;
  position: relative;
}

.text-rows--full-width .text-rows__content {
  margin: 0 var(--spacing-sides);
  padding-bottom: var(--spacing-08);
  padding-top: var(--spacing-08);
}

.text-rows--full-width::before {
  background-image: url("data:image/svg+xml,%3Csvg width='1920' height='48' viewBox='0 0 1920 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1920 25.3503V48H0.00134277C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0C1737.74 0 1810.67 10.1541 1889.44 21.1217C1899.51 22.5244 1909.68 23.9405 1920 25.3503Z' fill='white'/%3E%3Cpath d='M0.00132628 48C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0H0V48H0.00132628Z' fill='%23F7F7F7' fill-opacity='1' /%3E%3Cpath d='M1920 25.3503C1909.68 23.9405 1899.51 22.5244 1889.44 21.1217C1810.67 10.1541 1737.74 0 1646 0H1920V25.3503Z' fill='%23F7F7F7'/%3E%3C/svg%3E%0A");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 150%;
  content: "";
  display: block;
  height: 48px;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  .text-rows--full-width::before {
    background-size: cover;
    position: absolute;
  }
}

.text-rows--full-width::after {
  background-image: url("data:image/svg+xml,%3Csvg width='1920' height='48' viewBox='0 0 1920 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1920 25.3503V48H0.00134277C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0C1737.74 0 1810.67 10.1541 1889.44 21.1217C1899.51 22.5244 1909.68 23.9405 1920 25.3503Z' fill='%23F7F7F7'/%3E%3Cpath d='M0.00132628 48C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0H0V48H0.00132628Z' fill='none' fill-opacity='0' /%3E%3Cpath d='M1920 25.3503C1909.68 23.9405 1899.51 22.5244 1889.44 21.1217C1810.67 10.1541 1737.74 0 1646 0H1920V25.3503Z' fill='%23F7F7F7'/%3E%3C/svg%3E%0A");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 150%;
  bottom: 0;
  content: "";
  display: block;
  height: 48px;
  position: relative;
  width: 100%;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  .text-rows--full-width::after {
    background-size: cover;
    position: absolute;
  }
}

.text-rows__row {
  border-top: 2px solid var(--grey-04);
  column-gap: 16px;
  display: grid;
  grid-template-columns: var(--grid-columns);
  padding: 16px 0;
}

@media screen and (min-width: 800px) {
  .text-rows__row {
    margin-left: 16px;
    margin-right: 16px;
    padding: 32px 16px;
  }
}

.text-rows__row-subline {
  color: var(--grey-05);
  display: block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--micro);
  font-weight: 500;
  line-height: var(--lh--150);
  margin-bottom: var(--spacing-02);
  text-transform: uppercase;
}

.text-rows__row-title {
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--extra-small);
  font-weight: normal;
  grid-column: span 9;
  line-height: var(--lh--150);
  margin: 0 0 8px;
  max-width: 80%;
}

@media screen and (min-width: 1280px) {
  .text-rows__row-title {
    grid-column: span 4;
  }
}

.text-rows--full-width .text-rows__row-title {
  grid-column: span 3;
}

.text-rows__row-text {
  color: #808080;
  font-size: var(--fs--super-small);
  grid-column: span 8;
  line-height: var(--lh--150);
}

.text-rows--full-width .text-rows__row-text {
  grid-column: span 5;
}

@media screen and (min-width: 1280px) {
  .text-rows--full-width .text-rows__row-text {
    grid-column: span 9;
  }
}

/* csslint-disable-next-line */
.text-rows__row-text p {
  margin-top: 0;
}

/* csslint-disable-next-line */
.text-rows__row-text p:last-child {
  margin-bottom: 0;
}

.text-rows__row-link {
  color: var(--teal-04);
  font-family: var(--ff--sans-medium);
  text-decoration: none;
}

.text-rows__row-link::after {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33203 8H12.6654' stroke='%233D3D3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 3.3335L12.6667 8.00016L8 12.6668' stroke='%233D3D3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  content: "";
  display: inline-block;
  height: 16px;
  margin-left: 4px;
  margin-top: -2px;
  vertical-align: middle;
  width: 16px;
}
