.hero-stage {
  background-color: white;
  margin-bottom: var(--spacing-08);
}

/* csslint-disable-next-line */
.hero-stage .text-block ul li::before {
  color: var(--grey-07);
}

@media screen and (min-width: 800px) {
  .hero-stage {
    display: block;
    position: relative;
  }
}

.hero-stage::after {
  background-image: url("data:image/svg+xml,%3Csvg width='1920' height='48' viewBox='0 0 1920 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1920 25.3503V48H0.00134277C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0C1737.74 0 1810.67 10.1541 1889.44 21.1217C1899.51 22.5244 1909.68 23.9405 1920 25.3503Z' fill='%23F7F7F7'/%3E%3Cpath d='M0.00132628 48C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0H0V48H0.00132628Z' fill='red' fill-opacity='0' /%3E%3Cpath d='M1920 25.3503C1909.68 23.9405 1899.51 22.5244 1889.44 21.1217C1810.67 10.1541 1737.74 0 1646 0H1920V25.3503Z' fill='%23F7F7F7'/%3E%3C/svg%3E%0A");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 150%;
  bottom: 0;
  content: "";
  display: block;
  height: 48px;
  position: relative;
  width: 100%;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  .hero-stage::after {
    bottom: 0;
    position: absolute;
  }

  .hero-stage--has-trustbar.hero-stage::after {
    position: inherit;
  }
}

@media screen and (min-width: 800px) {
  .hero-stage::after {
    background-size: cover;
  }
}

.hero-stage--has-trustbar {
  padding-bottom: 0;
}

.hero-stage__content {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: var(--spacing-05);
}

@media screen and (min-width: 800px) {
  .hero-stage__content {
    align-items: center;
    column-gap: var(--grid-gap);
    display: grid;
    grid-template-columns: var(--grid-columns);
    height: 100vh;
    justify-content: center;
    margin: 0 var(--spacing-sides);

    /* max-height: 650px; */

    /* min-height: 650px; */
    max-height: 568px;
    min-height: 568px;
    padding: 0;
  }
}

.hero-stage__intro {
  margin: var(--spacing-06) var(--spacing-sides);
  position: relative;
  text-align: center;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  .hero-stage__intro {
    grid-column: 1 / 5;
    grid-row: 1;
    margin: 0;
    text-align: left;
  }
}

@media screen and (min-width: 1000px) {
  .hero-stage__intro {
    padding-left: var(--spacing-05);
  }
}

@media screen and (min-width: 1280px) {
  .hero-stage__intro {
    grid-column: 1 / 8;
  }
}

.hero-stage__logo {
  height: auto;
  width: 128px;
}

@media screen and (min-width: 800px) {
  .hero-stage__logo {
    height: auto;
    width: 192px;
  }
}

.hero-stage__image-container {
  margin-bottom: -30px;
  position: relative;
  object-fit: cover;
}

.hero-stage__image-container-secondary {
  grid-column: 8 / 12;
}

.hero-stage__image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-stage__image-container-secondary .hero-stage__image {
  max-width: 530px;
  margin: 0 auto;
}

@media screen and (max-width: 1280px) {
  .hero-stage__image-container-secondary {
    grid-column: 5 / 8;
  }
}

@media screen and (min-width: 800px) {
  .hero-stage__image-container {
    height: 100%;
    margin-bottom: 0;
    overflow: hidden;
    position: absolute;
    right: calc(var(--spacing-sides) * -1);
    top: 0;
    width: 580px;
  }
}

.hero-stage__image-container::before,
.hero-stage__image-container::after {
  content: "";
  position: absolute;
}

/* Gradient Mobile */
.hero-stage__image-container::before {
  display: block;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8) 22.85%,
      rgba(255, 255, 255, 0) 100.08%
    ),
    linear-gradient(180deg, #ffffff 13.31%, rgba(255, 255, 255, 0) 99.12%);
  top: -1px;
  content: "";
  height: 30%;
  left: 0;
  width: 100%;
  z-index: 1;
}

.hero-stage.hero-stage--img-mobile-top .hero-stage__image-container::before {
  bottom: -1px;
  top: unset;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8) 22.85%,
      rgba(255, 255, 255, 0) 100.08%
    ),
    linear-gradient(0deg, #ffffff 13.31%, rgba(255, 255, 255, 0) 99.12%);
}

/* Gradient Desktop */
.hero-stage__image-container::after {
  display: none;
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 30%,
      rgba(255, 255, 255, 0) 100.08%
    ),
    linear-gradient(90deg, #ffffff 13.31%, rgba(255, 255, 255, 0) 100%);
  bottom: 0;
  height: 100%;
  top: 0;
  width: 50%;
}

.hero-stage.hero-stage--img-desktop-left .hero-stage__image-container::after {
  left: unset;
  right: 0;
  background: linear-gradient(
      -90deg,
      rgba(255, 255, 255, 1) 30%,
      rgba(255, 255, 255, 0) 100.08%
    ),
    linear-gradient(-90deg, #ffffff 13.31%, rgba(255, 255, 255, 0) 100%);
}

@media screen and (min-width: 800px) {
  .hero-stage__image-container::before {
    display: none;
  }

  .hero-stage__image-container::after {
    display: block;
  }
}

/* Image Alignments */
.hero-stage.hero-stage--img-mobile-top .hero-stage__image-container {
  order: -1;
}

@media screen and (min-width: 800px) {
  .hero-stage.hero-stage--img-mobile-top .hero-stage__image-container::before {
    bottom: 0;
    top: 0;
  }

  .hero-stage.hero-stage--img-desktop-left .hero-stage__intro {
    grid-column: 5 / 9;
  }

  .hero-stage.hero-stage--img-desktop-left .hero-stage__image-container {
    left: calc(var(--spacing-sides) * -1);
    right: unset;
  }
}

@media screen and (min-width: 1280px) {
  .hero-stage.hero-stage--img-desktop-left .hero-stage__intro {
    grid-column: 5 / 13;
  }
}

@media screen and (max-width: 799px) {
  /* csslint-disable-next-line */
  .hero-stage .text-block ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  /* csslint-disable-next-line */
  .hero-stage .text-block li {
    flex: 0 0 auto;
    position: relative;
    display: block;
  }

  /* csslint-disable-next-line */
  .hero-stage .text-block ul li::before {
    position: relative;
    left: 0;
  }
}
