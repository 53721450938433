.note {
  color: var(--grey-08);
  margin: var(--spacing-08);
  box-shadow: var(--box-shadow-1);
  border-radius: 8px;
}

.note__border {
  display: block;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: var(--spacing-02);
  background-image: var(--gradient-01);
}

.note__info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.note__header,
.note__main {
  padding: var(--spacing-04);
}

.note__headline {
  margin: 0;
}

.note__header {
  border-bottom: 1px solid var(--grey-04);
  display: grid;
  grid-template-columns: 1fr auto;
  gap: var(--spacing-03);
}

.note__main {
  padding: var(--spacing-04) var(--spacing-06) var(--spacing-06);
}

.note--grey {
  background-color: var(--grey-01);
}

.note--yellow {
  background-color: var(--yellow-03);
}

.note--yellow .note__header {
  border-bottom-color: var(--yellow-05);
}

.note--yellow .note__border {
  background-image: var(--gradient-05);
}

.note--teal {
  background-color: var(--teal-03);
}

.note--teal .note__header {
  border-bottom-color: var(--teal-04);
}

.note--teal .note__border {
  background-image: var(--gradient-05);
}
