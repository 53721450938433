.steps {
  margin: 0 var(--spacing-sides) var(--spacing-08);
}

.steps__panels {
  display: flex;
  flex-direction: column;
  justify-content: center;
  counter-reset: stepsCounter;
  margin: 2em -0 1em 0;
}

.steps__panel {
  width: 100%;
  max-width: 100%;
  text-align: left;
  font-family: var(--ff--sans-medium);
  font-weight: normal;
  margin: 0;
  position: relative;
  flex: auto;
  display: flex;
  align-items: center;
}

.steps__text {
  padding-left: 1rem;
  margin: 0;
}

.steps__panel:not(:last-child) {
  padding-bottom: 2rem;
}

.steps__panel::before {
  counter-increment: stepsCounter;
  content: counter(stepsCounter);
  background: var(--teal-06);
  color: #ffffff;
  width: 1.8em;
  height: 1.8em;
  font-size: 1.5em;
  display: inline-block;
  line-height: 1.8em;
  font-weight: normal;
  border-radius: 50%;
  font-family: var(--ff--sans);
  margin: 0;
  flex: 0 0 auto;
  text-align: center;
}

/* csslint-disable-next-line */
.color--white .steps__panel::before {
  color: var(--teal-06);
  background-color: #ffffff;
}

.steps__panel:not(:last-child)::after {
  content: "";
  position: absolute;
  background: var(--teal-06);
  font-size: 1.5em;
  top: 1.9em;
  height: calc(100% - 1.8em - 0.2em);
  width: 2px;
  left: 0.9em;
  margin-left: -1px;
}

/* csslint-disable-next-line */
.color--white .steps__panel:not(:last-child)::after {
  background-color: var(--teal-04);
}

@media screen and (min-width: 800px) {
  .steps__panels {
    flex-direction: row;
  }

  .steps__panel {
    max-width: 13em;
    text-align: center;
    margin: 0 2em;
    flex-direction: column;
  }

  .steps__panel:not(:last-child)::after {
    top: 0.9em;
    height: 2px;
    width: 100%;
    left: 50%;
    margin-left: 2rem;
  }

  .steps__panel:not(:last-child) {
    padding-bottom: 0;
  }

  .steps__text {
    padding: 1rem 0 0 0;
  }
}
