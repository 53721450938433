.citations {
  position: relative;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.citations::before {
  background-image: url("data:image/svg+xml,%3Csvg width='1920' height='48' viewBox='0 0 1920 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1920 25.3503V48H0.00134277C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0C1737.74 0 1810.67 10.1541 1889.44 21.1217C1899.51 22.5244 1909.68 23.9405 1920 25.3503Z' fill='%23F7F7F7'/%3E%3Cpath d='M0.00132628 48C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0H0V48H0.00132628Z' fill='red' fill-opacity='0' /%3E%3Cpath d='M1920 25.3503C1909.68 23.9405 1899.51 22.5244 1889.44 21.1217C1810.67 10.1541 1737.74 0 1646 0H1920V25.3503Z' fill='%23F7F7F7'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 150%;
  content: "";
  display: block;
  height: 48px;
  position: relative;
  top: 29px;
  width: 100%;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  .citations::before {
    background-size: cover;
    position: absolute;
    top: -48px;
  }
}

.citations__content {
  background-color: var(--grey-02);
  padding: 80px var(--spacing-sides);
}

@media screen and (min-width: 800px) {
  .citations__content {
    padding-left: calc(var(--spacing-sides) + var(--spacing-05));
  }
}

.citations__headline {
  font-family: var(--ff-sans-medium);
  font-size: var(--fs--extra-small);
  margin-bottom: 30px;
}

.citations__citations {
  color: #4d4d4d;
  font-family: var(--ff-serif);
}

@media screen and (min-width: 1000px) {
  .citations__citations {
    max-width: 520px;
  }
}

/* csslint-disable-next-line */
.citations__citations ol {
  counter-reset: li;
  list-style-type: none;
  position: relative;
}

/* csslint-disable-next-line */
.citations__citations li {
  counter-increment: li;
  line-height: 27px;
  margin-bottom: var(--spacing-03);
}

/* csslint-disable-next-line */
.citations__citations li::before {
  right: calc(100% - 2em);
  content: counter(li) ".";
  padding-right: 0.5em;
  font-family: var(--ff--serif-medium);
  position: absolute;
}

/* csslint-disable-next-line */
.citations__citations a {
  color: var(--grey-07);
  border-bottom: 2px solid var(--teal-04);
  text-decoration: none;
  transition: color var(--link-transition) ease-in-out;
  word-break: break-word;
}

/* csslint-disable-next-line */
.citations__citations a:hover {
  color: var(--teal-05);
}
