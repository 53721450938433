/* The following class names are referenced as React classNames in /assets/js/nba.js */

.nba-sticky-button {
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.17, 1.06, 0.59, 0.99);
  animation-name: nba-sticky-button__fade-in;
  animation-duration: 1s;
  animation-delay: 6s;
  animation-iteration-count: 1;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  transition-property: transform, background-color;
  opacity: 0;
  pointer-events: none;
  background-color: var(--teal-04);
  border: none;
  border-radius: 16px 16px 0 0;
  bottom: 0;
  box-shadow: 0 -4px 32px 12px rgba(0, 0, 0, 0.08),
    0 -8px 80px 32px rgba(0, 0, 0, 0.08);
  color: white;
  cursor: pointer;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--super-small);
  font-weight: normal;
  left: 0;
  min-height: 48px;
  position: fixed;
  width: 100%;
  z-index: 4;
}

.nba-sticky-button:hover {
  background-color: var(--teal-05);
}

@media screen and (max-width: 999px) {
  .nba-sticky-button--hidden {
    visibility: hidden;
  }
}

@media screen and (min-width: 1000px) {
  .nba-sticky-button {
    display: none;
  }
}

@keyframes nba-sticky-button__fade-in {
  from {
    opacity: 0;
    pointer-events: none;
    transform: translateY(100%) scale(0.7) rotate(6deg);
  }

  to {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0%) scale(1) rotate(0deg);
  }
}
