.how-to-old__steps {
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: var(--spacing-08);
  padding: 48px 24px;
}

@media screen and (min-width: 800px) {
  .how-to-old__steps {
    padding: 48px 40px;
    padding-left: 0;
  }
}

.how-to-old__step {
  counter-increment: step; /* Increment the value of section counter by 1 */
  padding-bottom: 48px;
  position: relative;
}

@media screen and (min-width: 800px) {
  .how-to-old__step {
    border-left: 3px solid var(--teal-05);
    margin-left: calc(40px + 24px);
    padding-left: calc(48px + 24px);
  }

  .how-to-old__step:last-child {
    border-color: var(--grey-01);
  }
}

.how-to-old__step::before {
  align-items: center;
  background-color: var(--grey-01);
  border: 3px solid var(--teal-05);
  border-radius: 50%;
  color: var(--teal-05);
  content: counter(step);
  display: flex;
  font-size: var(--fs--medium);
  font-weight: 900;
  height: 48px;
  justify-content: center;
  outline: 5px solid var(--grey-01);
  width: 48px;
}

@media screen and (min-width: 800px) {
  .how-to-old__step::before {
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(calc(-50% - 1.5px));
  }
}

.how-to-old__step-title {
  font-size: var(--fs--extra-small);
  font-weight: 900;
  padding-bottom: 20px;
  padding-top: 20px;
  color: var(--grey-09);
}

@media screen and (min-width: 800px) {
  .how-to-old__step-title {
    padding-top: 12px;
  }
}

.how-to-old__step-copy {
  font-size: var(--fs--extra-small);
  line-height: var(--lh--150);
  padding-bottom: 16px;
  color: var(--grey-09);
}

/* csslint-disable-next-line */
.how-to-old__step-copy > p {
  margin-bottom: 0;
  margin-top: 0;
}

@media screen and (min-width: 800px) {
  .how-to-old__step-copy:last-child {
    border-bottom: 2px solid var(--grey-04);
    padding-bottom: 48px;
  }
}

@media screen and (min-width: 800px) {
  .how-to-old__step-list {
    border-bottom: 2px solid var(--grey-04);
    padding-bottom: 72px;
  }
}

/* csslint-disable-next-line */
.how-to-old__step-list a {
  color: var(--grey-07);
  text-decoration: none;
  transition: color var(--link-transition) ease-in-out;
}

/* csslint-disable-next-line */
.how-to-old__step-list a:hover {
  color: var(--teal-05);
}

.how-to-old__step-list-item {
  align-items: center;
  display: flex;
  font-size: var(--fs--super-small);
  font-weight: 600;
  padding: 8px 0;
}

.how-to-old__step-list-item-icon {
  background-color: var(--grey-04);
  background-position: center center;
  background-size: 48px 48px;
  border-radius: 50%;
  height: 48px;
  margin-right: 16px;
  min-width: 48px;
  width: 48px;
}

.how-to-old__step-list-item-icon--app {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ4IDI0QzQ4IDEwLjc0NTIgMzcuMjU0OCAwIDI0IDBDMTAuNzQ1MiAwIDAgMTAuNzQ1MiAwIDI0QzAgMzcuMjU0OCAxMC43NDUyIDQ4IDI0IDQ4QzM3LjI1NDggNDggNDggMzcuMjU0OCA0OCAyNFoiIGZpbGw9IiNGN0Y3RjciLz4KPHBhdGggZD0iTTI5IDE0SDE5QzE3Ljg5NTQgMTQgMTcgMTQuODk1NCAxNyAxNlYzMkMxNyAzMy4xMDQ2IDE3Ljg5NTQgMzQgMTkgMzRIMjlDMzAuMTA0NiAzNCAzMSAzMy4xMDQ2IDMxIDMyVjE2QzMxIDE0Ljg5NTQgMzAuMTA0NiAxNCAyOSAxNFoiIHN0cm9rZT0iIzFBMUExQSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMjQgMzBIMjQuMDEiIHN0cm9rZT0iIzFBMUExQSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
}

.how-to-old__step-list-item-icon--phone {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ4IDI0QzQ4IDEwLjc0NTIgMzcuMjU0OCAwIDI0IDBDMTAuNzQ1MiAwIDAgMTAuNzQ1MiAwIDI0QzAgMzcuMjU0OCAxMC43NDUyIDQ4IDI0IDQ4QzM3LjI1NDggNDggNDggMzcuMjU0OCA0OCAyNFoiIGZpbGw9IiNGN0Y3RjciLz4KPHBhdGggZD0iTTMzLjk5OTQgMjguOTJWMzEuOTJDMzQuMDAwNiAzMi4xOTg1IDMzLjk0MzUgMzIuNDc0MiAzMy44MzIgMzIuNzI5NEMzMy43MjA0IDMyLjk4NDUgMzMuNTU2NyAzMy4yMTM2IDMzLjM1MTUgMzMuNDAxOUMzMy4xNDYzIDMzLjU5MDEgMzIuOTA0IDMzLjczMzUgMzIuNjQwMiAzMy44MjI3QzMyLjM3NjQgMzMuOTExOSAzMi4wOTY4IDMzLjk0NTEgMzEuODE5NCAzMy45MkMyOC43NDIzIDMzLjU4NTYgMjUuNzg2NCAzMi41MzQyIDIzLjE4OTQgMzAuODVDMjAuNzczMyAyOS4zMTQ3IDE4LjcyNDggMjcuMjY2MiAxNy4xODk0IDI0Ljg1QzE1LjQ5OTQgMjIuMjQxMiAxNC40NDc3IDE5LjI3MSAxNC4xMTk0IDE2LjE4QzE0LjA5NDUgMTUuOTAzNSAxNC4xMjczIDE1LjYyNDggMTQuMjE1OSAxNS4zNjE2QzE0LjMwNDYgMTUuMDk4NSAxNC40NDcgMTQuODU2NyAxNC42MzQyIDE0LjY1MTZDMTQuODIxNCAxNC40NDY2IDE1LjA0OTIgMTQuMjgyNyAxNS4zMDMyIDE0LjE3MDVDMTUuNTU3MiAxNC4wNTgzIDE1LjgzMTggMTQuMDAwMyAxNi4xMDk0IDE0SDE5LjEwOTRDMTkuNTk0OCAxMy45OTUyIDIwLjA2NTIgMTQuMTY3MSAyMC40MzMyIDE0LjQ4MzVDMjAuODAxMiAxNC44IDIxLjA0MTUgMTUuMjM5NSAyMS4xMDk0IDE1LjcyQzIxLjIzNjEgMTYuNjgwMSAyMS40NzA5IDE3LjYyMjcgMjEuODA5NCAxOC41M0MyMS45NDQgMTguODg3OSAyMS45NzMxIDE5LjI3NjkgMjEuODkzNCAxOS42NTA5QzIxLjgxMzYgMjAuMDI0OSAyMS42MjgzIDIwLjM2ODEgMjEuMzU5NCAyMC42NEwyMC4wODk0IDIxLjkxQzIxLjUxMyAyNC40MTM2IDIzLjU4NTkgMjYuNDg2NSAyNi4wODk0IDI3LjkxTDI3LjM1OTQgMjYuNjRDMjcuNjMxMyAyNi4zNzExIDI3Ljk3NDYgMjYuMTg1OSAyOC4zNDg2IDI2LjEwNjFDMjguNzIyNSAyNi4wMjYzIDI5LjExMTUgMjYuMDU1NSAyOS40Njk0IDI2LjE5QzMwLjM3NjcgMjYuNTI4NiAzMS4zMTk0IDI2Ljc2MzQgMzIuMjc5NCAyNi44OUMzMi43NjUyIDI2Ljk1ODUgMzMuMjA4OCAyNy4yMDMyIDMzLjUyNiAyNy41Nzc1QzMzLjg0MzEgMjcuOTUxOCAzNC4wMTE2IDI4LjQyOTYgMzMuOTk5NCAyOC45MloiIHN0cm9rZT0iIzFBMUExQSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
}

.how-to-old__step-list-item-icon--mail {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ4IDI0QzQ4IDEwLjc0NTIgMzcuMjU0OCAwIDI0IDBDMTAuNzQ1MiAwIDAgMTAuNzQ1MiAwIDI0QzAgMzcuMjU0OCAxMC43NDUyIDQ4IDI0IDQ4QzM3LjI1NDggNDggNDggMzcuMjU0OCA0OCAyNFoiIGZpbGw9IiNGN0Y3RjciLz4KPHBhdGggZD0iTTE2IDE2SDMyQzMzLjEgMTYgMzQgMTYuOSAzNCAxOFYzMEMzNCAzMS4xIDMzLjEgMzIgMzIgMzJIMTZDMTQuOSAzMiAxNCAzMS4xIDE0IDMwVjE4QzE0IDE2LjkgMTQuOSAxNiAxNiAxNloiIHN0cm9rZT0iIzFBMUExQSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMzQgMThMMjQgMjVMMTQgMTgiIHN0cm9rZT0iIzFBMUExQSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
}

.how-to-old__step-list-item-icon--location {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ4IDI0QzQ4IDEwLjc0NTIgMzcuMjU0OCAwIDI0IDBDMTAuNzQ1MiAwIDAgMTAuNzQ1MiAwIDI0QzAgMzcuMjU0OCAxMC43NDUyIDQ4IDI0IDQ4QzM3LjI1NDggNDggNDggMzcuMjU0OCA0OCAyNFoiIGZpbGw9IiNGN0Y3RjciLz4KPHBhdGggZD0iTTMzIDIyQzMzIDI5IDI0IDM1IDI0IDM1QzI0IDM1IDE1IDI5IDE1IDIyQzE1IDE5LjYxMzEgMTUuOTQ4MiAxNy4zMjM5IDE3LjYzNiAxNS42MzZDMTkuMzIzOSAxMy45NDgyIDIxLjYxMzEgMTMgMjQgMTNDMjYuMzg2OSAxMyAyOC42NzYxIDEzLjk0ODIgMzAuMzY0IDE1LjYzNkMzMi4wNTE4IDE3LjMyMzkgMzMgMTkuNjEzMSAzMyAyMloiIHN0cm9rZT0iIzFBMUExQSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMjQgMjVDMjUuNjU2OSAyNSAyNyAyMy42NTY5IDI3IDIyQzI3IDIwLjM0MzEgMjUuNjU2OSAxOSAyNCAxOUMyMi4zNDMxIDE5IDIxIDIwLjM0MzEgMjEgMjJDMjEgMjMuNjU2OSAyMi4zNDMxIDI1IDI0IDI1WiIgc3Ryb2tlPSIjMUExQTFBIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
}

.how-to-old__step:last-child .how-to-old__step-copy:last-child,
.how-to-old__step:last-child .how-to-old__step-list {
  border-bottom: none;
  padding-bottom: 0;
}
