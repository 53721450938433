.toc {
  margin-top: 80px;
  margin-bottom: 80px;
}

.toc ol {
  list-style: none;
  padding: 0;
}

.toc ol li {
  padding: 0;
}

.toc ol li::before {
  display: none;
}
