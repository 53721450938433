.faq-list-new {
  max-width: var(--width-regular);
  padding: 0 var(--spacing-sides);
  margin: 0 auto var(--spacing-09);
}

.faq-list-new .faq-list-new__section-intro {
  padding-left: 0;
  padding-right: 0;
}

.faq-list-new__faq {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
  padding: 20px 16px;
  position: relative;
  text-align: left;
  width: 100%;
  border-bottom: 2px solid var(--grey-04);
}

.faq-list-new__faq::before {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2226%22%20height%3D%2226%22%20viewBox%3D%220%200%2026%2026%22%20fill%3D%22none%22%20xmlns%3D%22http://www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M13%208V18%22%20stroke%3D%22%23316561%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22/%3E%3Cpath%20d%3D%22M8%2013H18%22%20stroke%3D%22%23316561%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22/%3E%3C/svg%3E");
  background-position: center;
  content: "";
  height: 26px;
  width: 26px;
  position: absolute;
  right: 32px;
  top: 24px;
  transition: transform 0.1s ease-in;
  border: 2px solid #316561;
  border-radius: 50%;
}

.faq-list-new__faq--active::before {
  transform: rotate(45deg);
}

.faq-list-new__faq:last-child {
  margin-bottom: 0;
}

.faq-list-new__headline {
  font-size: var(--fs--extra-small);
  line-height: var(--lh--150);
  margin: 0 40px 0 0;
  color: var(--grey-09);
}

.faq-list-new__text {
  display: none;
  max-width: 680px;
}

/* csslint-disable-next-line */
.faq-list-new__text > p {
  color: var(--grey-07);
  font-size: var(--fs--extra-small);
  line-height: var(--lh--150);
  margin: var(--spacing-03) 0 var(--spacing-03);
  padding: 0;
}

/* csslint-disable-next-line */
.faq-list-new__text > figure {
  margin: var(--spacing-04) 0 var(--spacing-04);
  padding: 0;
  overflow-y: hidden;
}

/* csslint-disable-next-line */
.faq-list-new__text > figure > img {
  max-width: 100%;
}

/* csslint-disable-next-line */
.faq-list-new__faq--active .faq-list-new__text {
  display: block;
}

/* csslint-disable-next-line */
.faq-list-new__text a {
  color: var(--teal-05);
  text-decoration: none;
}

.faq-list-new__button {
  background-color: var(--teal-06);
  border-radius: 16px;
  color: white;
  display: inline-block;
  font-size: var(--fs--super-small);
  font-weight: 600;
  line-height: var(--lh--150);
  min-height: 48px;
  margin: var(--spacing-07) 0 0;
  padding: 11px 24px 12px;
  text-decoration: none;
  transition: background-color 0.1s ease-in;
}

.faq-list-new__button:hover {
  background-color: var(--teal-05);
}

@media screen and (min-width: 800px) {
  .faq-list-new__button {
    margin-left: 32px;
  }
}
