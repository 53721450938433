.contact-profile {
  margin-bottom: var(--spacing-08);
  position: relative;
  padding: 0 var(--spacing-sides);
}

.contact-profile .contact-profile__section-intro {
  padding: 0;
}

.contact-profile--full-width {
  background-color: white;
}

.contact-profile--full-width::before {
  background-image: url("data:image/svg+xml,%3Csvg width='1920' height='48' viewBox='0 0 1920 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1920 25.3503V48H0.00134277C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0C1737.74 0 1810.67 10.1541 1889.44 21.1217C1899.51 22.5244 1909.68 23.9405 1920 25.3503Z' fill='white'/%3E%3Cpath d='M0.00132628 48C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0H0V48H0.00132628Z' fill='%23F7F7F7' fill-opacity='1' /%3E%3Cpath d='M1920 25.3503C1909.68 23.9405 1899.51 22.5244 1889.44 21.1217C1810.67 10.1541 1737.74 0 1646 0H1920V25.3503Z' fill='%23F7F7F7'/%3E%3C/svg%3E%0A");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 150%;
  content: "";
  display: block;
  height: 48px;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 2;
  left: 0;
  right: 0;
}

@media screen and (min-width: 800px) {
  .contact-profile--full-width::before {
    background-size: cover;
    position: absolute;
  }
}

.contact-profile--full-width::after {
  background-image: url("data:image/svg+xml,%3Csvg width='1920' height='48' viewBox='0 0 1920 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1920 25.3503V48H0.00134277C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0C1737.74 0 1810.67 10.1541 1889.44 21.1217C1899.51 22.5244 1909.68 23.9405 1920 25.3503Z' fill='%23F7F7F7'/%3E%3Cpath d='M0.00132628 48C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0H0V48H0.00132628Z' fill='none' fill-opacity='0' /%3E%3Cpath d='M1920 25.3503C1909.68 23.9405 1899.51 22.5244 1889.44 21.1217C1810.67 10.1541 1737.74 0 1646 0H1920V25.3503Z' fill='%23F7F7F7'/%3E%3C/svg%3E%0A");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 150%;
  bottom: 0;
  content: "";
  display: block;
  height: 48px;
  position: relative;
  width: 100%;
  z-index: 2;
  left: 0;
  right: 0;
}

@media screen and (min-width: 800px) {
  .contact-profile--full-width::after {
    background-size: cover;
    position: absolute;
  }
}

.contact-profile__content {
  width: 100%;
  max-width: var(--width-regular);
  margin: 0 auto;
}

.contact-profile--full-width .contact-profile__content {
  padding: var(--spacing-08) var(--spacing-sides);
}

.contact-profile__panel {
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  position: relative;
  text-align: center;
}

@media screen and (min-width: 800px) {
  .contact-profile__panel {
    flex-direction: row;
    align-items: center;
  }

  .contact-profile--full-width .contact-profile__panel {
    display: block;
    text-align: left;
  }
}

@media screen and (min-width: 900px) {
  .contact-profile__panel {
    padding: 42px 0;
  }

  .contact-profile--full-width .contact-profile__panel {
    padding: 60px 0;
  }
}

@media screen and (min-width: 800px) {
  .contact-profile__panel {
    text-align: left;
  }
}

.contact-profile__text {
  order: 1;
}

.contact-profile__buttons {
  margin: var(--spacing-05) 0 0;
}

/* csslint-disable-next-line */
.contact-profile__buttons .text-block__button {
  margin-top: 0;
}

.contact-profile__name {
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--extra-small);
  font-weight: normal;
  line-height: var(--lh--150);
  margin: 0 0 var(--spacing-04);
}

.contact-profile__job-title {
  color: var(--grey-06);
  display: block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--micro);
  font-weight: 500;
  margin: 0 0 var(--spacing-04);
  text-transform: uppercase;
}

@media screen and (min-width: 800px) {
  .contact-profile__job-title {
    margin: 0 0 var(--spacing-05);
  }
}

.contact-profile__link {
  align-items: center;
  color: var(--teal-05);
  display: flex;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--ultra-small);
  justify-content: center;
  margin: 0 0 var(--spacing-03);
  text-decoration: none;
}

@media screen and (min-width: 380px) {
  .contact-profile__link {
    font-size: var(--fs--super-small);
  }
}

@media screen and (min-width: 800px) {
  .contact-profile__link {
    justify-content: left;
  }
}

.contact-profile__link::before {
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 16px;
  margin-right: 6px;
  width: 16px;
}

@media screen and (min-width: 380px) {
  .contact-profile__link::before {
    margin-right: 16px;
  }
}

.contact-profile__link:last-child {
  margin-bottom: 0;
}

.contact-profile__link--phone::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.6669 11.2802V13.2802C14.6677 13.4659 14.6297 13.6497 14.5553 13.8198C14.4809 13.9899 14.3718 14.1426 14.235 14.2681C14.0982 14.3937 13.9367 14.4892 13.7608 14.5487C13.5849 14.6082 13.3985 14.6303 13.2136 14.6136C11.1622 14.3907 9.19161 13.6897 7.46028 12.5669C5.8495 11.5433 4.48384 10.1777 3.46028 8.56689C2.3336 6.8277 1.63244 4.84756 1.41361 2.78689C1.39695 2.60254 1.41886 2.41673 1.47795 2.24131C1.53703 2.06589 1.63199 1.90469 1.75679 1.76797C1.88159 1.63126 2.03348 1.52203 2.20281 1.44724C2.37213 1.37245 2.55517 1.33374 2.74028 1.33356H4.74028C5.06382 1.33038 5.37748 1.44495 5.62279 1.65592C5.8681 1.86689 6.02833 2.15986 6.07361 2.48023C6.15803 3.12027 6.31458 3.74871 6.54028 4.35356C6.62998 4.59218 6.64939 4.8515 6.59622 5.10081C6.54305 5.35012 6.41952 5.57897 6.24028 5.76023L5.39361 6.60689C6.34265 8.27592 7.72458 9.65786 9.39361 10.6069L10.2403 9.76023C10.4215 9.58098 10.6504 9.45746 10.8997 9.40429C11.149 9.35112 11.4083 9.37053 11.6469 9.46023C12.2518 9.68593 12.8802 9.84248 13.5203 9.92689C13.8441 9.97258 14.1399 10.1357 14.3513 10.3852C14.5627 10.6347 14.6751 10.9533 14.6669 11.2802Z' stroke='%233D3D3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.contact-profile__link--email::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.66732 2.6665H13.334C14.0673 2.6665 14.6673 3.2665 14.6673 3.99984V11.9998C14.6673 12.7332 14.0673 13.3332 13.334 13.3332H2.66732C1.93398 13.3332 1.33398 12.7332 1.33398 11.9998V3.99984C1.33398 3.2665 1.93398 2.6665 2.66732 2.6665Z' stroke='%233D3D3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.6673 4L8.00065 8.66667L1.33398 4' stroke='%233D3D3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.contact-profile__photo {
  margin: 0;
  margin-bottom: var(--spacing-04);
}

@media screen and (min-width: 800px) {
  .contact-profile--full-width .contact-profile__section-intro {
    padding-right: 282px;
  }

  .contact-profile--full-width .contact-profile__panel {
    padding-right: 282px;
  }

  .contact-profile--full-width .contact-profile__photo {
    bottom: 24px;
    margin-bottom: 0;
    margin-top: 0;
    max-width: none;
    position: absolute;
    right: 0;
    width: 262px;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }

  .contact-profile--full-width .contact-profile__photo * {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 900px) {
  .contact-profile__photo {
    width: 262px;
  }

  .contact-profile--full-width .contact-profile__section-intro {
    padding-right: 328px;
  }

  .contact-profile--full-width .contact-profile__panel {
    padding-right: 328px;
  }

  .contact-profile--full-width .contact-profile__photo {
    width: 308px;
  }
}

@media screen and (min-width: 800px) {
  .contact-profile__photo {
    width: 340px;
    order: 2;
    margin-left: auto;
    margin-bottom: 0;
  }
}
