.topics-rail {
  margin-bottom: var(--spacing-08);
  overflow-x: hidden;
  overflow-y: hidden;
}

.topics-rail__section-intro {
  padding-left: 0;
  padding-right: 0;
}

.topics-rail__content {
  margin: 0 var(--spacing-sides);
}

.topics-rail__rail {
  padding: 0 var(--spacing-sides);
}

.topics-rail__item {
  margin-right: 16px;
}

/* csslint-disable-next-line */
.topics-rail__rail .rail .flickity-button.previous {
  position: absolute;
  top: -40px;
  right: 60px;
}

/* csslint-disable-next-line */
.topics-rail__rail .rail .flickity-button.next {
  position: absolute;
  top: -40px;
  right: 0;
}
