.image-caption {
  bottom: 0;
  left: 0;
  margin: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.image-caption__image {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0);
  display: block;
  margin-bottom: var(--spacing-03);
  width: 100%;
}

.image-caption__caption {
  color: var(--grey-07);
  font-size: var(--fs--super-small);
}
