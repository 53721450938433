.blog-subnav {
  background-color: white;
  padding: 160px 0 calc(var(--spacing-08) - var(--spacing-05));
}

.blog-subnav__content {
  margin: 0 var(--spacing-sides);
}

@media screen and (min-width: 800px) {
  .blog-subnav__content {
    padding: 0 80px;
  }
}

.blog-subnav__items {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 800px) {
  .blog-subnav__items {
    justify-content: center;
  }
}

.blog-subnav__item {
  margin-bottom: var(--spacing-05);
}

.blog-subnav__item:not(:last-child) {
  margin-right: var(--spacing-02);
}

.blog-subnav__link {
  background-color: rgba(242, 242, 242, 0.7);
  border-radius: 20px;
  color: var(--grey-08);
  padding: 8px 16px;
  text-decoration: none;
}

.blog-subnav__link:hover {
  color: var(--teal-05);
}

.blog-subnav__link--active {
  background-color: var(--teal-05);
  color: var(--grey-01);
}

.blog-subnav__link--active:hover {
  color: var(--grey-01);
}
