.text-block {
  width: 100%;
}

.text-block--centered {
  text-align: center;
}

.text-block__kicker {
  color: var(--teal-05);
  display: inline-block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--micro);
  letter-spacing: 0.03em;
  line-height: var(--lh--150);
  margin-bottom: 0.8em;
  text-transform: uppercase;
}

.text-block__kicker--lc {
  text-transform: unset;
}

/* csslint-disable-next-line */
.text-block ul {
  position: relative;
  list-style-type: none;
  padding-left: 2em;
}

/* csslint-disable-next-line */
.text-block li {
  margin-bottom: 8px;
}

/* csslint-disable-next-line */
.text-block ul li::before {
  position: absolute;
  left: 1em;
  content: "\2022 ";
  color: var(--teal-05);
  padding-right: 0.5em;
}

/* csslint-disable-next-line */
.text-block__text ol {
  position: relative;
  counter-reset: li;
  list-style-type: none;
}

/* csslint-disable-next-line */
.text-block__text ol li {
  counter-increment: li;
}

/* csslint-disable-next-line */
.text-block__text ol li::before {
  position: absolute;
  right: calc(100% - 2em);
  content: counter(li) ".";
  color: var(--teal-05);
  padding-right: 0.5em;
  font-family: var(--ff--sans-medium);
}

@media screen and (min-width: 800px) {
  .text-block__kicker {
    font-size: var(--fs--super-small);
  }
}

.text-block__headline {
  font-family: var(--ff--serif);
  font-size: var(--fs--super-large-serif);
  font-weight: normal;
  margin: 0 0 0.4em;
  color: var(--grey-09);
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
}

.text-block__headline:last-child {
  margin-bottom: 0;
}

.text-block__headline--small {
  font-family: var(--ff--serif);
  font-size: var(--fs--small-serif);
  font-weight: normal;
  margin: 0 0 0.4em;
}

.text-block__headline--medium {
  font-size: var(--fs--medium-serif);
}

.text-block__headline--large {
  font-size: var(--fs--large-serif);
}

.text-block__headline--extra-large {
  font-size: var(--fs--extra-large-serif);
}

.text-block__headline--ultra-large {
  font-size: var(--fs--ultra-large-serif);
}

.text-block__headline--centered {
  margin: 0 0 0.4em;
}

.text-block__text {
  font-size: var(--fs--extra-small);
  line-height: var(--lh--150);
  margin: 0 0 1em;
}

/* csslint-disable-next-line */
.text-block__text p a {
  color: var(--teal-05);
  text-decoration: none;
}

.text-block__text,
.text-block__link {
  color: var(--grey-07);
}

.text-block__text:last-child {
  margin-bottom: 0;
}

/* csslint-disable-next-line */
.text-block__text:last-child > *:last-child {
  margin-bottom: 0;
}

.text-block__link {
  border-bottom: 2px solid var(--teal-04);
  font-family: var(--ff--sans-medium);
  line-height: 1.8;
  padding-bottom: 3px;
  text-decoration: none;
  transition: color 0.1s ease-in;
}

.text-block__link:hover {
  color: var(--teal-04);
}

.text-block__button {
  background-color: var(--teal-04);
  border-radius: 16px;
  color: white;
  display: inline-block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--super-small);
  font-weight: normal;
  line-height: var(--lh--150);
  margin: var(--spacing-03) 4px 0 4px;
  min-height: 48px;
  padding: 11px 24px 12px;
  text-decoration: none;
  transition: background-color 0.1s ease-in;
}

.text-block__button:hover {
  background-color: var(--teal-05);
}

.text-block__secondary-button {
  background-color: white;
  border: 1px solid var(--teal-04);
  box-sizing: border-box;
  border-radius: 16px;
  color: var(--teal-04);
  display: inline-block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--super-small);
  font-weight: normal;
  line-height: var(--lh--150);
  margin: var(--spacing-03) 4px 0 4px;
  min-height: 48px;
  padding: 11px 24px 12px;
  text-decoration: none;
  transition: background-color 0.1s ease-in;
}

.text-block__secondary-button:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.text-block__secondary-button:active {
  background-color: rgba(0, 0, 0, 0.12);
}

/* csslint-disable-next-line */
.color--white .text-block__secondary-button:hover {
  background-color: var(--grey-03);
}

/* csslint-disable-next-line */
.text-image__body ul {
  padding: 0;
  list-style: none;
  margin: 1em 0;
}

/* csslint-disable-next-line */
.text-image__body ul li {
  padding-left: 2.2em;
  position: relative;
}

/* csslint-disable-next-line */
.text-image__body ul li + li {
  margin-top: 1em;
}

/* csslint-disable-next-line */
.text-image__body ul li::before {
  background-image: url("../../../assets/img/icons/checkmark.svg");
  content: "";
  position: absolute;
  left: 0;
  top: -0.1em;
  width: 1.8em;
  height: 1.8em;
  background-size: contain;
}

.text-block__breadcrumb {
  margin-bottom: var(--spacing-04);
}

.text-block__breadcrumb-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0 0 -0.5em;
}

@media screen and (min-width: 800px) {
  .text-block__breadcrumb-list {
    justify-content: left;
  }
}

.text-block__breadcrumb-list-item {
  list-style: none;
  margin-bottom: 0.5em;
}

.text-block__breadcrumb-list-item:not(:nth-last-child(2))::after {
  color: currentColor;
  content: "›";
  margin-left: 0.1em;
  margin-right: 0.3em;
  opacity: 0.7;
}

.text-block__breadcrumb-list-item:last-child {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.text-block__breadcrumb-list-item-link {
  color: currentColor;
  opacity: 0.7;
  text-decoration: none;
  transition: all 0.1s ease-in;
}

.text-block__breadcrumb-list-item-link:hover {
  color: var(--teal-04);
  opacity: 1;
}
