.person-card__card {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
  height: 100%;
  padding: 16px;
  text-align: center;
}

.person-card__photo {
  display: block;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
}

.person-card__headline {
  font-size: var(--fs--extra-small);
  line-height: var(--lh--150);
  margin: 0 0 1em;
}

.person-card__text {
  color: var(--grey-06);
  font-size: var(--fs--ultra-small);
  line-height: var(--lh--150);
  margin: 0 0 1em;
}

/* csslint-disable-next-line */
.person-card__text > p {
  margin: 0 0 1em;
}

/* csslint-disable-next-line */
.person-card__text > p:last-child {
  margin: 0;
}

/* csslint-disable-next-line */
.person-card__text a {
  color: var(--teal-05);
  text-decoration: none;
}
