.topic-lp-list {
  margin-bottom: 128px;
}

.topic-lp-list__links {
  list-style: none;
  padding: 0;
}

.topic-lp-list__link-item {
  margin-top: var(--spacing-03);
}

@media screen and (min-width: 800px) {
  .topic-lp-list__link-item {
    margin-top: var(--spacing-05);
  }
}

.topic-lp-list__link-item:first-child {
  margin-top: 0;
}

.topic-lp-list__link {
  border-bottom: 2px solid var(--grey-04);
  color: var(--grey-07);
  display: block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--extra-small);
  font-weight: normal;
  padding-bottom: var(--spacing-04);
  padding-top: var(--spacing-04);
  position: relative;
  text-decoration: none;
  transition: color 0.1s ease-in;
}

@media screen and (min-width: 800px) {
  .topic-lp-list__link {
    margin-left: 16px;
    margin-right: 16px;
    padding-bottom: var(--spacing-04);
    padding-left: 16px;
    padding-right: 16px;
    padding-top: var(--spacing-04);
  }
}

.topic-lp-list__link:hover {
  color: var(--teal-04);
}

.topic-lp-list__file-info {
  color: var(--grey-06);
  font-size: var(--fs--ultra-small);
  font-weight: 400;
}

@media screen and (min-width: 800px) {
  .topic-lp-list__file-info {
    font-size: var(--fs--extra-small);
  }
}

.topic-lp-list__icon::after {
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 32px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
}

.topic-lp-list__icon--arrow::after {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.0003 29.3332C23.3641 29.3332 29.3337 23.3636 29.3337 15.9998C29.3337 8.63604 23.3641 2.6665 16.0003 2.6665C8.63653 2.6665 2.66699 8.63604 2.66699 15.9998C2.66699 23.3636 8.63653 29.3332 16.0003 29.3332Z' stroke='%2300A095' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 21.3332L21.3333 15.9998L16 10.6665' stroke='%2300A095' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.667 16H21.3337' stroke='%2300A095' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

/* csslint-disable-next-line */
.topic-lp-list__t-link a:hover {
  color: var(--teal-05);
}

/* csslint-disable-next-line */
.t-list__t-link a::after {
  content: " →";
}
