.text-image {
  padding: 0 var(--spacing-sides);
}

.column-set__column .text-image {
  padding: 40px;
}

.text-image__panel-text {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 var(--spacing-sides) 0;
}

.column-set__column .text-image__panel-text {
  padding: 0;
}

.text-image.layout--small-width {
  max-width: var(--width-regular);
  margin: 0 auto var(--spacing-08);
  padding: 0;
}

.intro + .text-image.layout--small-width {
  margin-top: calc(var(--spacing-07) * -1);
}

.text-image.layout--boxed {
  margin: 0 auto var(--spacing-08);
}

.intro + .text-image.layout--boxed {
  margin-top: calc(var(--spacing-07) * -1);
}

.text-image.layout--regular {
  margin: 0 0 calc(var(--spacing-08) + 3em);
}

.layout--regular .text-image__panel {
  background-color: white;
}

.layout--boxed .text-image__panel {
  background-color: var(--grey-02);
  border-radius: 16px;
  overflow: hidden;
}

.layout--image-top .text-image__panel {
  flex-direction: column;
  text-align: center;
  display: block;
}

.layout--image-top-toggler .text-image__panel {
  flex-direction: column;
  text-align: center;
}

.text-image__panel:last-child {
  margin-bottom: 0;
}

.text-image.layout--boxed > .text-image__panel {
  max-width: var(--width-regular);
  margin: 0 auto;
}

.text-image.layout--image-top {
  max-width: var(--width-regular);
  margin: 0 auto var(--spacing-08);
}

.text-image.layout--image-top-toggler {
  max-width: var(--width-regular);
  margin: 0 auto var(--spacing-08);
}

main > .text-image.layout--small-width::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  height: calc(var(--waves-height) * 3.5);
  background: url("../../../assets/img/backgrounds/waves.svg") no-repeat;
  background-position: left;
  background-size: auto 100%;
  z-index: -1;
}

@media screen and (min-width: 800px) {
  .text-image {
    margin: 0 var(--spacing-sides) var(--spacing-08);
  }
}

.text-image__text {
  margin: 0 var(--spacing-sides) var(--spacing-08);
  max-width: 736px;
}

@media screen and (min-width: 800px) {
  .text-image__text {
    margin: 0 auto var(--spacing-08);
  }
}

@media screen and (min-width: 800px) {
  .text-image__panel {
    border-radius: 8px;
    display: flex;
    margin-bottom: var(--spacing-07);
  }
}

@media screen and (min-width: 950px) {
  .text-image__panel {
    margin-bottom: var(--spacing-09);
  }
}

.text-image__panel--right {
  flex-direction: row-reverse;
}

.text-image__image-wrapper {
  align-items: center;
  display: flex;
  max-width: 100%;
  padding: var(--spacing-sides) var(--spacing-sides) 40px;
}

.text-image__image-wrapper * {
  flex: 1 1 100%;
}

.layout--boxed .text-image__image-wrapper {
  position: relative;
  object-fit: cover;
  padding: 0;
}

.layout--image-top .text-image__image-wrapper {
  position: relative;
  object-fit: contain;
  padding: 0;
  margin: 0 auto var(--spacing-04);
  max-width: calc(var(--width-small) / 2);
}

.layout--image-top-toggler .text-image__image-wrapper {
  position: relative;
  object-fit: contain;
  padding: 0;
  margin-bottom: var(--spacing-04);
}

@media screen and (min-width: 800px) {
  .text-image__image-wrapper {
    padding: 40px;
  }
}

@media screen and (min-width: 950px) {
  .text-image__image-wrapper {
    padding: 0;
  }
}

.text-image__panel:nth-child(2) .text-image__image-wrapper {
  padding: 40px var(--spacing-sides);
}

@media screen and (min-width: 950px) {
  .text-image__panel:nth-child(2) .text-image__image-wrapper {
    padding: 0;
  }
}

.text-image__image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  height: auto;
}

.layout--boxed .text-image__image {
  position: relative;
}

@media screen and (min-width: 800px) {
  .layout--boxed .text-image__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

@media screen and (min-width: 950px) {
  .layout--regular .text-image__image-wrapper {
    margin-top: 3em;
    margin-bottom: 3em;
  }

  .layout--regular .text-image__image {
    margin-top: -3em;
    margin-bottom: -3em;
    width: 33vw;
  }
}

.text-image__panel-text > div {
  width: 100%;
}

.layout--boxed .text-image__panel-text {
  padding: var(--spacing-sides);
}

@media screen and (min-width: 800px) {
  .layout--regular .text-image__panel-text {
    padding: 40px;
  }

  .layout--small-width .text-image__panel-text {
    padding: 40px;
  }

  .layout--boxed .text-image__panel-text {
    padding: 40px;
  }
}

@media screen and (min-width: 950px) {
  .layout--regular .text-image__panel-text {
    padding: 60px;
  }
}

@media screen and (min-width: 800px) {
  .layout--regular .text-image__image-wrapper,
  .layout--regular .text-image__panel-text {
    width: 50%;
  }

  .layout--small-width .text-image__image-wrapper {
    width: 33.33%;
  }

  .layout--small-width .text-image__panel-text {
    width: 66.66%;
  }

  .layout--boxed .text-image__image-wrapper {
    width: 33.33%;
  }

  .layout--boxed .text-image__panel-text {
    width: 66.66%;
  }
}

.text-image__kicker {
  color: var(--teal-05);
  display: block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--micro);
  letter-spacing: 0.03em;
  line-height: var(--lh--150);
  margin-bottom: 0.8em;
  text-transform: uppercase;
}

@media screen and (min-width: 800px) {
  .text-image__kicker {
    font-size: var(--fs--super-small);
  }
}

.text-image__headline {
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--medium);
  font-weight: normal;
  line-height: var(--lh--150);
  margin: 0 0 var(--spacing-04);
  color: var(--grey-09);
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.text-image__headline--regular {
  font-family: var(--ff--sans);
}

.text-image__headline.font-family--serif {
  font-family: var(--ff--serif);
}

.text-image__body {
  color: var(--grey-07);
  font-size: var(--fs--super-small);
  line-height: var(--lh--150);
}

@media screen and (min-width: 1100px) {
  .text-image__body {
    font-size: var(--fs--extra-small);
  }
}

/* csslint-disable-next-line */
.text-image__body > p {
  margin-bottom: var(--spacing-03);
}

/* csslint-disable-next-line */
.text-image__body > p:last-child {
  margin: 0;
}

/* csslint-disable-next-line */
.text-image__body > p a {
  color: var(--teal-05);
  text-decoration: none;
}

.text-image__link {
  border-bottom: 2px solid var(--teal-04);
  color: var(--grey-07);
  font-family: var(--ff--sans-medium);
  line-height: 1.8;
  padding-bottom: 3px;
  text-decoration: none;
  transition: color 0.1s ease-in;
}

.text-image__link:hover,
.text-image__link:focus {
  color: var(--teal-05);
}

.text-image__toggler {
  display: block;
  text-align: center;
  margin: var(--spacing-04) 0 0;
  cursor: pointer;
}

.text-image__toggler .text {
  display: inline-block;
  vertical-align: middle;
}

.text-image__toggler .icon {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 6.66699V25.3337' stroke='%2300A095' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.66602 16H25.3327' stroke='%2300A095' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  content: "";
  height: 32px;
  right: 32px;
  top: 24px;
  transition: transform 0.1s ease-in;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
}

.text-image__panel-text--active .text-image__toggler .icon {
  transform: rotate(45deg);
}

.text-image.layout--image-top-toggler
  .text-image__panel-text--active
  .text-image__body {
  display: block;
}

@media screen and (min-width: 800px) {
  .text-image__toggler {
    display: none;
  }
}

.text + .text-image.layout--boxed {
  margin-top: calc(var(--spacing-08) * -1 + var(--spacing-06));
}
