:root {
  /* Teal */
  --teal-01: #eefffe;
  --teal-02: #c2fffb;
  --teal-03: #00b3a7;
  --teal-04: #00968c;
  --teal-05: #00766e;
  --teal-06: #006861;
  --teal-07: #00403c;
  --teal-08: #00302d;
  --teal-09: #00201e;
  --teal-10: #ebeeee;
  --teal-11: #dee9e8;

  /* Yellow */
  --yellow-01: #fffae5;
  --yellow-02: #fff0b3;
  --yellow-03: #ffe26c;
  --yellow-04: #ffd839;
  --yellow-05: #ffcd00;
  --yellow-06: #a68500;
  --yellow-07: #665200;
  --yellow-08: #4d3e00;
  --yellow-09: #2d2400;

  /* Black */
  --white: #ffffff;
  --grey-01: #ffffff;
  --grey-02: #f7f7f7;
  --grey-03: #f2f2f2;
  --grey-04: #ebebeb;
  --grey-05: #cccccc;
  --grey-06: #999999;
  --grey-07: #3d3d3d;
  --grey-08: #1a1a1a;
  --grey-09: #000000;

  /* Gradients */
  --gradient-01: linear-gradient(90deg, var(--teal-03) 0.01%, var(--yellow-03));
  --gradient-02: linear-gradient(90deg, var(--teal-04) 0.01%, var(--yellow-03));
  --gradient-03: linear-gradient(90deg, var(--teal-05) 0.01%, var(--yellow-03));
  --gradient-04: linear-gradient(90deg, var(--teal-03) 0.01%, var(--yellow-04));
  --gradient-05: linear-gradient(90deg, var(--teal-04) 0.01%, var(--yellow-04));
  --gradient-06: linear-gradient(90deg, var(--teal-05) 0.01%, var(--yellow-04));
  --gradient-07: linear-gradient(90deg, var(--teal-03) 0.01%, var(--yellow-05));
  --gradient-08: linear-gradient(90deg, var(--teal-04) 0.01%, var(--yellow-05));
  --gradient-09: linear-gradient(90deg, var(--teal-05) 0.01%, var(--yellow-05));
}
