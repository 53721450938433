/* csslint-disable-next-line */
#CybotCookiebotDialog {
  border: 1px solid #2a2a2a !important;
  padding: 25px !important;
  border-radius: 16px !important;
  box-shadow: none !important;
}

@media only screen and (max-width: 768px) {
  /* csslint-disable-next-line */
  #CybotCookiebotDialog {
    transform: scale(0.9) !important;
    padding: 5px !important;
    margin: 10px !important;
    overflow-y: scroll !important;
    height: 90vh !important;
    width: 100vw !important;
    top: 5% !important;
    left: 0 !important;
  }
}

/* csslint-disable-next-line */
#CybotCookiebotDialog a,
/* csslint-disable-next-line */
#CybotCookiebotDialog a:hover {
  color: #00a095 !important;
  font-family: var(--ff--sans) !important;
  font-size: var(--fs--super-small);
}

/* csslint-disable-next-line */
#CybotCookiebotDialog #CybotCookiebotDialogBodyContentTitle {
  color: #000 !important;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--small);
  line-height: var(--lh--150);
}

/* csslint-disable-next-line */
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonsSelectPane,
/* csslint-disable-next-line */
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelDetailsWrapper {
  border: 0 !important;
  background-color: transparent;
}

/* csslint-disable-next-line */
#CybotCookiebotDialogBodyLevelDetailsButton {
  font-family: var(--ff--sans-medium);
  line-height: var(--lh--150);
  font-size: var(--fs--micro);
}

/* csslint-disable-next-line */
#CybotCookiebotDialog
/* csslint-disable-next-line */
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--super-small);
  padding: 16px 12px !important;
  border-radius: 24px !important;
  color: var(--grey-07) !important;
  background-color: var(--grey-03) !important;
  border: none !important;
  font-weight: 700;
  width: 183px !important;
  margin-left: 0 !important;
  line-height: 1 !important;
}

/* csslint-disable-next-line */
#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a {
  font-weight: bold;
}

/* csslint-disable-next-line */
#CybotCookiebotDialog #CybotCookiebotDialog a,
/* csslint-disable-next-line */
#CybotCookiebotDialog #CybotCookiebotDialog a:hover {
  color: #00a095;
  font-size: 14px;
}

/* csslint-disable-next-line */
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--super-small);
  padding: 16px 12px !important;
  border-radius: 24px !important;
  color: #fff !important;
  background-color: var(--teal-04) !important;
  border: none !important;
  font-weight: 700;
  width: 183px !important;
  margin-left: 1em !important;
  line-height: 1 !important;
}

@media only screen and (max-width: 768px) {
  /* csslint-disable-next-line */
  #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    margin-left: 0 !important;
  }
}

/* csslint-disable-next-line */
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonsTable {
  margin-bottom: 30px;
}

/* csslint-disable-next-line */
#CybotCookiebotDialog
/* csslint-disable-next-line */
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
  order: 1 !important;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@media only screen and (max-width: 768px) {
  /* csslint-disable-next-line */
  #CybotCookiebotDialog
  /* csslint-disable-next-line */
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
    width: 50%;
    margin-left: 10px !important;
  }

  /* csslint-disable-next-line */
  #CybotCookiebotDialog
  /* csslint-disable-next-line */
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper
    /* csslint-disable-next-line */
    .CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    margin-left: 0 !important;
  }
}

/* csslint-disable-next-line */
#CybotCookiebotDialog
/* csslint-disable-next-line */
  input[type="checkbox"].CybotCookiebotDialogBodyLevelButton
  /* csslint-disable-next-line */
  + label {
  padding-left: 25px;
  background-position: left 3px;
  color: #48484a;
  font-family: var(--ff--sans);
  font-size: var(--fs--ultra-small);
  line-height: var(--lh--150);
  background-image: url("../../img/cookiebot/circle.svg");
}

/* csslint-disable-next-line */
#CybotCookiebotDialog
  /* csslint-disable-next-line */
  input[type="checkbox"].CybotCookiebotDialogBodyLevelButton:checked
  /* csslint-disable-next-line */
  + label {
  background-image: url("../../img/cookiebot/circle-checked.svg");
  background-position: left 3px;
}

/* csslint-disable-next-line */
#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText {
  letter-spacing: normal;
  color: #000;
  font-family: var(--ff--sans);
  font-size: var(--fs--super-small);
  line-height: var(--lh--150);
}

@media only screen and (max-width: 768px) {
  /* csslint-disable-next-line */
  #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText {
    font-size: 14px;
  }
}

/* csslint-disable-next-line */
#CybotCookiebotDialog
/* csslint-disable-next-line */
  .CybotCookiebotDialogDetailBodyContentCookieTypeTableContainer {
  display: block !important;
}

/* csslint-disable-next-line */
#CybotCookiebotDialogBodyLevelWrapper {
  display: flex !important;
  flex-direction: column;
}

/* csslint-disable-next-line */
#CybotCookiebotDialogDetail {
  margin-top: 1em !important;
}
