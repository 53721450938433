.call-to-action-cards {
  margin: 0 var(--spacing-sides) var(--spacing-08);
}

.call-to-action-cards__inner {
  max-width: var(--width-regular);
  margin: 0 auto;
}

.call-to-action-cards__text {
  margin: 0 auto var(--spacing-08);
  max-width: 736px;
  text-align: center;
}

.call-to-action-cards__course-item {
  margin-bottom: 32px;
}

@media screen and (min-width: 500px) {
  .call-to-action-cards__course-item {
    margin-bottom: 24px;
  }
}

.call-to-action-cards__course-item:last-child {
  margin-bottom: 0;
}

/* Card */

/*
.background--white .call-to-action-cards__card {
  background-color: var(--grey-02);
}
*/
.call-to-action-cards__card {
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 490px;
  padding: 24px;
  text-decoration: none;
  transition: box-shadow 0.1s ease-in;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto 300px;
  padding-top: 300px;
  text-align: center;
}

@media screen and (min-width: 800px) {
  .call-to-action-cards__card {
    min-height: 304px;
    background-size: auto 100%;
    flex-direction: row;
    padding: 12px 32px;
    background-position: right bottom;
    text-align: left;
  }
}

@media screen and (min-width: 1024px) {
  .call-to-action-cards__card {
    padding: 32px 62px;
  }
}

.call-to-action-cards__card-text {
  order: 1;
  padding: 15px 0 0;
}

@media screen and (min-width: 800px) {
  .call-to-action-cards__card-text {
    order: 0;
    padding: 15px 0 15px;
    width: 50%;
  }
}

.call-to-action-cards__title {
  margin: 0 0 1em;
  color: var(--grey-08);
  font-family: var(--ff--serif);
  font-size: var(--fs--large);
  font-weight: normal;
}

/* csslint-disable-next-line */
.color--white .call-to-action-cards__title {
  color: var(--grey-01);
}

.call-to-action-cards__description {
  color: var(--grey-07);
  font-size: var(--fs--super-small);
  line-height: var(--lh--150);
}

/* csslint-disable-next-line */
.color--white .call-to-action-cards__description {
  color: var(--grey-01);
}

/* csslint-disable-next-line */
.call-to-action-cards__description a {
  color: currentColor;
}

/* csslint-disable-next-line */
.call-to-action-cards__description a[href^="tel:"] {
  font-size: var(--fs--large);
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: var(--spacing-05) 0;
  line-height: 1;
}

/* csslint-disable-next-line */
.call-to-action-cards__description a[href^="tel:"]::before {
  width: 26px;
  height: 37px;
  margin-right: var(--spacing-04);
  background-color: currentColor;
  content: "";
  display: block;
  -webkit-mask-image: url("../../../assets/img/icons/phone.svg");
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  -webkit-mask-position: center center;
  mask-image: url("../../../assets/img/icons/phone.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center center;
}

.call-to-action-cards__button {
  background-color: var(--teal-06);
  border-radius: 16px;
  color: var(--grey-01);
  display: inline-block;
  font-size: var(--fs--super-small);
  font-weight: 600;
  line-height: var(--lh--150);
  min-height: 48px;
  padding: 11px 24px 12px;
  text-decoration: none;
  transition: background-color 0.1s ease-in;
}

.call-to-action-cards__button:hover {
  background-color: var(--teal-05);
}

.call-to-action-cards__image-container {
  align-items: center;
  display: flex;
  flex: 1;
}

.call-to-action-cards__image {
  display: block;
  margin: 0 auto;
  max-width: 294px;
}

/* Special cards */
@media screen and (max-width: 799.99px) {
  .call-to-action-cards[data-set*="cta_cards_phone"]
    .call-to-action-cards__card {
    padding-top: 230px;
    background-size: auto 230px;
  }
}

@media screen and (min-width: 800px) {
  .call-to-action-cards[data-set*="cta_cards_phone"]
    .call-to-action-cards__card {
    background-size: auto 90%;
    background-position: right top;
  }
}
