.image-rail {
  margin-bottom: var(--spacing-08);
}

.image-rail__intro {
  margin: 0 var(--spacing-sides) var(--spacing-08);
}

.image-rail__text {
  margin: 0 auto;
  max-width: 736px;
}

.image-rail__rail {
  overflow: hidden;
}

.image-rail__rail-item {
  margin-right: 8px;
  width: 300px;
}

@media screen and (min-width: 800px) {
  .image-rail__rail-item {
    margin-right: 24px;
    height: 600px;
    width: 600px;
  }
}

.image-rail__rail-item::before {
  content: "";
  display: block;
  padding-top: 100%;
}

/* Prevent CLS */

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .image-rail__rail-item {
  position: absolute;
  top: 0;
  opacity: 0;
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .image-rail__rail-item:nth-child(1) {
  opacity: 1;
  top: 0;
  position: relative;
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .image-rail__rail-item:nth-child(2) {
  opacity: 1;
  transform: translateX(calc((100% + 16px) * 1));
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .image-rail__rail-item:nth-child(3) {
  opacity: 0.8;
  transform: translateX(calc((100% + 16px) * 2));
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .image-rail__rail-item:nth-child(4) {
  opacity: 0.6;
  transform: translateX(calc((100% + 16px) * 3));
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .image-rail__rail-item:nth-child(5) {
  opacity: 0.4;
  transform: translateX(calc((100% + 16px) * 4));
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .image-rail__rail-item:nth-child(6) {
  opacity: 0.2;
  transform: translateX(calc((100% + 16px) * 5));
}
