.funnel-entry {
  position: relative;
  padding: var(--spacing-10) var(--spacing-sides);
}

/* csslint-disable-next-line */
.funnel-entry.layout--small-width {
  max-width: var(--width-regular);
  margin: 0 auto var(--spacing-08);
  padding: 0;
}

/* csslint-disable-next-line */
.funnel-entry.layout--regular .funnel-entry__content {
  position: relative;
  max-width: var(--width-regular);
  margin: 0 auto;
  padding: 0;
  align-items: center;
  gap: 100px;
  z-index: 4;
}

/* csslint-disable-next-line */
.funnel-entry.layout--boxed {
  margin: 0 auto var(--spacing-08);
}

.funnel-entry__panel-text {
  position: relative;
  max-width: 440px;
}

.funnel-entry__buttons {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 350px;
}

@media screen and (min-width: 800px) {
  .funnel-entry__content {
    display: flex;
    justify-content: center;
  }
}

/* Extra Options */
.funnel-entry__leaf {
  position: absolute;
  z-index: 3;
}

.funnel-entry__leaf-left {
  bottom: -60px;
  left: 0;
  width: 8%;
  max-width: 120px;
}

.funnel-entry__leaf-right {
  bottom: -100px;
  right: 0;
  width: 13%;
  max-width: 120px;
}

.funnel-entry__arrow-center {
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  text-align: center;
  width: 43%;
  max-width: 150px;
  left: 0;
  bottom: -100px;
  color: var(--teal-06);
  font-size: var(--fs--extra-small);
  transform: translateX(-100%);
}

@media screen and (min-width: 800px) {
  .funnel-entry__arrow-center {
    display: flex;
  }
}
