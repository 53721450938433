.logo-rows {
  margin-bottom: 128px;
}

@media screen and (min-width: 800px) {
  .logo-rows__rows {
    padding: 16px;
  }
}

.logo-rows__row {
  border-top: 2px solid var(--grey-04);
  display: flex;
  padding: 32px 0;
}

@media screen and (min-width: 800px) {
  .logo-rows__row {
    padding: 48px 16px;
  }
}

.logo-rows__logo {
  padding-right: 16px;
}

@media screen and (min-width: 800px) {
  .logo-rows__logo {
    padding-right: 48px;
  }
}

@media screen and (min-width: 1200px) {
  .logo-rows__logo {
    padding-right: 80px;
  }
}

.logo-rows__logo-inner {
  background-color: var(--grey-01);
  padding: 16px;
  text-align: center;
  width: 96px;
}

@media screen and (min-width: 800px) {
  .logo-rows__logo-inner {
    padding: 32px;
    width: 192px;
  }
}

@media screen and (min-width: 1200px) {
  .logo-rows__logo-inner {
    width: 256px;
  }
}

/* csslint-disable-next-line */
.logo-rows__logo img {
  margin: 0 auto;
  max-width: 100%;
}

.logo-rows__intro {
  color: var(--grey-08);
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--extra-small);
  font-weight: 500;
  line-height: var(--lh--150);
  margin: 0 0 32px;
}

.logo-rows__text-block {
  margin-bottom: var(--spacing-05);
}

.logo-rows__text-block:last-child {
  margin-bottom: 0;
}

.logo-rows__kicker {
  color: var(--grey-06);
  display: block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--micro);
  line-height: var(--lh--150);
  margin-bottom: 8px;
  text-transform: uppercase;
}

.logo-rows__text {
  color: var(--grey-06);
  font-family: var(--ff--sans);
  font-size: var(--fs--ultra-small);
  line-height: var(--lh--150);
}

/* csslint-disable-next-line */
.logo-rows__text a {
  color: var(--teal-05);
  text-decoration: none;
}

.logo-rows__link {
  color: var(--teal-05);
  display: block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--super-small);
  margin-bottom: var(--spacing-04);
  text-decoration: none;
}

.logo-rows__link::after {
  color: var(--grey-06);
  content: "";
  display: inline-block;
  height: 16px;
  margin-left: 4px;
  margin-top: -2px;
  vertical-align: middle;
  width: 16px;
}

.logo-rows__link--icon-arrow::after {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33398 8H12.6673' stroke='%233D3D3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 3.33325L12.6667 7.99992L8 12.6666' stroke='%233D3D3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.logo-rows__link--icon-download::after {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10' stroke='%233D3D3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.66602 6.66675L7.99935 10.0001L11.3327 6.66675' stroke='%233D3D3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 10V2' stroke='%233D3D3D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.logo-rows__link:last-child {
  margin-bottom: 0;
}
