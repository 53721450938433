.default-two-column__columns {
  margin: 0 var(--spacing-sides);
}

/* csslint-disable-next-line */
.default-two-column__column.boxed > * {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

/* csslint-disable-next-line */
.default-two-column__column.boxed > *.text .section-intro {
  padding-left: 0;
  padding-right: 0;
}

.default-two-column__columns--no-margin {
  margin: 0;
}

@media screen and (min-width: 1000px) {
  .default-two-column__columns {
    column-gap: var(--grid-gap);
    display: grid;
    grid-template-columns: var(--grid-columns);
    justify-content: center;
  }
}

.default-two-column__column:first-child {
  grid-column: span 5;
}

@media screen and (min-width: 1280px) {
  .default-two-column__column:first-child {
    grid-column: span 8;
  }
}

@media screen and (min-width: 1000px) {
  .default-two-column__column:last-child {
    grid-column: span 3;
  }
}

@media screen and (min-width: 1280px) {
  .default-two-column__column:last-child {
    grid-column: span 4;
  }
}
