:root {
  --waves-height: 20px;
}

@media screen and (min-width: 800px) {
  :root {
    --waves-height: 48px;
  }
}

.group {
  position: relative;
  padding: var(--spacing-08) 0;
  margin: 0 0 var(--spacing-08);
}

.group + .group {
  margin-top: calc(var(--spacing-08) * -1);
}

/* csslint-disable-next-line */
.group__inner > *:first-child {
  margin-top: 0 !important;
}

/* csslint-disable-next-line */
.group__inner > *:last-child {
  margin-bottom: 0 !important;
}

.group.group--spacing-bottom {
  padding-bottom: calc(var(--spacing-08) * 2);
}

/* csslint-disable-next-line */
.group.group--spacing-bottom + .call-to-action-cards {
  position: relative;
  z-index: 10;

  /* margin-top: calc((var(--spacing-08) + (var(--spacing-09) - var(--spacing-06))) * -1); */
  margin-top: calc((var(--spacing-08) * 2) * -1);
}

/* csslint-disable-next-line */
.group.group--spacing-bottom + .group .call-to-action-cards:first-child {
  position: relative;
  z-index: 10;

  /* margin-top: calc((var(--spacing-08) + (var(--spacing-09) - var(--spacing-06))) * -1) !important; */
  margin-top: calc((var(--spacing-08) * 2) * -1) !important;
}

/* Waves */
.group.group--has-waves-top {
  margin-top: calc(var(--spacing-08) + var(--waves-height));
}

.group.group--has-waves-bottom {
  margin-bottom: calc(var(--spacing-08) + var(--waves-height));
}

.group.group--has-waves-bottom + .group {
  margin-top: calc((var(--spacing-08) + var(--waves-height)) * -1);
  padding-top: calc(var(--spacing-08) + var(--waves-height));
}

.group__waves-top {
  bottom: 100%;
  content: "";
  display: block;
  height: var(--waves-height);
  position: absolute;
  width: 100%;
  z-index: 2;
  margin-bottom: -1px;
  overflow: hidden;
}

/* csslint-disable-next-line */
.group__waves-top svg {
  width: auto;
  height: 100%;
}

.group__waves-bottom {
  top: 100%;
  content: "";
  display: block;
  height: var(--waves-height);
  position: absolute;
  width: 100%;
  z-index: 2;
  margin-top: -1px;
  overflow: hidden;
}

/* csslint-disable-next-line */
.group__waves-bottom svg {
  width: auto;
  height: 100%;
}
