.quote-rail-new {
  margin-bottom: var(--spacing-08);
  padding: 0 var(--spacing-sides);
  overflow: hidden;
}

.quote-rail-new--full-width .quote-rail-new {
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

.quote-rail-new--full-width .quote-rail-new__content {
  padding: 0;
  position: relative;
}

.quote-rail-new__rail {
  position: relative;
  max-width: var(--width-regular);
  margin: 0 auto;

  /* margin: 0 calc( var(--spacing-sides) * -1 ); */
}

/* csslint-disable-next-line */
.background--white .quote-rail-new__rail::before {
  background: linear-gradient(
    -90.99deg,
    rgba(255, 255, 255, 0) 4.45%,
    #ffffff 95.14%
  );
}

/* csslint-disable-next-line */
.background--white .quote-rail-new__rail::after {
  background: linear-gradient(
    90.99deg,
    rgba(255, 255, 255, 0) 4.45%,
    #ffffff 95.14%
  );
}

.quote-rail-new__item {
  margin: 28px 0 0;
  width: 100%;
  padding: 21px var(--spacing-sides);
}

/* csslint-disable-next-line */
.quote-rail-new .flickity-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--teal-04);
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 48px;
  width: 48px;
  z-index: 2;
}

/* csslint-disable-next-line */
.quote-rail-new__rail .rail .flickity-button {
  display: block !important;
}

/* csslint-disable-next-line */
.quote-rail-new__rail .rail .flickity-button.previous {
  left: var(--spacing-sides);
}

/* csslint-disable-next-line */
.quote-rail-new__rail .rail .flickity-button.next {
  right: var(--spacing-sides);
}

/* csslint-disable-next-line */
.quote-rail-new .flickity-viewport {
  grid-column: span 2;
}

/* csslint-disable-next-line */

@media screen and (min-width: 800px) {
  .quote-rail-new__item {
    margin: 0;
    padding: 21px;
  }

  /* csslint-disable-next-line */
  .quote-rail-new__rail .rail .flickity-button.previous {
    left: 0;
  }

  /* csslint-disable-next-line */
  .quote-rail-new__rail .rail .flickity-button.next {
    right: 0;
  }
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .quote-rail-new__item {
  position: absolute;
  top: 0;
  opacity: 0;
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .quote-rail-new__item:nth-child(1) {
  opacity: 1;
  top: 0;
  position: relative;
}
