.quote {
  margin-bottom: var(--spacing-08);
  padding: 0 var(--spacing-sides);
}

.quote__box {
  position: relative;
  max-width: var(--width-regular);
  margin: 0 auto;
}

.quote__box-item {
  margin: 0;
  padding: 21px 0;
}

@media screen and (min-width: 800px) {
  .quote .quote__box-item {
    padding: 21px;
  }
}
