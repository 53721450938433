:root {
  --hero-stage-slider-height: 568px;
}

.hero-stage-slider {
  background-color: white;
  margin-bottom: var(--spacing-08);
  display: block;
  position: relative;
}

/** Wave image */
.hero-stage-slider::after {
  background-image: url("data:image/svg+xml,%3Csvg width='1920' height='48' viewBox='0 0 1920 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1920 25.3503V48H0.00134277C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0C1737.74 0 1810.67 10.1541 1889.44 21.1217C1899.51 22.5244 1909.68 23.9405 1920 25.3503Z' fill='%23F7F7F7'/%3E%3Cpath d='M0.00132628 48C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0H0V48H0.00132628Z' fill='red' fill-opacity='0' /%3E%3Cpath d='M1920 25.3503C1909.68 23.9405 1899.51 22.5244 1889.44 21.1217C1810.67 10.1541 1737.74 0 1646 0H1920V25.3503Z' fill='%23F7F7F7'/%3E%3C/svg%3E%0A");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 150%;
  bottom: 0;
  content: "";
  display: block;
  height: 48px;
  position: absolute;
  width: 100%;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  /** Wave image */
  .hero-stage-slider::after {
    bottom: 0;
    position: absolute;
    background-size: cover;
  }
}

.hero-stage-slider__content {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(--grid-gap);
}

@media screen and (min-width: 800px) {
  .hero-stage-slider__content {
    grid-template-columns: var(--grid-columns);
    margin: 0 var(--spacing-sides);
    height: var(--hero-stage-slider-height);
  }
}

.hero-stage-slider__intro {
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: var(--spacing-05);
}

@media screen and (max-width: 799px) {
  .hero-stage-slider__intro {
    text-align: center;
    margin: var(--spacing-06) var(--spacing-sides);
  }
}

@media screen and (min-width: 800px) {
  .hero-stage-slider__intro {
    grid-column: 1 / 5;
    grid-row: 1;
  }
}

@media screen and (min-width: 1000px) {
  .hero-stage-slider__intro {
    padding-left: var(--spacing-05);
  }
}

@media screen and (min-width: 1280px) {
  .hero-stage-slider__intro {
    grid-column: 1 / 8;
  }
}

.hero-stage-slider__logo {
  height: auto;
  width: 128px;
}

@media screen and (min-width: 800px) {
  .hero-stage-slider__logo {
    height: auto;
    width: 192px;
  }
}

@media screen and (max-width: 799px) {
  /* csslint-disable-next-line */
  .hero-stage-slider .text-block ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  /* csslint-disable-next-line */
  .hero-stage-slider .text-block li {
    flex: 0 0 auto;
    position: relative;
    display: block;
  }

  /* csslint-disable-next-line */
  .hero-stage-slider .text-block ul li::before {
    position: relative;
    left: 0;
  }
}

.hero-stage-slider__slides {
  position: relative;
  z-index: 2;
  overflow: hidden;
}

@media screen and (max-width: 799px) {
  .hero-stage-slider__slides {
    width: 100%;
    height: var(--hero-stage-slider-height);
  }
}

@media screen and (min-width: 800px) {
  .hero-stage-slider__slides {
    width: 580px;
    right: 0;
    position: absolute;
    top: 0;
    height: var(--hero-stage-slider-height);
  }
}

.hero-stage-slider__slide {
  position: relative;
  height: 100%;
  width: 100%;
}

.hero-stage-slider__slide-gradient {
  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8) 22.85%,
      rgba(255, 255, 255, 0) 100.08%
    ),
    linear-gradient(180deg, #ffffff 13.31%, rgba(255, 255, 255, 0) 99.12%);
  top: 0;
  height: 30%;
  left: 0;
  width: 100%;
  z-index: 2;
  display: block;
  position: absolute;
  pointer-events: none;
}

@media screen and (min-width: 800px) {
  .hero-stage-slider__slide-gradient {
    height: 100%;
    width: 50%;
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 30%,
        rgba(255, 255, 255, 0) 100.08%
      ),
      linear-gradient(90deg, #ffffff 13.31%, rgba(255, 255, 255, 0) 100%);
  }
}

.hero-stage-slider__slide-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 3;
  padding: 0 var(--spacing-02) var(--spacing-08);
  color: var(--grey-01);
  text-shadow: 1px 1px 5px var(--grey-09);
}

.hero-stage-slider__slide-image {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hero-stage-slider__slide-image-src {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

/* csslint-disable-next-line */
.hero-stage-slider .flickity-viewport {
  height: 100%;
}

/* csslint-disable-next-line */
.hero-stage-slider .flickity-slider {
  /* carousel height */
  height: 100%;
}

@media screen and (min-width: 800px) {
  .hero-stage-slider--has-trustbar.hero-stage-slider::after {
    position: inherit;
  }
}
