.feature-card {
  background-color: var(--grey-01);
  border-radius: 8px;
  height: 100%;
  padding: 24px;
  display: flex;
  align-items: flex-start;
}

.feature-card__content {
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .feature-card {
    padding: 32px;
  }
}

.feature-card__icon {
  background-size: cover;
  display: inline-block;
  height: 32px;
  margin-bottom: 16px;
  overflow: hidden;
  width: 32px;
}

.feature-card__fact {
  color: var(--teal-05);
  display: block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--medium);
  font-weight: 500;
  margin: 0 0 16px;
}

.feature-card__title {
  font-size: var(--fs--extra-small);
  font-family: var(--ff--sans-medium);
  font-weight: normal;
  line-height: var(--lh--150);
  margin: 0 0 16px;
  color: var(--grey-09);
}

.feature-card__text {
  color: var(--grey-06);
  font-size: var(--fs--ultra-small);
  line-height: var(--lh--150);
}

/* csslint-disable-next-line */
.feature-card__text > p {
  margin-top: 0;
}

/* csslint-disable-next-line */
.feature-card__text > p:last-child {
  margin-bottom: 0;
}

/* csslint-disable-next-line */
.feature-card__text a {
  color: var(--grey-07);
  border-bottom: 2px solid var(--teal-06);
  text-decoration: none;
  transition: color var(--link-transition) ease-in-out;
}

/* csslint-disable-next-line */
.feature-card__text a:hover,
/* csslint-disable-next-line */
.feature-card__text a:focus {
  color: var(--teal-05);
}
