.socials {
  padding-bottom: var(--spacing-08);
}

.socials__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-sides);
}

@media screen and (min-width: 767px) {
  .socials__content {
    padding-left: calc(var(--spacing-sides) + var(--spacing-05));
    flex-direction: row;
  }
}

.socials__headline {
  font-family: var(--ff--serif);
  font-size: var(--fs--medium-serif);
  font-weight: normal;
}

.socials__list {
  display: flex;
  list-style: none;
}

.socials__item {
  margin-right: var(--spacing-05);
}

.socials__item--margin {
  margin-left: -6px;
}

.socials__item-icon {
  fill: var(--teal-04);
  height: 32px;
  width: 32px;
}
