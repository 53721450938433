.section-intro {
  padding: 0 0 var(--spacing-02);
  margin: 0 auto;
}

/* csslint-disable-next-line */
.group__inner > .text > .section-intro {
  padding-left: var(--spacing-sides);
  padding-right: var(--spacing-sides);
}

/*
@media screen and (min-width: 800px) {
  .section-intro {
    padding-left: 32px;
    padding-right: 32px;
  }
}
*/
