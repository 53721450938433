.blog-category-intro {
  padding-bottom: var(--spacing-08);
}

@media screen and (min-width: 1000px) {
  .blog-category-intro {
    padding-left: var(--spacing-05);
  }
}

.blog-category-intro__subheadline {
  display: block;
  color: var(--teal-04);
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--ultra-small);
  font-weight: 500;
  margin: 0;
}

/* csslint-disable-next-line */
.blog-category-intro__content h1 {
  font-family: var(--ff--serif);
  font-size: var(--fs--extra-large-serif);
  font-weight: normal;
  margin: var(--spacing-05) 0;
  color: var(--grey-09);
}

@media screen and (min-width: 1000px) {
  /* csslint-disable-next-line */
  .blog-category-intro__content h1 {
    margin: var(--spacing-06) 0;
    max-width: 510px;
    line-height: 54px;
  }
}

/* csslint-disable-next-line */
.blog-category-intro__content p {
  color: var(--grey-07);
  font-size: var(--fs--extra-small);
  line-height: var(--lh--150);
}

/* csslint-disable-next-line */
.blog-category-intro__content p:not(:last-of-type) {
  margin-bottom: var(--spacing-04);
}

@media screen and (min-width: 1000px) {
  /* csslint-disable-next-line */
  .blog-category-intro__content p {
    max-width: 640px;
  }

  /* csslint-disable-next-line */
  .blog-category-intro__content p:not(:last-of-type) {
    margin-bottom: var(--spacing-06);
  }
}
