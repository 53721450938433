.course-card__course-card {
  background-color: white;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  min-height: 180px;
  padding: 1.5em 1.5em 2.5em 1.5em;
  text-decoration: none;
  transition: box-shadow 0.1s ease-in;
}

/* csslint-disable-next-line */
.course-card__course-card + .text-block-new__buttons .text-block-new__button {
  margin: -1em 0 0 0;
}

@media screen and (min-width: 500px) {
  .course-card__course-card {
    background-position: right top;
    min-height: 200px;
  }
}

@media screen and (min-width: 1024px) {
  .course-card__course-card {
    padding: 1.5em;
  }

  /* csslint-disable-next-line */
  .course-card__course-card + .text-block-new__buttons .text-block-new__button {
    margin: 1em 0 0 0;
  }
}

.course-card__course-card--link:hover {
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
}

.course-card__kicker {
  color: var(--grey-05);
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--micro);
  line-height: var(--lh--150);
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.course-card__title {
  color: var(--grey-07);
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--small);
  font-weight: normal;
  margin: 0 0 0.9em;
  flex-grow: 1;
  margin-bottom: 0;
}

.course-card__description {
  display: none;
}

@media screen and (min-width: 800px) {
  .course-card__description {
    color: var(--grey-06);
    display: block;
    flex-grow: 1;
    font-family: var(--ff--sans);
    font-size: var(--fs--super-small);
    line-height: var(--lh--150);
    margin-top: 0;
    max-width: 310px;
  }
}

.course-card__cost-coverage {
  align-items: center;
  display: flex;
}

.course-card__billing {
  color: var(--grey-07);
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--micro);
  letter-spacing: 0.04em;
  line-height: var(--lh--150);
  margin-right: 6px;
  text-transform: uppercase;
}

.course-card__coverage-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.course-card__coverage {
  background-color: var(--teal-04);
  border-radius: 4px;
  color: white;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--micro);
  letter-spacing: 0.06em;
  line-height: var(--lh--100);
  margin-right: 4px;
  padding: 5px 8px;
  text-transform: uppercase;
}

.course-card__coverage:last-child {
  margin-right: 0;
}
