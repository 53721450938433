.quote-rail {
  margin-bottom: var(--spacing-08);
  padding: 0 var(--spacing-sides);
}

.quote-rail--full-width .quote-rail {
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

.quote-rail--full-width .quote-rail__content {
  padding: 0;
  position: relative;
}

.quote-rail__rail {
  position: relative;
  max-width: var(--width-regular);
  margin: 0 auto;

  /* margin: 0 calc( var(--spacing-sides) * -1 ); */
}

.quote-rail__rail::before {
  background: linear-gradient(
    -90.99deg,
    rgba(247, 247, 247, 0) 4.45%,
    #f7f7f7 95.14%
  );
  content: "";
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: var(--spacing-sides);
  max-width: 80px;
  z-index: 1;
}

.quote-rail__rail::after {
  background: linear-gradient(
    90.99deg,
    rgba(247, 247, 247, 0) 4.45%,
    #f7f7f7 95.14%
  );
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: var(--spacing-sides);
  max-width: 80px;
  z-index: 1;
}

/* csslint-disable-next-line */
.background--white .quote-rail__rail::before {
  background: linear-gradient(
    -90.99deg,
    rgba(255, 255, 255, 0) 4.45%,
    #ffffff 95.14%
  );
}

/* csslint-disable-next-line */
.background--white .quote-rail__rail::after {
  background: linear-gradient(
    90.99deg,
    rgba(255, 255, 255, 0) 4.45%,
    #ffffff 95.14%
  );
}

.quote-rail__item {
  margin: 28px 0 0;
  width: 100%;
  padding: 21px var(--spacing-sides);
}

/* csslint-disable-next-line */
.quote-rail .flickity-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--teal-04);
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  height: 48px;
  position: absolute;
  top: 20px;
  width: 48px;
  z-index: 2;
}

/* csslint-disable-next-line */
.quote-rail__rail .rail .flickity-button {
  display: block !important;
  top: calc((170px / 2) + 40px); /* img-height/2 + margin-top */
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* csslint-disable-next-line */
.quote-rail__rail .rail .flickity-button.previous {
  right: unset;
  left: var(--spacing-sides);
}

/* csslint-disable-next-line */
.quote-rail__rail .rail .flickity-button.next {
  right: var(--spacing-sides);
  left: unset;
}

/* csslint-disable-next-line */
.quote-rail .flickity-viewport {
  overflow: hidden;
}

/* csslint-disable-next-line */

@media screen and (min-width: 800px) {
  .quote-rail__item {
    margin: 0;
    padding: 21px;
  }

  /* csslint-disable-next-line */
  .quote-rail__rail .rail .flickity-button {
    top: 50%;
  }

  /* csslint-disable-next-line */
  .quote-rail__rail .rail .flickity-button.previous {
    left: 0;
  }

  /* csslint-disable-next-line */
  .quote-rail__rail .rail .flickity-button.next {
    right: 0;
  }
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .quote-rail__item {
  position: absolute;
  top: 0;
  opacity: 0;
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .quote-rail__item:nth-child(1) {
  opacity: 1;
  top: 0;
  position: relative;
}
