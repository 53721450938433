.logo-wall {
  background-color: white;
  margin-bottom: var(--spacing-08);
  position: relative;
}

.logo-wall::before {
  background-image: url("data:image/svg+xml,%3Csvg width='1920' height='48' viewBox='0 0 1920 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1920 25.3503V48H0.00134277C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0C1737.74 0 1810.67 10.1541 1889.44 21.1217C1899.51 22.5244 1909.68 23.9405 1920 25.3503Z' fill='white'/%3E%3Cpath d='M0.00132628 48C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0H0V48H0.00132628Z' fill='%23F7F7F7' fill-opacity='1' /%3E%3Cpath d='M1920 25.3503C1909.68 23.9405 1899.51 22.5244 1889.44 21.1217C1810.67 10.1541 1737.74 0 1646 0H1920V25.3503Z' fill='%23F7F7F7'/%3E%3C/svg%3E%0A");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 150%;
  content: "";
  display: block;
  height: 48px;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  .logo-wall::before {
    background-size: cover;
    position: absolute;
  }
}

.logo-wall::after {
  background-image: url("data:image/svg+xml,%3Csvg width='1920' height='48' viewBox='0 0 1920 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1920 25.3503V48H0.00134277C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0C1737.74 0 1810.67 10.1541 1889.44 21.1217C1899.51 22.5244 1909.68 23.9405 1920 25.3503Z' fill='%23F7F7F7'/%3E%3Cpath d='M0.00132628 48C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0H0V48H0.00132628Z' fill='none' fill-opacity='0' /%3E%3Cpath d='M1920 25.3503C1909.68 23.9405 1899.51 22.5244 1889.44 21.1217C1810.67 10.1541 1737.74 0 1646 0H1920V25.3503Z' fill='%23F7F7F7'/%3E%3C/svg%3E%0A");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 150%;
  bottom: 0;
  content: "";
  display: block;
  height: 48px;
  position: relative;
  width: 100%;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  .logo-wall::after {
    background-size: cover;
    position: absolute;
  }
}

.logo-wall__content {
  margin: 0 var(--spacing-sides);
  padding-bottom: var(--spacing-08);
  padding-top: var(--spacing-08);
}

.logo-wall__text {
  margin: 0 auto var(--spacing-06);
  max-width: 736px;
  text-align: center;
}

.logo-wall__logos {
  text-align: center;
}

.logo-wall__logo {
  display: inline-block;
  margin: 32px;
  max-width: 180px;
  vertical-align: middle;
}
