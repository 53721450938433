.text-image-new {
  padding: 0 var(--spacing-sides);
}

/* csslint-disable-next-line */
.column-set__column .text-image-new {
  padding: 40px;
}

.text-image-new__panel-text {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 var(--spacing-sides) 0;
}

/* csslint-disable-next-line */
.column-set__column .text-image-new__panel-text {
  padding: 0;
}

/* csslint-disable-next-line */
.text-image-new.layout--small-width {
  max-width: var(--width-regular);
  margin: 0 auto var(--spacing-08);
  padding: 0;
}

/* csslint-disable-next-line */
.intro + .text-image-new.layout--small-width {
  margin-top: calc(var(--spacing-07) * -1);
}

/* csslint-disable-next-line */
.text-image-new.layout--boxed {
  margin: 0 auto var(--spacing-08);
}

/* csslint-disable-next-line */
.intro + .text-image-new.layout--boxed {
  margin-top: calc(var(--spacing-07) * -1);
}

/* csslint-disable-next-line */
.text-image-new.layout--regular {
  margin: 0 0 calc(var(--spacing-08) + 3em);
}

/* csslint-disable-next-line */
.layout--boxed .text-image-new__panel {
  background-color: var(--grey-02);
  border-radius: 16px;
  overflow: hidden;
}

/* csslint-disable-next-line */
.layout--image-top .text-image-new__panel {
  flex-direction: column;
  text-align: center;
  display: block;
}

/* csslint-disable-next-line */
.layout--image-top-toggler .text-image-new__panel {
  flex-direction: column;
  text-align: center;
}

.text-image-new__panel:last-child {
  margin-bottom: 0;
}

/* csslint-disable-next-line */
.text-image-new.layout--boxed > .text-image-new__panel {
  max-width: var(--width-regular);
  margin: 0 auto;
}

/* csslint-disable-next-line */
.text-image-new.layout--image-top {
  max-width: var(--width-regular);
  margin: 0 auto var(--spacing-08);
}

/* csslint-disable-next-line */
.text-image-new.layout--image-top-toggler {
  max-width: var(--width-regular);
  margin: 0 auto var(--spacing-08);
}

/* csslint-disable-next-line */
main
  /* csslint-disable-next-line */
  > .text-image-new.layout--small-width
  .text-image-new__image-wrapper::after {
  content: "";
  position: absolute;
  top: 20%;
  left: 0%;
  width: 100%;
  height: 400px;
  background: url("../../../assets/img/backgrounds/bubble-bg.svg") no-repeat;
  background-position: center;
  background-size: contain;
  z-index: -1;
}

@media screen and (min-width: 800px) {
  .text-image-new {
    margin: 0 var(--spacing-sides) var(--spacing-08);
  }

  main
    /* csslint-disable-next-line */
    > .text-image-new.layout--small-width
    .text-image-new__image-wrapper::after {
    top: 4%;
    left: -36%;
    width: 160%;
    background-size: auto 100%;
  }
}

.text-image-new__text {
  margin: 0 var(--spacing-sides) var(--spacing-08);
  max-width: 736px;
}

@media screen and (min-width: 800px) {
  .text-image-new__text {
    margin: 0 auto var(--spacing-08);
  }
}

@media screen and (min-width: 800px) {
  .text-image-new__panel {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    margin-bottom: var(--spacing-07);
  }
}

@media screen and (min-width: 950px) {
  .text-image-new__panel {
    margin-bottom: var(--spacing-09);
  }
}

.text-image-new__panel--right {
  flex-direction: row-reverse;
}

.text-image-new__image-wrapper {
  position: relative;
  align-items: center;
  display: flex;
  max-width: 100%;
  padding: var(--spacing-sides) var(--spacing-sides) 40px;
}

/* csslint-disable-next-line */
.text-image-new__image-wrapper * {
  flex: 1 1 100%;
}

/* csslint-disable-next-line */
.layout--boxed .text-image-new__image-wrapper {
  position: relative;
  object-fit: cover;
  padding: 0;
}

/* csslint-disable-next-line */
.layout--image-top .text-image-new__image-wrapper {
  position: relative;
  object-fit: contain;
  padding: 0;
  margin: 0 auto var(--spacing-04);
  max-width: calc(var(--width-small) / 2);
}

/* csslint-disable-next-line */
.layout--image-top-toggler .text-image-new__image-wrapper {
  position: relative;
  object-fit: contain;
  padding: 0;
  margin-bottom: var(--spacing-04);
}

@media screen and (min-width: 800px) {
  .text-image-new__image-wrapper {
    padding: 40px;
  }
}

@media screen and (min-width: 950px) {
  .text-image-new__image-wrapper {
    padding: 0;
  }
}

.text-image-new__panel:nth-child(2) .text-image-new__image-wrapper {
  padding: 40px var(--spacing-sides);
}

@media screen and (min-width: 950px) {
  .text-image-new__panel:nth-child(2) .text-image-new__image-wrapper {
    padding: 0;
  }
}

.text-image-new__image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 650px;
}

/* csslint-disable-next-line */
.layout--boxed .text-image-new__image {
  position: relative;
}

@media screen and (min-width: 800px) {
  /* csslint-disable-next-line */
  .layout--boxed .text-image-new__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

@media screen and (min-width: 950px) {
  /* csslint-disable-next-line */
  .layout--regular .text-image-new__image-wrapper {
    margin-top: 3em;
    margin-bottom: 3em;
  }

  /* csslint-disable-next-line */
  .layout--regular .text-image-new__image {
    margin-top: -3em;
    margin-bottom: -3em;
    width: 33vw;
  }
}

/* csslint-disable-next-line */
.text-image-new__panel-text > div {
  width: 100%;
}

/* csslint-disable-next-line */
.layout--boxed .text-image-new__panel-text {
  padding: var(--spacing-sides);
}

@media screen and (min-width: 800px) {
  /* csslint-disable-next-line */
  .layout--regular .text-image-new__panel-text {
    padding: 40px;
  }

  /* csslint-disable-next-line */
  .layout--boxed .text-image-new__panel-text {
    padding: 40px;
  }

  /* csslint-disable-next-line */
  .layout--small-width .text-image-new__panel-text {
    padding: 40px;
  }

  /* csslint-disable-next-line */
  .layout--small-width
    .text-image-new__panel--left
    .text-image-new__panel-text {
    padding-left: var(--spacing-sides);
  }

  /* csslint-disable-next-line */
  .layout--small-width
    .text-image-new__panel--right
    .text-image-new__panel-text {
    padding-right: var(--spacing-sides);
  }
}

@media screen and (min-width: 950px) {
  /* csslint-disable-next-line */
  .layout--regular .text-image-new__panel-text {
    padding: 60px;
  }

  /* csslint-disable-next-line */
  .layout--small-width
    .text-image-new__panel--left
    .text-image-new__panel-text {
    padding-left: var(--spacing-sides);
  }

  /* csslint-disable-next-line */
  .layout--small-width
    .text-image-new__panel--right
    .text-image-new__panel-text {
    padding-right: var(--spacing-sides);
  }
}

@media screen and (min-width: 800px) {
  /* csslint-disable-next-line */
  .layout--regular .text-image-new__image-wrapper {
    width: 43%;
  }

  /* csslint-disable-next-line */
  .layout--regular .text-image-new__panel-text {
    width: 57%;
  }

  /* csslint-disable-next-line */
  .layout--small-width .text-image-new__image-wrapper {
    width: 33.33%;
  }

  /* csslint-disable-next-line */
  .layout--small-width .text-image-new__panel-text {
    width: 66.66%;
  }

  /* csslint-disable-next-line */
  .layout--boxed .text-image-new__image-wrapper {
    width: 33.33%;
  }

  /* csslint-disable-next-line */
  .layout--boxed .text-image-new__panel-text {
    width: 66.66%;
  }
}

.text-image-new__kicker {
  color: var(--teal-05);
  display: block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--micro);
  letter-spacing: 0.03em;
  line-height: var(--lh--150);
  margin-bottom: 0.8em;
  text-transform: uppercase;
}

@media screen and (min-width: 800px) {
  .text-image-new__kicker {
    font-size: var(--fs--super-small);
  }
}

.text-image-new__headline {
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--small);
  font-weight: normal;
  line-height: var(--lh--120);
  margin: 0 0 var(--spacing-04);
  color: var(--grey-09);
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.text-image-new__headline--regular {
  font-family: var(--ff--sans);
}

/* csslint-disable-next-line */
.text-image-new__headline.font-family--serif {
  font-family: var(--ff--serif);
}

.text-image-new__body {
  color: var(--grey-08);
  font-size: var(--fs--super-small);
  line-height: var(--lh--150);
}

@media screen and (min-width: 1100px) {
  .text-image-new__body {
    font-size: var(--fs--extra-small);
  }
}

/* csslint-disable-next-line */
.text-image-new__body > p {
  margin-bottom: var(--spacing-03);
}

/* csslint-disable-next-line */
.text-image-new__body > p:last-child {
  margin: 0;
}

/* csslint-disable-next-line */
.text-image-new__body > p a {
  color: var(--teal-05);
  text-decoration: none;
}

.text-image-new__link {
  border-bottom: 2px solid var(--teal-04);
  color: var(--grey-07);
  font-family: var(--ff--sans-medium);
  line-height: 1.8;
  padding-bottom: 3px;
  text-decoration: none;
  transition: color 0.1s ease-in;
}

.text-image-new__link:hover,
.text-image-new__link:focus {
  color: var(--teal-05);
}

.text-image-new__toggler {
  display: block;
  text-align: center;
  margin: var(--spacing-04) 0 0;
  cursor: pointer;
}

/* csslint-disable-next-line */
.text-image-new__toggler .text {
  display: inline-block;
  vertical-align: middle;
}

/* csslint-disable-next-line */
.text-image-new__toggler .icon {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 6.66699V25.3337' stroke='%2300A095' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.66602 16H25.3327' stroke='%2300A095' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  content: "";
  height: 32px;
  right: 32px;
  top: 24px;
  transition: transform 0.1s ease-in;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
}

/* csslint-disable-next-line */
.text-image-new__panel-text--active .text-image-new__toggler .icon {
  transform: rotate(45deg);
}

/* csslint-disable-next-line */
.text-image-new.layout--image-top-toggler
  .text-image-new__panel-text--active
  .text-image-new__body {
  display: block;
}

@media screen and (min-width: 800px) {
  .text-image-new__toggler {
    display: none;
  }
}

/* csslint-disable-next-line */
.text + .text-image-new.layout--boxed {
  margin-top: calc(var(--spacing-08) * -1 + var(--spacing-06));
}

/* Extra Options */
.text-image-new__extra-leaf {
  position: absolute;
  z-index: 3;
}

.text-image-new__extra-leaf-left {
  bottom: 0;
  left: 0;
  width: 8%;
  max-width: 100px;
}

.text-image-new__extra-leaf-right {
  bottom: -30px;
  right: 0;
  width: 13%;
  max-width: 160px;
}

.text-image-new__extra-arrow-center {
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  text-align: center;
  width: 21%;
  max-width: 150px;
  left: 5%;
  bottom: -10%;
  color: var(--teal-06);
  font-size: var(--fs--extra-small);
  transform: translateX(-50%);
}

@media screen and (min-width: 800px) {
  .text-image-new__extra-arrow-center {
    display: flex;
  }
}
