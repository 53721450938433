.rail {
  position: relative;
}

/* csslint-disable-next-line */
.flickity-page-dots .dot {
  background-color: var(--teal-04);
}

/* csslint-disable-next-line */
.rail .flickity-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--teal-04);
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
  box-shadow: 0 06px 32px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  height: 48px;
  position: absolute;
  top: 0;
  width: 48px;
  z-index: 2;
  display: block !important;
}

/* csslint-disable-next-line */
.rail--centered .flickity-button {
  top: 50%;
  transform: translateY(-50%);
}

/* csslint-disable-next-line */
.rail .flickity-button:disabled {
  background-color: var(--grey-02);
  cursor: default;
}

/* csslint-disable-next-line */
.rail .flickity-button.previous {
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='22' viewBox='0 0 13 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 20L2 11L11 2' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: calc(100% - 19px) center;
  right: 56px;
}

/* csslint-disable-next-line */
.rail--centered .flickity-button.previous {
  left: var(--spacing-sides);
  right: auto;
}

/* csslint-disable-next-line */
.rail .flickity-button:disabled.previous {
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='22' viewBox='0 0 13 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 19.6533L2 10.6533L11 1.65332' stroke='%23CCCCCC' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

/* csslint-disable-next-line */
.rail .flickity-button.next {
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='22' viewBox='0 0 13 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 20L11 11L2 2' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: calc(100% - 16px) center;
  right: 0;
}

/* csslint-disable-next-line */
.rail--centered .flickity-button.next {
  right: var(--spacing-sides);
}

/* csslint-disable-next-line */
.rail .flickity-button:disabled.next {
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='22' viewBox='0 0 13 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 20L11 11L2 2' stroke='%23CCCCCC' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: calc(100% - 16px) center;
  right: 0;
}

/* csslint-disable-next-line */
.rail .flickity-button-icon {
  display: none;
}

@media screen and (min-width: 800px) {
  /* csslint-disable-next-line */
  .rail--centered .flickity-button.previous {
    left: 80px;
  }

  /* csslint-disable-next-line */
  .rail--centered .flickity-button.next {
    right: 80px;
  }
}
