.text-columns {
  position: relative;
  display: block;
  padding: 0 var(--spacing-sides);
  overflow: hidden;

  /* max-width: var(--width-large); */
  margin: 0 auto var(--spacing-08);
}

.text-columns__section-intro {
  padding-left: var(--spacing-sides);
  padding-right: var(--spacing-sides);
}

.text-columns--full-width {
  background-color: white;
  position: relative;
}

.text-columns .text-columns__section-intro {
  text-align: center;
  padding-bottom: var(--spacing-07);
}

.text-columns__columns {
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--spacing-03) * -1 + 2px) calc(var(--spacing-04) * -1);
  width: calc(100% + (var(--spacing-04) * 2));
  max-width: calc(100% + (var(--spacing-04) * 2));
  flex-direction: column;
  counter-reset: stepsCounter;
}

.text-columns__column {
  flex: 1 1 100%;
  padding: var(--spacing-03) var(--spacing-04);
}

.text-columns__column-title {
  font-size: var(--fs--extra-small);
  padding-left: 3.4em;
  position: relative;
  min-height: 2.6em;
  margin-top: 0;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
  justify-content: space-between;
}

.text-columns__column-title::before {
  counter-increment: stepsCounter;
  content: counter(stepsCounter);
  background: var(--teal-06);
  color: #ffffff;
  width: 1.8em;
  height: 1.8em;
  font-size: 1.5em;
  display: inline-block;
  line-height: 1.8em;
  font-weight: normal;
  border-radius: 50%;
  font-family: var(--ff--sans);
  margin: 0;
  flex: 0 0 auto;
  text-align: center;
  position: absolute;
  left: 0;
}

.text-columns__column-text {
  display: none;
}

.text-columns__column--active .text-columns__column-text {
  display: block;
}

.text-columns__toggler {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 6.66699V25.3337' stroke='%2300A095' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.66602 16H25.3327' stroke='%2300A095' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  content: "";
  height: 32px;
  right: 32px;
  top: 24px;
  transition: transform 0.1s ease-in;
  width: 32px;
  margin-left: 1em;
}

.text-columns__column--active .text-columns__toggler {
  transform: rotate(45deg);
}

@media screen and (min-width: 800px) {
  .text-columns__column-title {
    cursor: auto;
  }

  .text-columns__columns {
    flex-direction: row;
    margin: calc(var(--spacing-04) * -1 + 2px);
  }

  .text-columns__column {
    flex-basis: 50%;
    padding: var(--spacing-04);
  }

  .text-columns__toggler {
    display: none;
  }

  .text-columns__column-text {
    display: block;
  }
}

@media screen and (min-width: 1000px) {
  .text-columns__column {
    flex-basis: 50%;
  }
}

@media screen and (min-width: 1200px) {
  .text-columns__column {
    flex-basis: 25%;
  }
}
