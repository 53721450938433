.steps-new {
  margin: 0 var(--spacing-sides) var(--spacing-08);
}

.steps-new__panels {
  display: flex;
  flex-direction: column;
  justify-content: center;
  counter-reset: stepsCounter;
  margin: 3em -0 1em 0;
}

.steps-new__panel {
  width: 100%;
  max-width: 100%;
  text-align: left;
  font-family: var(--ff--sans-medium);
  font-weight: normal;
  margin: 0;
  position: relative;
  flex: auto;
  display: flex;
  align-items: center;
}

.steps-new__text {
  padding-left: 1rem;
  margin: 0;
}

.steps-new__panel:not(:last-child) {
  padding-bottom: 2rem;
}

.steps-new__panel::before {
  counter-increment: stepsCounter;
  content: counter(stepsCounter);
  background: var(--teal-06);
  color: #fff;
  width: 2em;
  height: 2em;
  font-size: var(--fs--medium);
  display: inline-block;
  line-height: 1.9em;
  font-weight: 400;
  border-radius: 50%;
  font-family: var(--ff--serif);
  margin: 0;
  flex: 0 0 auto;
  text-align: center;
}

/* csslint-disable-next-line */
.background--white .steps-new__panel::before {
  background: url("../../../assets/img/backgrounds/step-bg.svg") no-repeat;
  background-size: contain;
  width: 2.6em;
  height: 2.6em;
  color: var(--teal-06);
  line-height: 2.4em;
  font-weight: normal;
}

.steps-new__panel:not(:last-child)::after {
  content: "";
  position: absolute;
  background: var(--teal-06);
  font-size: 1.5em;
  top: 2.6em;
  height: calc(100% - 2.6em - 0.2em);
  width: 2px;
  left: 1.3em;
  margin-left: -1px;
}

/* csslint-disable-next-line */
.color--white .steps-new__panel:not(:last-child)::after {
  background-color: var(--teal-04);
}

@media screen and (min-width: 800px) {
  .steps-new__panels {
    flex-direction: row;
  }

  .steps-new__panel {
    max-width: 13em;
    text-align: center;
    margin: 0 2em;
    flex-direction: column;
  }

  .steps-new__panel:not(:last-child)::after {
    top: 1.2em;
    height: 2px;
    width: 83%;
    left: 50%;
    margin-left: 3rem;
  }

  .steps-new__panel:not(:last-child) {
    padding-bottom: 0;
  }

  .steps-new__text {
    padding: 1rem 0 0 0;
  }
}
