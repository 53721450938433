.trustbar {
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap);
  padding: 0 var(--spacing-sides);
  margin-bottom: var(--spacing-03);
  background-color: white;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 800px) {
  .trustbar {
    flex-direction: row;
    align-items: flex-start;
    margin: var(--spacing-05) 0 var(--spacing-06);
  }
}

.trustbar__item {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

@media screen and (min-width: 800px) {
  .trustbar__item {
    flex-basis: 0;
    flex-grow: 1;
  }
}

.trustbar__icon {
  flex-grow: 0;
  flex-shrink: 0;
  height: 42px;
  margin-right: var(--spacing-03);
  object-fit: contain;
  width: 42px;
  display: block;
}

@media screen and (min-width: 800px) {
  .trustbar__icon {
    margin-right: var(--spacing-04);
  }
}

@media screen and (min-width: 1440px) {
  .trustbar__icon {
    width: 64px;
    height: 64px;
  }
}

.trustbar__text {
  max-width: 440px;
  width: 100%;
}

@media screen and (min-width: 800px) {
  .trustbar__text {
    max-width: 380px;
  }
}

.trustbar__headline {
  color: var(--grey-08);
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--ultra-small);
  font-weight: normal;
  letter-spacing: var(--ls--default);
  line-height: var(--lh--120);
  margin: var(--spacing-01) 0;
}

@media screen and (min-width: 1440px) {
  .trustbar__headline {
    font-size: var(--fs--extra-small);
  }
}

.trustbar__description {
  color: var(--grey-07);
  font-size: var(--fs--micro);
  line-height: var(--lh--150);
  margin: 0;
}

@media screen and (min-width: 1440px) {
  .trustbar__description {
    font-size: var(--fs--ultra-small);
  }
}
