.accordion {
  margin-top: var(--spacing-04);
}

.accordion + .accordion {
  margin-top: var(--spacing-04);
}

/* csslint-disable-next-line */
.accordion + * {
  margin-top: calc(var(--spacing-04) + var(--spacing-04));
}

/* csslint-disable-next-line */
.accordion__title p {
  margin: 0;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--extra-small);
  font-weight: 400;
  transition: 0.3s ease 0s;
}

.accordion__title {
  padding: 1em 3em 1em 1em;
  border-bottom: 2px solid var(--grey-04);
  position: relative;
  cursor: pointer;
}

.accordion__content {
  padding: 1em 1em 1em 1em;
  line-height: var(--lh--150);
  display: none;
}

.accordion__content-inner {
  font-size: var(--fs--extra-small);
  line-height: var(--lh--150);
}

/* csslint-disable-next-line */
.accordion__content > *:first-child {
  margin-top: 0;
}

/* csslint-disable-next-line */
.accordion__content > *:last-child {
  margin-bottom: 0;
}

/* csslint-disable-next-line */
.accordion__content p {
  margin: 0.5em 0;
}

/* csslint-disable-next-line */
.accordion__content ul {
  margin: 1em 0;
}

.accordion__title::after {
  display: block;
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -0.5em;
  width: 1em;
  height: 1em;
  background-image: var(--arrow-teal);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
  transform: rotate(0deg);
  transition: 0.3s ease 0s;
}

/* csslint-disable-next-line */
.accordion--active .accordion__title p {
  color: var(--teal-04);
}

.accordion--active .accordion__title::after {
  transform: rotateX(180deg);
}

.accordion--active .accordion__content {
  display: block;
}
