/* csslint-disable-next-line */
.toc-inhaltsuebersicht .container {
  width: 100%;
  max-width: var(--width-regular);
  margin: 0 auto;
  padding: var(--spacing-08) var(--spacing-sides);
}

/* csslint-disable-next-line */
.toc-inhaltsuebersicht ol {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

/* csslint-disable-next-line */
.toc-inhaltsuebersicht ol li {
  padding: 0;
}

/* csslint-disable-next-line */
.toc-inhaltsuebersicht ol li::before {
  display: none;
}

.toc-inhaltsuebersicht__top {
  background-color: var(--teal-06);
  border-radius: 16px;
  border: unset;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--super-small);
  font-weight: normal;
  line-height: var(--lh--150);
  margin: var(--spacing-03) 4px 0 4px;
  min-height: 48px;
  padding: 11px 24px 12px;
  text-decoration: none;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  transition-property: background-color, opacity, bottom;
  position: fixed;
  bottom: var(--spacing-03);
  right: var(--spacing-03);
  z-index: 3;
}

.toc-inhaltsuebersicht__top:hover {
  background-color: var(--teal-05);
}

.toc-inhaltsuebersicht__top[aria-hidden="true"] {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}

@media (max-width: 999px) {
  .toc-inhaltsuebersicht__top--shifted {
    bottom: calc(48px + var(--spacing-03));
  }
}
