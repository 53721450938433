.teaser-cards {
  background-color: white;
  margin-bottom: var(--spacing-08);
  position: relative;
}

.teaser-cards::before {
  background-image: url("data:image/svg+xml,%3Csvg width='1920' height='48' viewBox='0 0 1920 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1920 25.3503V48H0.00134277C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0C1737.74 0 1810.67 10.1541 1889.44 21.1217C1899.51 22.5244 1909.68 23.9405 1920 25.3503Z' fill='white'/%3E%3Cpath d='M0.00132628 48C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0H0V48H0.00132628Z' fill='%23F7F7F7' fill-opacity='1' /%3E%3Cpath d='M1920 25.3503C1909.68 23.9405 1899.51 22.5244 1889.44 21.1217C1810.67 10.1541 1737.74 0 1646 0H1920V25.3503Z' fill='%23F7F7F7'/%3E%3C/svg%3E%0A");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 150%;
  content: "";
  display: block;
  height: 48px;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  .teaser-cards::before {
    background-size: cover;
    position: absolute;
  }
}

.teaser-cards::after {
  background-image: url("data:image/svg+xml,%3Csvg width='1920' height='48' viewBox='0 0 1920 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1920 25.3503V48H0.00134277C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0C1737.74 0 1810.67 10.1541 1889.44 21.1217C1899.51 22.5244 1909.68 23.9405 1920 25.3503Z' fill='%23F7F7F7'/%3E%3Cpath d='M0.00132628 48C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0H0V48H0.00132628Z' fill='none' fill-opacity='0' /%3E%3Cpath d='M1920 25.3503C1909.68 23.9405 1899.51 22.5244 1889.44 21.1217C1810.67 10.1541 1737.74 0 1646 0H1920V25.3503Z' fill='%23F7F7F7'/%3E%3C/svg%3E%0A");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 150%;
  bottom: 0;
  content: "";
  display: block;
  height: 48px;
  position: relative;
  width: 100%;
  z-index: 2;
}

@media screen and (min-width: 800px) {
  .teaser-cards::after {
    background-size: cover;
    position: absolute;
  }
}

.teaser-cards__content {
  margin: 0 var(--spacing-sides);
  padding-bottom: var(--spacing-08);
  padding-top: var(--spacing-08);
}

@media screen and (min-width: 1000px) {
  .teaser-cards__content {
    column-gap: var(--grid-gap);
    display: grid;
    grid-template-columns: var(--grid-columns);
  }
}

.teaser-cards__card {
  background-color: var(--grey-02);
  border-radius: 16px;
  margin: 0 auto;
  margin-bottom: var(--spacing-05);
  max-width: 480px;
}

@media screen and (min-width: 1000px) {
  .teaser-cards__card {
    grid-column: span 4;
    margin: var(--spacing-05);
    max-width: none;
  }
}

@media screen and (min-width: 1280px) {
  .teaser-cards__card {
    grid-column: span 6;
  }
}

.teaser-cards__card:first-child {
  margin-bottom: var(--spacing-04);
}

@media screen and (min-width: 1000px) {
  .teaser-cards__card:first-child {
    margin-bottom: inherit;
    margin-right: 0;
  }
}

@media screen and (min-width: 1280px) {
  .teaser-cards__card:first-child {
    margin-right: var(--spacing-05);
  }
}

.teaser-cards__card:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 1000px) {
  .teaser-cards__card:last-child {
    margin-bottom: inherit;
    margin-left: 0;
  }
}

@media screen and (min-width: 1280px) {
  .teaser-cards__card:last-child {
    margin-left: var(--spacing-05);
  }
}

.teaser-cards__image {
  border-top-right-radius: 16px;
  display: block;
  margin-left: auto;
  max-width: 100%;
}

.teaser-cards__text {
  padding: 0 16px 39px;
}

@media screen and (min-width: 800px) {
  .teaser-cards__text {
    padding: 0 48px 48px;
  }
}

.teaser-cards__kicker {
  color: var(--teal-04);
  display: inline-block;
  font-size: var(--fs--super-small);
  font-weight: 600;
  line-height: var(--lh--150);
  margin-bottom: 16px;
}

.teaser-cards__headline {
  color: var(--grey-07);
  font-size: var(--fs--extra-small);
  font-weight: 600;
  line-height: var(--lh--150);
  margin: 0 0 24px;
}

.teaser-cards__description {
  color: var(--grey-06);
  font-size: var(--fs--super-small);
  line-height: var(--lh--150);
  margin: 0 0 var(--spacing-05);
}

.teaser-cards__button {
  background-color: var(--teal-04);
  border-radius: 16px;
  color: white;
  display: inline-block;
  font-size: var(--fs--super-small);
  font-weight: 600;
  line-height: var(--lh--150);
  min-height: 48px;
  padding: 11px 24px 12px;
  text-decoration: none;
  transition: background-color 0.1s ease-in;
}

.teaser-cards__button:hover {
  background-color: var(--teal-05);
}
