.column-set {
  position: relative;
  display: block;
  padding: 0 var(--spacing-sides);
  overflow: hidden;
  max-width: var(--width-large);
  margin: 0 auto var(--spacing-07);
}

.column-set__inner {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-left: calc(var(--spacing-sides) * -1);
  margin-right: calc(var(--spacing-sides) * -1);
  width: calc(100% + (var(--spacing-04) * 2));
  max-width: calc(100% + (var(--spacing-04) * 2));
  flex-direction: column;
}

.column-set__column {
  flex: 1 1 100%;
  padding: var(--spacing-04) var(--spacing-sides);
}

/* csslint-disable-next-line */
.column-set__column .column-set__section-intro {
  padding: 0;
}

/* csslint-disable-next-line */
.column-set__column .text {
  margin: 0;
}

.column-set__column-inner {
  min-height: 100%;
}

/* csslint-disable-next-line */
.column-set .column-set__column-inner > *:first-child {
  margin-top: 0;
}

/* csslint-disable-next-line */
.column-set .column-set__column-inner > *:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 800px) {
  .column-set__inner {
    flex-direction: row;
    margin-left: calc(var(--spacing-si04des) * -1);
    margin-right: calc(var(--spacing-04) * -1);
  }

  .column-set--cols-1 .column-set__column {
    flex-basis: 100%;
    padding-left: var(--spacing-04);
    padding-right: var(--spacing-04);
  }

  .column-set--cols-2 .column-set__column {
    flex-basis: 50%;
    padding-left: var(--spacing-04);
    padding-right: var(--spacing-04);
  }

  .column-set--cols-3 .column-set__column {
    flex-basis: 33.33%;
    padding-left: var(--spacing-04);
    padding-right: var(--spacing-04);
  }

  .column-set--cols-4 .column-set__column {
    flex-basis: 25%;
    padding-left: var(--spacing-04);
    padding-right: var(--spacing-04);
  }
}

@media screen and (min-width: 1000px) {
  .column-set--cols-4 .column-set__column {
    flex-basis: 50%;
  }
}

@media screen and (min-width: 1200px) {
  .column-set--cols-4 .column-set__column {
    flex-basis: 25%;
  }
}

/* Background */
.column-set__column-inner[class*="background--"] {
  border-radius: 18px;
  box-shadow: 0 6px 21px rgb(0 0 0 / 20%);
}

.column-set.column-set--backgrounds {
  z-index: 10;
}

/* csslint-disable-next-line */
.column-set.column-set--backgrounds + .group[class*="background"] {
  margin-top: calc(
    (var(--spacing-07) + var(--spacing-04) + var(--spacing-07)) * -1
  );
  padding-top: calc(var(--spacing-08) + var(--spacing-07));
}

/* csslint-disable-next-line */
.text + .column-set {
  margin-top: calc((var(--spacing-08) - var(--spacing-06)) * -1);
}
