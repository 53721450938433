.sticky-box {
  position: fixed;
  top: calc(var(--header-height) + var(--spacing-06));
  box-shadow: var(--box-shadow-2);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  right: 0;
  padding: var(--spacing-03) var(--spacing-02);
  background-color: var(--teal-06);
  color: var(--grey-01);
  z-index: 9;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  max-width: 150px;
}

.sticky-box::before {
  content: "";
  background-image: var(--gradient-07);
  display: block;
  width: 100%;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
}

.sticky-box--hidden {
  pointer-events: none;
  transform: translateX(calc(100% + 20px));
}

.sticky-box__close {
  appearance: none;
  box-shadow: var(--box-shadow-2);
  position: absolute;
  z-index: 1;
  top: -10px;
  left: -10px;
  width: 20px;
  cursor: pointer;
  height: 20px;
  border-radius: 50%;
  background-color: var(--grey-01);
  border: 1px solid var(--grey-04);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.sticky-box__close:hover,
.sticky-box__close:focus {
  transform: scale(1.2);
}

.sticky-box__close-icon {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  -webkit-mask: url("./../../img/icons/close.svg") no-repeat;
  mask: url("./../../img/icons/close.svg") no-repeat;
  background-color: currentColor;
  -webkit-mask-size: 60% auto;
  mask-size: 60% auto;
  -webkit-mask-position: center;
  mask-position: center;
}

.sticky-box__headline {
  display: block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  margin: var(--spacing-02) 0;
}

.sticky-box__icon {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  display: block;
  -webkit-mask: url("./../../img/icons/download.svg") no-repeat;
  mask: url("./../../img/icons/download.svg") no-repeat;
  background-color: currentColor;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.sticky-box__link {
  color: var(--grey-01);
  display: block;
  text-decoration: none;
  background-color: var(--teal-04);
  border: none;
  border-radius: 16px;
  cursor: pointer;
  margin-top: var(--spacing-02);
  padding: var(--spacing-02);
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--super-small);
  transition: background-color 0.1s ease-in;
  width: 100%;
}

.sticky-box__link:hover {
  background-color: var(--teal-05);
}

.sticky-box__link-title {
  /* nothing here */
}
