.quote-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
}

.quote-card__images {
  position: relative;
  width: 100%;
  flex: 0 0 auto;
  max-width: 170px;
  margin: 0 auto;
}

.quote-card__images.quote-card--images-hide {
  width: 39px;
  min-height: 28px;
  margin-left: 0;
}

.quote-card__images-wrapper {
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}

.quote-card__content {
  flex: 1 1 auto;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
}

.quote-card__image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.quote-card__placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--grey-05);
}

.quote-card__placeholder::after {
  content: "";
  position: absolute;
  width: 40%;
  height: 40%;
  background-color: var(--grey-06);
  border-radius: 50%;
  left: 30%;
  top: 19%;
}

.quote-card__placeholder::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--grey-06);
  border-radius: 50%;
  left: 0;
  top: 65%;
}

.quote-card__images::after {
  content: "";
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  display: block;
}

.quote-card__headline {
  position: relative;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--extra-small);
  font-weight: normal;
  line-height: var(--lh--150);
  margin: 0 0 1em;
}

.quote-card__images::before {
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='28' viewBox='0 0 40 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.9871 15.575C31.9871 18.9875 29.5371 22.1375 22.7996 24.15L23.7621 28C34.1746 26.25 39.3371 18.725 39.3371 10.5C39.3371 4.1125 35.7496 0 31.1996 0C27.4371 0 24.1121 2.45 24.1121 6.825C24.1121 13.3875 31.9871 11.9875 31.9871 15.575ZM9.84961 15.575C9.84961 18.9875 7.39961 22.1375 0.662109 24.15L1.62461 28C12.0371 26.25 17.1996 18.725 17.1996 10.5C17.1996 4.1125 13.6121 0 9.06211 0C5.29961 0 1.97461 2.45 1.97461 6.825C1.97461 13.3875 9.84961 11.9875 9.84961 15.575Z' fill='%2300A095'/%3E%3C/svg%3E%0A");
  content: "";
  display: block;
  height: 28px;
  margin: 0;
  width: 39px;
  position: absolute;
  margin-right: 0.75em;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  left: 85%;
  bottom: 95%;
}

.quote-card__images.quote-card--images-hide::before {
  bottom: calc(95% - 28px);
  left: 0%;
}

.quote-card__text {
  color: var(--grey-06);
  line-height: var(--lh--150);
  margin: 0 0 1em;
}

.quote-card__quotee {
  color: var(--teal-05);
  font-style: normal;
  order: -1;
  margin: 0 0 1em 0;
}

@media screen and (min-width: 800px) {
  .quote-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 6px 21px rgb(0 0 0 / 20%);
    padding: 70px 60px;
    flex-direction: row;
  }

  .quote-card__content {
    margin-top: 0;
    padding-left: calc(40px + 0.75em - 6px);
  }

  .quote-card__quotee {
    order: unset;
    margin: 1em 0 0 0;
  }
}
