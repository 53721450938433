:root {
  --spacing-01: 4px;
  --spacing-02: 8px;
  --spacing-03: 12px;
  --spacing-04: 20px;
  --spacing-05: 32px;
  --spacing-06: 40px;
  --spacing-07: 44px;
  --spacing-08: 50px;
  --spacing-09: 60px;
  --spacing-10: 70px;

  /* spacings for main wrapper */
  --spacing-sides: 5vw;
  --spacing-top: var(--spacing-05);
}

@media screen and (min-width: 800px) {
  :root {
    --spacing-07: 48px;
    --spacing-07: 54px;
    --spacing-08: 64px;
    --spacing-09: 75px;
    --spacing-10: 100px;
    --spacing-sides: 6vw;
  }
}

@media screen and (min-width: 1280px) {
  :root {
    --spacing-sides: 5vw;
  }
}
