.new-tag {
  background-color: var(--teal-05);
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.04em;
  padding: 2px 8px;
  text-transform: uppercase;
  vertical-align: middle;
}
