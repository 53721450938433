.topbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
  padding: 0 var(--spacing-sides);
  transform: translateY(0);
  transition: transform 0.2s ease-in-out;
  background-color: var(--teal-06);
  z-index: 110;
}

.topbar.topbar--hide {
  transform: translateY(-100%);
}

.topbar__nav {
  width: 100%;
  max-width: 1920px;
  height: var(--topbar-height);
}

.topbar__nav:not(.topbar__nav-mobile) {
  display: none;
}

.topbar__nav.topbar__nav-mobile {
  display: block;
}

@media screen and (min-width: 800px) {
  .topbar__nav:not(.topbar__nav-mobile) {
    display: block;
  }

  .topbar__nav.topbar__nav-mobile {
    display: none;
  }
}

.topbar__nav-list {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding: 0;
  margin: 0;
  list-style: none;
  align-content: center;
  justify-content: flex-end;
  height: 100%;
}

.topbar__nav-item {
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
}

.topbar__nav-item:not(:last-child) {
  margin-right: 3em;
}

.topbar__nav-link {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #fff;
  text-decoration: none;
  opacity: 0.8;
}

.topbar__nav-link:hover {
  opacity: 1;
}

.topbar__nav-mobile .topbar__nav-list {
  justify-content: space-around;
}

.topbar__nav-mobile .topbar__nav-item:not(:last-child) {
  margin: 0;
}

.topbar__nav-mobile .topbar__nav-link {
  font-size: 0.8em;
}
