@font-face {
  font-family: "Untitled-Sans";
  src: url("../fonts/untitled-sans-web-regular.eot");
  src: url("../fonts/untitled-sans-web-regular.woff2") format("woff2"),
    url("../fonts/untitled-sans-web-regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Untitled-Sans-Medium";
  src: url("../fonts/untitled-sans-web-medium.eot");
  src: url("../fonts/untitled-sans-web-medium.woff2") format("woff2"),
    url("../fonts/untitled-sans-web-medium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Untitled-Serif";
  src: url("../fonts/untitled-serif-web-regular.eot");
  src: url("../fonts/untitled-serif-web-regular.woff2") format("woff2");
  src: url("../fonts/untitled-serif-web-regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Untitled-Serif-Medium";
  src: url("../fonts/untitled-serif-web-medium.eot");
  src: url("../fonts/untitled-serif-web-medium.woff2") format("woff2"),
    url("../fonts/untitled-serif-web-medium.woff") format("woff");
  font-display: swap;
}
