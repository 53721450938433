.icon[class*="icon-"] {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 1.2em;
  height: 1.2em;
}

.icon[class*="icon-"]::after {
  content: "";
  background-image: url("../../../assets/img/icons/checkmark.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: absolute;
  left: 0;
}

.icon.icon-alert::after {
  background-image: url("../../../assets/img/icons/alert.svg");
}

.icon.icon-ce::after {
  background-image: url("../../../assets/img/icons/ce.svg");
}

.icon.icon-checkmark::after {
  background-image: url("../../../assets/img/icons/checkmark.svg");
}

.icon.icon-factory::after {
  background-image: url("../../../assets/img/icons/factory.svg");
}

.icon.icon-info::after {
  background-image: url("../../../assets/img/icons/info.svg");
}

.icon.icon-mail::after {
  background-image: url("../../../assets/img/icons/mail.svg");
}

.icon.icon-md::after {
  background-image: url("../../../assets/img/icons/md.svg");
}

.icon.icon-udi::after {
  background-image: url("../../../assets/img/icons/udi.svg");
}
