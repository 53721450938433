.articles-rail-new {
  padding-top: var(--spacing-03);
  margin-bottom: var(--spacing-08);
  overflow: hidden;
}

.articles-rail-new .articles-rail-new__section-intro {
  padding-left: 0;
  padding-right: 0;
}

.articles-rail-new--is-blog-post {
  background-color: var(--grey-02);
  padding-bottom: var(--spacing-08);
  margin-bottom: 0;
}

.articles-rail-new__content {
  margin: 0 var(--spacing-sides);
}

.articles-rail-new__rail {
  padding: 0 var(--spacing-sides);
}

/* csslint-disable-next-line */
.articles-rail-new__rail .rail-new {
  grid-row-gap: 15px;
  padding-top: 21px;
}

/* csslint-disable-next-line */
.articles-rail-new__rail .flickity-viewport {
  grid-column: span 2;
}

.articles-rail-new__item {
  margin-right: 16px;
}

.articles-rail-new__button {
  text-align: center;
  margin: var(--spacing-05) 0 0;
}

/* csslint-disable-next-line */
.articles-rail-new__rail .rail-new .flickity-button.previous {
  position: absolute;
  top: -40px;
  right: 60px;
}

/* csslint-disable-next-line */
.articles-rail-new__rail .rail-new .flickity-button.next {
  position: absolute;
  top: -40px;
  right: 0;
}

/* Prevent CLS */

/* csslint-disable-next-line */
.rail-new:not(.flickity-enabled) .articles-rail-new__item {
  position: absolute;
  top: 21px;
  opacity: 0;
}

/* csslint-disable-next-line */
.rail-new:not(.flickity-enabled) .articles-rail-new__item:nth-child(1) {
  opacity: 1;
  top: 0;
  position: relative;
}

/* csslint-disable-next-line */
.rail-new:not(.flickity-enabled) .articles-rail-new__item:nth-child(2) {
  opacity: 1;
  transform: translateX(calc((100% + 16px) * 1));
}

/* csslint-disable-next-line */
.rail-new:not(.flickity-enabled) .articles-rail-new__item:nth-child(3) {
  opacity: 0.8;
  transform: translateX(calc((100% + 16px) * 2));
}

/* csslint-disable-next-line */
.rail-new:not(.flickity-enabled) .articles-rail-new__item:nth-child(4) {
  opacity: 0.6;
  transform: translateX(calc((100% + 16px) * 3));
}

/* csslint-disable-next-line */
.rail-new:not(.flickity-enabled) .articles-rail-new__item:nth-child(5) {
  opacity: 0.4;
  transform: translateX(calc((100% + 16px) * 4));
}

/* csslint-disable-next-line */
.rail-new:not(.flickity-enabled) .articles-rail-new__item:nth-child(6) {
  opacity: 0.2;
  transform: translateX(calc((100% + 16px) * 5));
}
