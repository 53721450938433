.navigation {
  position: fixed;
  top: var(--topbar-height);
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: var(--header-height);
  padding: 0 var(--spacing-sides);
  transform: translateY(0);
  transition: transform 0.2s ease-in-out;
  background-color: var(--grey-01);
  z-index: 100;
}

@media screen and (min-width: 800px) {
  .navigation.navigation--topbar-mobil-enabled {
    top: 0;
  }

  .navigation.navigation--topbar-desktop-enabled {
    top: var(--topbar-height);
  }
}

.navigation.navigation--hide {
  transform: translateY(-100%);
}

.navigation.navigation--hide.navigation--topbar-enabled {
  transform: translateY(calc(-100% - var(--topbar-height)));
}

.navigation__container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  max-width: 1920px;
}

.navigation__logo {
  align-self: center;
  height: 35px;
  padding: 0;
  z-index: 20;
}

/* MAIN NAVIGATION BAR */

.navigation__navbar {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 1.2em;
  z-index: 20;
}

.navigation__navbar-list {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation__navbar-item {
  display: none;
}

@media screen and (min-width: 900px) {
  .navigation__navbar {
    margin-right: -14px;
  }

  .navigation__navbar-item {
    box-sizing: border-box;
    display: block;
    margin-right: 0.5em;
    padding-left: 14px;
    padding-right: 14px;
    position: relative;
    text-align: center;
    text-decoration: none;
  }

  .style--primary .navigation__navbar-link {
    background-color: var(--teal-06);
    border-radius: 40px;
    color: white;
    min-height: 40px;
    padding: 3px 24px;
    text-decoration: none;
    transition: background-color 0.1s ease-in;
    display: flex;
    align-items: center;
  }

  .style--primary .navigation__navbar-link::before {
    display: none;
  }

  .style--secondary .navigation__navbar-link {
    background-color: var(--teal-11);
    box-sizing: border-box;
    border-radius: 40px;
    min-height: 40px;
    padding: 3px 24px;
    text-decoration: none;
    transition: background-color 0.1s ease-in;
    display: flex;
    align-items: center;
  }

  .style--primary .navigation__navbar-link:hover {
    background-color: var(--teal-05);
  }

  .style--secondary .navigation__navbar-link::before {
    display: none;
  }

  .style--secondary .navigation__navbar-link:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .navigation__navbar-item.style--secondary {
    order: 2;
    margin: 0;
  }

  .navigation__navbar-item:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .navigation__navbar-item {
    margin-right: 1.5em;
  }
}

@media screen and (min-width: 1280px) {
  .navigation__navbar-item {
    margin-right: 2em;
  }
}

.navigation__navbar-dropdown-label {
  align-items: center;
  color: var(--grey-08);
  cursor: pointer;
  display: flex;
  font-size: var(--fs--super-small);
  justify-content: center;
  position: relative;
  text-align: center;
}

.navigation__navbar-dropdown-label::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 100%;
  margin-top: 0.2em;
  background: var(--teal-06);
  height: 2px;
  opacity: 0;
  transition: opacity var(--link-transition) ease-in-out;
}

.navigation__navbar-dropdown-label.active::before,
.navigation__navbar-dropdown-label:hover::before,
.navigation__navbar-dropdown-label:focus::before {
  opacity: 1;
}

.navigation__navbar-dropdown-label::after {
  background-image: var(--arrow);
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 5px;
  margin-left: 0.3em;
  width: 9px;
}

.navigation__navbar-dropdown {
  position: absolute;
  top: calc(100%);
  left: 50%;
  transform: translateX(-50%);
  padding-top: 20px;
  overflow: hidden;
  opacity: 0;
  width: 0;
}

.navigation__navbar-dropdown-list {
  position: relative;
  background-color: white;
  border-radius: 0.5em;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.12);
  display: block;
  font-size: var(--fs--super-small);
  list-style: none;
  min-width: 180px;
  max-width: 280px;
  padding: 32px;
  text-align: left;
}

/* triangle */
.navigation__navbar-dropdown-list::after {
  background-color: white;
  content: "";
  height: 16px;
  left: 50%;
  position: absolute;
  top: -8px;
  transform: translateX(-50%) rotate(45deg);
  width: 16px;
}

.navigation__navbar-dropdown-label:focus + .navigation__navbar-dropdown,
.navigation__navbar-item:focus-within .navigation__navbar-dropdown {
  height: auto;
  opacity: 1;
  overflow: unset;
  transition: opacity var(--link-transition) ease-in-out;
  width: auto;
}

.navigation__navbar-dropdown-item {
  display: block;
  line-height: var(--lh--150);
  padding-top: 8px;
  padding-bottom: 8px;
}

.navigation__navbar-dropdown-item:first-child {
  padding-top: 0;
}

.navigation__navbar-dropdown-item:last-child {
  padding-bottom: 0;
}

.navigation__navbar-dropdown-link {
  display: block;
  color: var(--grey-08);
  text-decoration: none;
  transition: color var(--link-transition) ease-in-out;
}

.navigation__navbar-dropdown-link:hover,
.navigation__navbar-dropdown-link:focus {
  color: var(--teal-05);
}

.navigation__navbar-link {
  position: relative;
  border-radius: 100px;
  color: var(--grey-08);
  display: inline-block;
  letter-spacing: 0.01em;
  text-decoration: none;
  transition: color var(--link-transition) ease-in-out;
  vertical-align: middle;
}

.navigation__navbar-link::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 100%;
  margin-top: 0.2em;
  background: var(--teal-06);
  height: 2px;
  opacity: 0;
  transition: opacity var(--link-transition) ease-in-out;
}

.navigation__navbar-link.active::before,
.navigation__navbar-link:hover::before,
.navigation__navbar-link:focus::before {
  opacity: 1;
}

.navigation__navbar-button-link {
  border-radius: 80px;
  color: var(--grey-08);
  display: flex;
  height: 40px;
  justify-content: center;
  line-height: var(--lh--150);
  padding-bottom: 8px;
  padding-top: 8px;
  text-decoration: none;
  transition: all 0.1s ease-in;
  width: 40px;
}

@media screen and (min-width: 1024px) {
  .navigation__navbar-button-link {
    height: auto;
    min-width: 100px;
    width: auto;
  }
}

.navigation__navbar-button-link::before {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  content: "";
  display: block;
  height: 24px;
  margin-left: -1px;
  width: 24px;
}

@media screen and (min-width: 1024px) {
  .navigation__navbar-button-link::before {
    margin-left: 0;
    margin-right: 8px;
  }
}

.navigation__navbar-button-link:focus,
.navigation__navbar-button-link:hover {
  color: var(--teal-06);
}

.navigation__navbar-button-link:hover::before,
.navigation__navbar-button-link:focus::before {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21' stroke='rgb(0,160,149)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z' stroke='rgb(0,160,149)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.navigation__navbar-button-text {
  display: none;
}

@media screen and (min-width: 1024px) {
  .navigation__navbar-button-text {
    display: block;
  }
}

/* BURGER NAV / OVERLAY */

.navigation__burger {
  background-color: var(--teal-04);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 10px;
  position: relative;
  top: 1.2em;
  height: 40px;
  width: 40px;
  z-index: 20;
  transform: scale(1);
  transition: background-color 350ms ease-in-out, transform 350ms ease-in-out;
}

.navigation__burger:hover {
  background-color: var(--teal-05);
}

.navigation__slidein:focus-within .navigation__burger {
  background-color: transparent;
  padding: 10px;
  transform: scale(1.25);
}

.navigation__burger-line {
  background-color: var(--grey-01);
  border-radius: 2px;
  height: 2px;
  pointer-events: none;
  position: absolute;
  transform: rotate(0);
  transition: width 350ms ease-in-out, transform 350ms ease-in-out,
    background-color 350ms ease-in-out, opacity 350ms ease-in-out;
  width: calc(100% - 20px);
  z-index: 2000;
}

.navigation__burger-line--top {
  top: 13px;
}

.navigation__burger-line--middle {
  top: 19px;
}

.navigation__burger-line--middle-double {
  top: 19px;
}

.navigation__burger-line--bottom {
  top: 25px;
}

.navigation__slidein {
  display: block;
}

@media screen and (min-width: 900px) {
  .navigation__slidein {
    display: none;
  }
}

.navigation__slidein:focus-within
  .navigation__burger:hover
  .navigation__burger-line {
  background-color: var(--grey-07);
}

/* stylelint-disable */
.navigation__slidein:focus-within .navigation__burger-line {
  background-color: var(--grey-07);
  width: calc(100% - 20px);
}
/* stylelint-enable */

.navigation__slidein:focus-within .navigation__burger-line--top {
  opacity: 0;
}

.navigation__slidein:focus-within .navigation__burger-line--middle {
  transform: rotate(45deg);
}

.navigation__slidein:focus-within .navigation__burger-line--middle-double {
  transform: rotate(-45deg);
}

.navigation__slidein:focus-within .navigation__burger-line--bottom {
  opacity: 0;
}

.navigation__slidein-overlay {
  background-color: rgba(238, 239, 239, 0);
  cursor: not-allowed;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transition: background-color 350ms ease-in-out;
  width: 100vw;
  z-index: 10;
  pointer-events: none;
}

.navigation__slidein:focus .navigation__slidein-overlay,
.navigation__slidein:focus-within .navigation__slidein-overlay {
  background-color: rgba(238, 239, 239, 0.6);
  pointer-events: all;
}

.navigation__slidein:focus .navigation__slidein-overlay::before,
.navigation__slidein:focus-within .navigation__slidein-overlay::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(0deg, #fff0, #fff),
    linear-gradient(0deg, #fff0, #fff 20%);
  height: 5em;
  z-index: 1;
}

.navigation__slidein-nav-wrapper {
  background-color: #ffffff;
  box-shadow: var(--box-shadow-1);
  cursor: default;
  height: 100%;
  overflow: auto;
  padding-top: 6rem;
  position: absolute;
  right: 0;
  scroll-behavior: smooth;
  transform: translateX(200%);
  transition: transform 350ms ease-in-out;
  width: 100%;
  padding-bottom: 60px;
}

@media screen and (min-width: 640px) {
  .navigation__slidein-nav-wrapper {
    max-width: 30vw;
    min-width: 400px;
  }
}

/* stylelint-disable */
.navigation__slidein:focus .navigation__slidein-nav-wrapper,
.navigation__slidein:focus-within .navigation__slidein-nav-wrapper,
.navigation__slidein-nav-wrapper:focus {
  transform: translateX(0%);
}
/* stylelint-enable */

.navigation__slidein-nav {
  padding: 0 var(--spacing-sides);
}

.navigation__slidein-nav + .navigation__slidein-nav {
  position: relative;
  margin-top: 2em;
  padding-top: 2em;
}

.navigation__slidein-nav + .navigation__slidein-nav::before {
  content: "";
  position: absolute;
  left: var(--spacing-sides);
  right: var(--spacing-sides);
  top: -1px;
  background: var(--grey-04);
  height: 2px;
}

@media screen and (min-width: 640px) {
  .navigation__slidein-nav {
    padding-left: var(--spacing-03);
  }
}

.navigation__slidein-nav-list {
  margin: 0;
  padding: 0;
}

.navigation__slidein-nav-item {
  list-style: none;
  margin-bottom: 1.75em;
  user-select: none;
}

.navigation__slidein-nav-dropdown-list {
  height: 0;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  transition: opacity var(--link-transition) ease-in-out;
}

.navigation__slidein-nav-dropdown-item {
  list-style: none;
}

.navigation__slidein-nav-dropdown-item:first-child {
  margin-top: 0.75em;
}

.navigation__slidein-nav-label:focus + .navigation__slidein-nav-dropdown-list,
.navigation__slidein-nav-item:focus-within
  .navigation__slidein-nav-dropdown-list {
  height: auto;
  opacity: 1;
  overflow: unset;
}

.navigation__slidein-nav-label {
  color: var(--grey-07);
  display: inline-block;
  font-size: var(--fs--small);
  padding: 0 var(--spacing-04) 0 0;
  position: relative;
  user-select: none;
  cursor: pointer;
}

@media screen and (min-width: 640px) {
  .navigation__slidein-nav-label {
    padding: var(--spacing-02) var(--spacing-04);
  }
}

.navigation__slidein-nav-label::after {
  background-image: var(--arrow);
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  height: 100%;
  position: absolute;
  right: 0.3em;
  top: 0;
  width: 8px;
  transform: translateY(3px);
}

.navigation__slidein-nav-label:hover {
  color: var(--grey-08);
}

.navigation__slidein-nav-label:hover::after,
.navigation__slidein-nav-label:focus::after {
  background-image: var(--arrow);
}

.navigation__slidein-nav-item:focus .navigation__slidein-nav-label::after {
  transform: translateY(3px) rotate(180deg);
}

.navigation__slidein-nav-link {
  color: var(--grey-07);
  font-size: var(--fs--small);
  margin: 0 0 1.25em;
  padding: 0;
  text-decoration: none;
}

.navigation__slidein-nav-link:hover,
.navigation__slidein-nav-link:focus {
  color: var(--grey-08);
}

.navigation__slidein-nav-item-link {
  display: inline-block;
  border-bottom: 1px solid transparent;
  color: var(--grey-06);
  font-size: var(--fs--small);
  padding: var(--spacing-02) var(--spacing-03);
  position: relative;
  text-decoration: none;
  transition: color var(--link-transition) ease-in-out;
}

.navigation__slidein-nav-item.style--primary,
.navigation__slidein-nav-item.style--secondary {
  margin-bottom: 0.75em;
  display: flex;
}

.style--primary .navigation__slidein-nav-link {
  background-color: var(--teal-04);
  border-radius: 16px;
  color: white;
  min-height: 2.6em;
  padding: 3px 24px;
  text-decoration: none;
  transition: background-color 0.1s ease-in;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: var(--fs--extra-small);
}

.style--primary .navigation__slidein-nav-link::before {
  display: none;
}

.style--secondary .navigation__slidein-nav-link {
  background-color: white;
  border: 1px solid var(--teal-04);
  box-sizing: border-box;
  border-radius: 16px;
  color: var(--teal-04);
  min-height: 2.6em;
  padding: 3px 24px;
  text-decoration: none;
  transition: background-color 0.1s ease-in;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: var(--fs--extra-small);
}

.style--primary .navigation__slidein-nav-link:hover {
  background-color: var(--teal-05);
}

.style--secondary .navigation__slidein-nav-link::before {
  display: none;
}

.style--secondary .navigation__slidein-nav-link:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media screen and (min-width: 640px) {
  .navigation__slidein-nav-item-link,
  .navigation__slidein-nav-link {
    padding: var(--spacing-02) var(--spacing-04);
  }
}

.navigation__slidein-nav-item-link:hover,
.navigation__slidein-nav-item-link:focus {
  color: var(--grey-08);
}

.navigation__emergency-headline {
  color: var(--grey-06);
  font-size: var(--fs--extra-small);
  margin: 7rem var(--spacing-sides) 0;
  max-width: 400px;
}

@media screen and (min-width: 640px) {
  .navigation__emergency-headline {
    margin-left: var(--spacing-05);
  }
}

.navigation__emergency-text {
  margin: 0 var(--spacing-sides);
  max-width: 400px;
}

@media screen and (min-width: 640px) {
  .navigation__emergency-text {
    margin-left: var(--spacing-05);
  }
}

/* csslint-disable-next-line */
.navigation__emergency-text * {
  color: var(--grey-06);
  font-size: var(--fs--ultra-small);
  line-height: var(--lh--150);
}

/* csslint-disable-next-line */
.navigation__emergency-text a {
  text-decoration: none;
  transition: color var(--link-transition) ease-in-out;
}

/* csslint-disable-next-line */
.navigation__emergency-text a:hover {
  color: var(--grey-07);
}

.navigation__imprint-nav {
  margin: 3rem var(--spacing-sides) 20px;
}

@media screen and (min-width: 640px) {
  .navigation__imprint-nav {
    margin-left: 24px;
  }
}

.navigation__imprint-nav-list {
  display: flex;
  padding: 0;
}

.navigation__imprint-nav-item {
  list-style: none;
  position: relative;
}

.navigation__imprint-nav-item::after {
  background-color: var(--grey-01);
  bottom: 0.05em;
  content: "";
  height: 0.75em;
  position: absolute;
  width: 1px;
}

.navigation__imprint-nav-item:last-child::after {
  display: none;
}

.navigation__imprint-nav-link {
  color: var(--grey-06);
  font-size: var(--fs--micro);
  padding: 0 var(--spacing-02);
  text-decoration: none;
}

.navigation__imprint-nav-link:hover,
.navigation__imprint-nav-link:focus {
  color: var(--grey-07);
}
