.buttons {
  text-align: center;
  margin-bottom: var(--spacing-08);
}

/* csslint-disable-next-line */
.buttons .buttons__links a,
/* csslint-disable-next-line */
.buttons .buttons__links button {
  background-color: var(--teal-06);
  border-radius: 16px;
  border: unset;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--super-small);
  font-weight: normal;
  line-height: var(--lh--150);
  margin: var(--spacing-03) 4px 0 4px;
  min-height: 48px;
  padding: 11px 24px 12px;
  text-decoration: none;
  transition: background-color 0.1s ease-in;
}

/* csslint-disable-next-line */
.buttons .buttons__links a:disabled,
/* csslint-disable-next-line */
.buttons .buttons__links button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/* csslint-disable-next-line */
.buttons .buttons__links a:not(:disabled):hover,
/* csslint-disable-next-line */
.buttons .buttons__links button:not(:disabled):hover {
  background-color: var(--teal-05);
}

/* csslint-disable-next-line */
.buttons .buttons__links a.secondary,
/* csslint-disable-next-line */
.buttons .buttons__links button.secondary {
  background-color: #fff;
  border: 1px solid var(--teal-04);
  box-sizing: border-box;
  border-radius: 16px;
  color: var(--teal-04);
  display: inline-block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--super-small);
  font-weight: normal;
  line-height: var(--lh--150);
  margin: var(--spacing-03) 4px 0 4px;
  min-height: 48px;
  padding: 11px 24px 12px;
  text-decoration: none;
  transition: background-color 0.1s ease-in;
}

/* csslint-disable-next-line */
.buttons .buttons__links a.secondary:hover,
/* csslint-disable-next-line */
.buttons .buttons__links button.secondary:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
