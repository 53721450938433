.featured-courses-row {
  margin-bottom: var(--spacing-08);
}

.featured-courses-row__course-item {
  margin-bottom: 32px;
}

@media screen and (min-width: 500px) {
  .featured-courses-row__course-item {
    margin-bottom: 24px;
  }
}

.featured-courses-row__course-item:last-child {
  margin-bottom: 0;
}
