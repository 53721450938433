.image {
  padding: 0 var(--spacing-sides);
  margin: 0 auto var(--spacing-08);
}

.image__embed {
  margin-top: var(--spacing-04);
}

.image__embed-box {
  background-color: var(--grey-04);
  padding: var(--spacing-04);
}

.image__embed-textarea {
  margin-top: var(--spacing-04);
  padding: var(--spacing-02);
  border: 1px solid var(--grey-05);
  background-color: var(--grey-01);
  width: 100%;
  border-radius: 0;
  min-height: 100px;
  font-family: monospace;
  font-size: inherit;
  resize: vertical;
}
