/* every constants that dont "match" in other files should go here */
:root {
  --topbar-height: 2.4em;
  --header-height: 80px;
  --width-small: 800px;
  --width-regular: 1120px;
  --width-large: 1580px;
}

@media screen and (min-width: 800px) {
  :root {
    --width-small: 736px;
  }
}
