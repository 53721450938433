.text-block-new {
  width: 100%;
}

.text-block-new--centered {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-block-new__kicker {
  color: var(--teal-06);
  display: inline-block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--micro);
  letter-spacing: 0.03em;
  line-height: var(--lh--150);
  margin-bottom: 0.8em;
  text-transform: uppercase;
}

.text-block-new__kicker--lc {
  text-transform: unset;
}

/* csslint-disable-next-line */
.text-block-new ul {
  position: relative;
  list-style-type: none;
  padding-left: 2em;
}

/* csslint-disable-next-line */
.text-block-new li {
  margin-bottom: 8px;
}

/* csslint-disable-next-line */
.text-block-new ul li::before {
  position: absolute;
  left: 1em;
  content: "\2022 ";
  color: var(--teal-05);
  padding-right: 0.5em;
}

/* csslint-disable-next-line */
.text-block-new__text ol {
  position: relative;
  counter-reset: li;
  list-style-type: none;
}

/* csslint-disable-next-line */
.text-block-new__text ol li {
  counter-increment: li;
}

/* csslint-disable-next-line */
.text-block-new__text ol li::before {
  position: absolute;
  right: calc(100% - 2em);
  content: counter(li) ".";
  color: var(--teal-05);
  padding-right: 0.5em;
  font-family: var(--ff--sans-medium);
}

.text-block-new__benefitwrapper {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--spacing-05);
}

@media screen and (min-width: 800px) {
  .text-block-new__benefitwrapper {
    justify-content: flex-start;
  }

  .text-block-new__kicker {
    font-size: var(--fs--super-small);
  }
}

.text-block-new__benefit {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: var(--fs--ultra-small);
  font-weight: 600;
  text-align: left;
  color: #00403ccc;
  padding: var(--spacing-02) var(--spacing-03);
  background-color: #00403c0d;
  border-radius: 50px;
}

.text-block-new__headline {
  font-family: var(--ff--serif);
  font-size: var(--fs--super-large-serif);
  font-weight: normal;
  margin: 0 0 0.4em;
  color: var(--grey-09);
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
}

.text-block-new__headline:last-child {
  margin-bottom: 0;
}

.text-block-new__headline--small {
  font-family: var(--ff--serif);
  font-size: var(--fs--small-serif);
  font-weight: normal;
  margin: 0 0 0.4em;
}

.text-block-new__headline--medium {
  font-size: var(--fs--medium-serif);
}

.text-block-new__headline--large {
  font-size: var(--fs--large-serif);
}

.text-block-new__headline--extra-large {
  font-size: var(--fs--extra-large-serif);
}

.text-block-new__headline--ultra-large {
  font-size: var(--fs--ultra-large-serif);
}

.text-block-new__headline--centered {
  margin: 0 0 0.4em;
}

.text-block-new__text {
  font-size: var(--fs--super-small);
  line-height: var(--lh--150);
  margin: 0 0 1em;
}

/* csslint-disable-next-line */
.text-block-new__text p a {
  color: var(--teal-06);
  text-decoration: none;
}

/* csslint-disable-next-line */
.text-block-new__text p a:hover {
  color: var(--teal-05);
}

.text-block-new__text,
.text-block-new__link {
  color: var(--grey-07);
}

.text-block-new__text:last-child {
  margin-bottom: 0;
}

/* csslint-disable-next-line */
.text-block-new__text:last-child > *:last-child {
  margin-bottom: 0;
}

.text-block-new__link {
  border-bottom: 2px solid var(--teal-06);
  font-family: var(--ff--sans-medium);
  line-height: 1.8;
  padding-bottom: 3px;
  text-decoration: none;
  transition: color 0.1s ease-in;
}

.text-block-new__link:hover {
  color: var(--teal-06);
}

.text-block-new__button {
  background-color: var(--teal-06);
  border-radius: 16px;
  border: 0;
  color: white;
  display: inline-block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--super-small);
  font-weight: normal;
  line-height: var(--lh--150);
  margin: var(--spacing-03) 4px 0 4px;
  min-height: 48px;
  padding: 11px 24px 12px;
  text-decoration: none;
  transition: background-color 0.1s ease-in;
  cursor: pointer;
}

.text-block-new__button:hover {
  background-color: var(--teal-07);
}

.text-block-new__secondary-button {
  background-color: var(--teal-11);
  box-sizing: border-box;
  border-radius: 16px;
  border: 0;
  color: var(--teal-06);
  display: inline-block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--super-small);
  font-weight: normal;
  line-height: var(--lh--150);
  margin: var(--spacing-03) 4px 0 4px;
  min-height: 48px;
  padding: 11px 24px 12px;
  text-decoration: none;
  transition: background-color 0.1s ease-in;
  cursor: pointer;
}

.text-block-new__secondary-button:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.text-block-new__secondary-button:active {
  background-color: rgba(0, 0, 0, 0.12);
}

/* csslint-disable-next-line */
.color--white .text-block-new__secondary-button:hover {
  background-color: var(--grey-03);
}

/* csslint-disable-next-line */
.text-image-new__body ul {
  padding: 0;
  list-style: none;
  margin: 1em 0;
}

/* csslint-disable-next-line */
.text-image-new__body ul li {
  padding-left: 2.2em;
  position: relative;
}

/* csslint-disable-next-line */
.text-image-new__body ul li + li {
  margin-top: 1em;
}

/* csslint-disable-next-line */
.text-image-new__body ul li::before {
  background-image: url("../../../assets/img/icons/checkmark-bubble.svg");
  content: "";
  position: absolute;
  left: 0;
  top: -0.1em;
  width: 1.8em;
  height: 1.8em;
  background-size: contain;
}

.text-block-new__breadcrumb {
  margin-bottom: var(--spacing-04);
}

.text-block-new__breadcrumb-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0 0 -0.5em;
}

@media screen and (min-width: 800px) {
  .text-block-new__breadcrumb-list {
    justify-content: left;
  }
}

.text-block-new__breadcrumb-list-item {
  list-style: none;
  margin-bottom: 0.5em;
}

.text-block-new__breadcrumb-list-item:not(:nth-last-child(2))::after {
  color: currentColor;
  content: "›";
  margin-left: 0.1em;
  margin-right: 0.3em;
  opacity: 0.7;
}

.text-block-new__breadcrumb-list-item:last-child {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.text-block-new__breadcrumb-list-item-link {
  color: currentColor;
  opacity: 0.7;
  text-decoration: none;
  transition: all 0.1s ease-in;
}

.text-block-new__breadcrumb-list-item-link:hover {
  color: var(--teal-06);
  opacity: 1;
}
