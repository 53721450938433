.articles-rail {
  margin-bottom: var(--spacing-08);
  overflow: hidden;
}

.articles-rail .articles-rail__section-intro {
  padding-left: 0;
  padding-right: 0;
}

.articles-rail--is-blog-post {
  background-color: var(--grey-02);
  padding-bottom: var(--spacing-08);
  margin-bottom: 0;
}

.articles-rail__content {
  margin: 0 var(--spacing-sides);
}

.articles-rail__rail {
  padding: 0 var(--spacing-sides);
}

/* csslint-disable-next-line */
.articles-rail__rail .rail {
  padding-top: 21px;
}

.articles-rail__item {
  margin-right: 16px;
}

.articles-rail__button {
  text-align: center;
  margin: var(--spacing-05) 0 0;
}

/* Prevent CLS */

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .articles-rail__item {
  position: absolute;
  top: 21px;
  opacity: 0;
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .articles-rail__item:nth-child(1) {
  opacity: 1;
  top: 0;
  position: relative;
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .articles-rail__item:nth-child(2) {
  opacity: 1;
  transform: translateX(calc((100% + 16px) * 1));
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .articles-rail__item:nth-child(3) {
  opacity: 0.8;
  transform: translateX(calc((100% + 16px) * 2));
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .articles-rail__item:nth-child(4) {
  opacity: 0.6;
  transform: translateX(calc((100% + 16px) * 3));
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .articles-rail__item:nth-child(5) {
  opacity: 0.4;
  transform: translateX(calc((100% + 16px) * 4));
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .articles-rail__item:nth-child(6) {
  opacity: 0.2;
  transform: translateX(calc((100% + 16px) * 5));
}
