.logo-slider {
  margin-bottom: var(--spacing-08);
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
}

@media (min-width: 1920px) {
  .logo-slider {
    max-width: 1920px;
  }
}

.logo-slider__intro {
  margin: 0 var(--spacing-sides) var(--spacing-08);
}

.logo-slider__text {
  margin: 0 auto;
  max-width: 736px;
}

.logo-slider__items-wrapper {
  user-select: none;
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
}

.logo-slider__item {
  position: relative;
  width: 100px;
  height: 60px;
}

@media (min-width: 768px) {
  .logo-slider__item {
    width: 200px;
    height: 60px;
  }
}

.logo-slider__item-image {
  display: block;
  position: absolute;
  object-fit: contain;
  object-position: center center;
  width: 100%;
  height: 100%;
}
