/*
 * For accessibility reasons, the user-agent's native focus (outline) styles
 * should be left unmodified for the sake of keyboard navigability.
 *
 * This represents a *compromise* where the user-agent's outlines are removed
 * ONLY if we determine that the user is not interacting via their keyboard.
 *
 */

/* csslint-disable-next-line */
html:not([data-whatintent="keyboard"]) *:focus {
  outline: none; /* stylelint-disable-line declaration-property-value-blacklist */
}