.default {
  display: grid;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  font-family: var(--ff--sans);
  background-color: var(--grey-02);
  -moz-font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.default--white {
  background-color: white;
}

main > .group:last-child {
  margin-bottom: 0;
}

/*
.topbar-enabled main {
  padding-top: calc(var(--topbar-height) + var(--header-height));
}
*/
main {
  padding-top: var(--header-height);
}

.topbar-mobil-enabled main {
  padding-top: calc(var(--topbar-height) + var(--header-height));
}

@media screen and (min-width: 800px) {
  .topbar-mobil-enabled main {
    padding-top: var(--header-height);
  }

  .topbar-desktop-enabled main {
    padding-top: calc(var(--topbar-height) + var(--header-height));
  }
}

img {
  width: 100%;
  height: auto;
}

/* Colors */
.background--white {
  background-color: #ffffff;
}

.background--grey-01 {
  background-color: var(--grey-01);
}

.background--grey-02 {
  background-color: var(--grey-02);
}

.background--grey-03 {
  background-color: var(--grey-03);
}

.background--grey-04 {
  background-color: var(--grey-04);
}

.background--grey-05 {
  background-color: var(--grey-05);
}

.background--grey-06 {
  background-color: var(--grey-06);
}

.background--grey-07 {
  background-color: var(--grey-07);
}

.background--grey-08 {
  background-color: var(--grey-08);
}

.background--grey-09 {
  background-color: var(--grey-09);
}

.background--teal-01 {
  background-color: var(--teal-01);
}

.background--teal-02 {
  background-color: var(--teal-02);
}

.background--teal-03 {
  background-color: var(--teal-03);
}

.background--teal-04 {
  background-color: var(--teal-04);
}

.background--teal-05 {
  background-color: var(--teal-05);
}

.background--teal-06 {
  background-color: var(--teal-06);
}

.background--teal-07 {
  background-color: var(--teal-07);
}

.background--teal-08 {
  background-color: var(--teal-08);
}

.background--teal-09 {
  background-color: var(--teal-09);
}

.background--teal-10 {
  background-color: var(--teal-10);
}

.background--teal-11 {
  background-color: var(--teal-11);
}

table td > *:first-child {
  margin-top: 0;
}

table td > *:last-child {
  margin-bottom: 0;
}

.background--white.group *[class*="waves"] svg * {
  fill: #ffffff;
}

.background--grey-01.group *[class*="waves"] svg * {
  fill: var(--grey-01);
}

.background--grey-02.group *[class*="waves"] svg * {
  fill: var(--grey-02);
}

.background--grey-03.group *[class*="waves"] svg * {
  fill: var(--grey-03);
}

.background--grey-04.group *[class*="waves"] svg * {
  fill: var(--grey-04);
}

.background--grey-05.group *[class*="waves"] svg * {
  fill: var(--grey-05);
}

.background--grey-06.group *[class*="waves"] svg * {
  fill: var(--grey-06);
}

.background--grey-07.group *[class*="waves"] svg * {
  fill: var(--grey-07);
}

.background--grey-08.group *[class*="waves"] svg * {
  fill: var(--grey-08);
}

.background--grey-09.group *[class*="waves"] svg * {
  fill: var(--grey-09);
}

.background--teal-01.group *[class*="waves"] svg * {
  fill: var(--teal-01);
}

.background--teal-02.group *[class*="waves"] svg * {
  fill: var(--teal-02);
}

.background--teal-03.group *[class*="waves"] svg * {
  fill: var(--teal-03);
}

.background--teal-04.group *[class*="waves"] svg * {
  fill: var(--teal-04);
}

.background--teal-05.group *[class*="waves"] svg * {
  fill: var(--teal-05);
}

.background--teal-06.group *[class*="waves"] svg * {
  fill: var(--teal-06);
}

.background--teal-07.group *[class*="waves"] svg * {
  fill: var(--teal-07);
}

.background--teal-08.group *[class*="waves"] svg * {
  fill: var(--teal-08);
}

.background--teal-09.group *[class*="waves"] svg * {
  fill: var(--teal-09);
}

.background--teal-10.group *[class*="waves"] svg * {
  fill: var(--teal-10);
}

.background--teal-11.group *[class*="waves"] svg * {
  fill: var(--teal-11);
}

.color--white,
.color--white .text-block__headline,
.color--white .text-block__text,
.color--white .text-image__body {
  color: #fff;
}

.color--white .text-block__link {
  color: #fff;
}

table td ul {
  margin: 0.75em 0;
}

.list-style--checkmark ul,
.list-style--checkmark.text-block ul {
  padding: 0;
}

.list-style--checkmark li {
  position: relative;
  text-align: left;
  margin: 0.7em 0;
  padding: 0 0 0 40px;
  font-size: var(--fs--extra-small);
  list-style: none;
}

.list-style--checkmark ul li::before,
.list-style--checkmark.text-block ul li::before {
  content: "";
  background-image: url("../../../assets/img/icons/checkmark.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  vertical-align: middle;
  height: 32px;
  overflow: hidden;
  width: 32px;
  position: absolute;
  left: 0;
}

.hbspt-form {
  background: var(--grey-01);
  padding: var(--spacing-05);
}

.hbspt-form iframe {
  width: 100% !important;
}

.width--full {
  max-width: 100%;
}

.width--small {
  max-width: var(--width-small);
}

.width--regular {
  max-width: var(--width-regular);
}

.width--large {
  max-width: var(--width-large);
}

main .font-size--extra-small {
  font-size: var(--fs--extra-small);
}

main .font-size--small {
  font-size: var(--fs--small);
}

main .font-size--medium {
  font-size: var(--fs--medium);
}

main .font-size--large {
  font-size: var(--fs--large);
}

main .font-size--extra-large {
  font-size: var(--fs--extra-large);
}

main .font-size--ultra-large {
  font-size: var(--fs--ultra-large);
}

main .font-family--serif.font-size--small {
  font-size: var(--fs--small-serif);
}

main .font-family--serif.font-size--medium {
  font-size: var(--fs--medium-serif);
}

main .font-family--serif.font-size--large {
  font-size: var(--fs--large-serif);
}

main .font-family--serif.font-size--extra-large {
  font-size: var(--fs--extra-large-serif);
}

main .font-family--serif.font-size--ultra-large {
  font-size: var(--fs--ultra-large-serif);
}

/* Table */
table td p {
  margin: 0.75em 0;
}

table td {
  padding: 0.25em 0;
}

.product-labeling .icon {
  font-size: 1.4em;
}

.product-labeling .icon + .icon {
  margin-left: 0.65em;
}

.spacing-top {
  margin-top: var(--spacing-sides);
}

/* from section-intro.css */
main > .section-intro {
  padding-left: var(--spacing-sides);
  padding-right: var(--spacing-sides);
}

/* from text.css */
main > .text > .section-intro {
  width: 100%;
  max-width: var(--width-regular);
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

main > .text.width--full > .section-intro {
  max-width: 100%;
}
