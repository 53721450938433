.clipping__photo {
  display: block;
  margin-bottom: var(--spacing-03);
  width: 100%;
}

.clipping__date {
  color: var(--teal-05);
  display: block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--micro);
  font-weight: 500;
  line-height: var(--lh--150);
  margin-bottom: var(--spacing-02);
  text-transform: uppercase;
}

.clipping__text {
  color: var(--grey-06);
  font-size: var(--fs--ultra-small);
  line-height: var(--lh--150);
  margin: 0;
  width: 90%;
}

/* csslint-disable-next-line */
.clipping__text > p {
  margin: 0 0 1em;
}

/* csslint-disable-next-line */
.clipping__text > p:last-child {
  margin: 0;
}

/* csslint-disable-next-line */
.clipping__text a {
  color: var(--teal-05);
  text-decoration: none;
}
