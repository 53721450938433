.survey {
  background-color: var(--grey-01);
  position: relative;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin: 0 var(--spacing-sides) var(--spacing-08);
}

.survey__bar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: var(--spacing-02);
  background-image: var(--gradient-01);
}

.survey__back {
  position: absolute;
  box-shadow: 0 6px 32px rgb(0 0 0 / 20%);
  border-radius: 50%;
  width: 48px;
  appearance: none;
  border: unset;
  height: 48px;
  cursor: pointer;
  transform: translateY(-50%) translateX(50%);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='13' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 20l-9-9 9-9' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: calc(100% - 19px) 50%;
  background-color: var(--teal-04);
  background-repeat: no-repeat;
}

.survey__text {
  color: var(--teal-05);
  font-size: 130%;
}

.survey__wrapper {
  padding: var(--spacing-07) var(--spacing-05) var(--spacing-07)
    var(--spacing-08);
}

.survey__headlineText {
  margin-top: 0;
}

.survey__answers {
  margin-bottom: 0;
}

.survey__list {
  list-style: none;
  margin: 0;
  padding: 0 0 0 var(--spacing-05);
  display: flex;
  flex-direction: column;
}

.survey__listItem {
  display: flex;
}

.survey__readMore {
  margin-top: var(--spacing-06);
  margin-bottom: 0;
}

.survey__readMoreText {
  margin-bottom: 0;
}

.survey__embed {
  margin-top: var(--spacing-06);
}

.survey__embeddedelement {
  width: 100%;
  border: 0;
}
