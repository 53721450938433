.text {
  margin-bottom: var(--spacing-08);
}

.text__section-intro {
  padding-left: var(--spacing-sides);
  padding-right: var(--spacing-sides);
}

/* csslint-disable-next-line */
.group__inner > .text > .section-intro {
  width: 100%;
  max-width: var(--width-regular);
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

/* csslint-disable-next-line */
.group__inner.width--full > .text > .section-intro {
  max-width: 100%;
}
