.icon-text {
  margin: 0 var(--spacing-sides) var(--spacing-08);
}

.icon-text__icon {
  text-align: center;
}

.icon-text__text {
  margin: 0 auto var(--spacing-08);
  max-width: 736px;
  text-align: center;
}
