.funnel__ {
  max-width: 100vw;
  margin: 0;
  min-height: 100vh;
  color: var(--grey-08);
}

.funnel__wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  padding-top: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}

.funnel__image-left {
  position: absolute;
  bottom: 25px;
  left: 0;
  width: 10%;
  max-width: 150px;
}

.funnel__image-right {
  position: absolute;
  bottom: 25px;
  right: 0;
  width: 10%;
  max-width: 150px;
  transition: 0.5s ease-in-out;
}

.funnel__right-image-2,
.funnel__right-image-3 {
  width: 16%;
  max-width: 250px;
}

.funnel__topbar {
  text-align: center;
  font-size: var(--fs--micro);
  font-weight: 600;
  padding: var(--spacing-03) var(--spacing-sides);
}

.funnel__topbar-text {
  transition: 0.5s ease-in-out;
}

.funnel__logo {
  display: flex;
  justify-content: center;
}

.funnel__logo-img {
  max-width: 160px;
  margin: var(--spacing-08) 0 var(--spacing-08);
}

@media screen and (min-width: 800px) {
  .funnel__logo-img {
    margin: var(--spacing-05) 0 var(--spacing-08);
  }
}

/* Back Button */
.funnel__back-button {
  position: absolute;
  top: 60px;
  left: var(--spacing-sides);
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  padding-right: 1px;
  border-radius: 50px;
  border: 0;
  background-color: var(--teal-11);
}

.funnel__back-button:hover {
  cursor: pointer;
  background-color: #bed5d3;
}

/*  Progressbar  */

.funnel__progress-wrapper {
  padding: 0 var(--spacing-sides);
}

.funnel__progress {
  width: 100%;
  max-width: 330px;
  height: 8px;
  margin: 0 auto;
  background: var(--teal-10);
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: var(--spacing-06);
}

.funnel__progress-bar {
  width: 5%;
  height: 100%;
  border-radius: 10px;
  background: var(--teal-07);
  opacity: 0;
  transition: width 0.6s;
}

/* Funnel Steps Slide */
.funnel__steps {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.funnel__initially-hidden {
  opacity: 0 !important;
}

.funnel__show {
  transition: opacity 0.6s;
}

.funnel__step {
  position: absolute;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0 var(--spacing-sides);
  opacity: 0;
  transform: translateX(100%);
}

.funnel__step.funnel__active {
  position: relative;
  opacity: 1;
  transform: translateX(0);
}

.funnel__step.funnel__hidden {
  display: block;
  opacity: 0;
}

.funnel__step.funnel__slide-out {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.6s ease-in-out, opacity 0.6s;
}

.funnel__step.funnel__slide-in {
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.6s ease-in-out, opacity 0.6s;
}

.funnel__step-content {
  height: calc(100% - 38px);
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
}

.funnel__step-content-900 {
  max-width: 900px;
}

.funnel__step-content-800 {
  max-width: 800px;
}

.funnel__step-content-700 {
  max-width: 700px;
}

.funnel__step-content-600 {
  max-width: 600px;
}

.funnel__step-content-500 {
  max-width: 500px;
}

.funnel__text-image-headline {
  font-family: var(--ff--serif);
  font-weight: normal;
  margin: 0;
  text-align: center;
}

.funnel__info-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.funnel__infotext {
  font-size: 16px;
  text-align: center;
}

.funnel__infotext--left {
  max-width: 700px;
  text-align: left;
}

.funnel__infotext--right {
  text-align: right;
}

.funnel__text-block-text {
  font-size: 16px;
  color: var(--grey-07);
  margin: 0;
}

.funnel__list-head {
  display: inline-block;
  font-weight: 600;
  margin-bottom: var(--spacing-01);
}

.funnel__infotext-link {
  color: var(--teal-06);
}

/* Step Image */
.funnel__step-image {
  margin-bottom: var(--spacing-04);
}

.funnel__step-img {
  width: auto;
  max-height: 85px;
}

/* Funnel Hinweis */
.funnel__hinweis {
  opacity: 0.8;
  max-width: 700px;
}

.funnel__hinweis .funnel__text-block-text {
  font-size: var(--fs--ultra-small);
}

/* Funnel Download */
.funnel__download {
  width: 100%;
  max-width: 700px;
}

.funnel__download-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  font-weight: 600;
  text-decoration: none;
  color: var(--grey-09);
  padding: var(--spacing-04);
  width: 100%;
  background-color: var(--teal-10);
  border-radius: 8px;
  border: 0;
}

.funnel__download-button:hover {
  cursor: pointer;
}

.funnel__download-text-wrap {
  display: flex;
  align-items: center;
  gap: 16px;
}

.funnel__download-icon-load {
  padding: var(--spacing-02);
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: var(--grey-01);
}

/* Funnel buttons */
.funnel__buttons {
  display: grid;
  gap: 12px;
}

.funnel__column-1 {
  grid-template-columns: 1fr;
  width: 100%;
  max-width: 400px;
}

.funnel__column-2 {
  grid-template-columns: repeat(1, 1fr);
}

.funnel__column-3 {
  grid-template-columns: repeat(1, 1fr);
}

.funnel__icon-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 14px;
  color: var(--grey-08);
  text-align: left;
  font-weight: 600;
  background-color: var(--teal-11);
  border: 0;
  border-radius: 100px;
  transition: all 0.3s ease-in-out;
}

.funnel__icon-button:hover {
  cursor: pointer;
  background-color: #bed5d3;
}

.funnel__button-icon {
  max-width: 40px;
}

@media screen and (min-width: 800px) {
  .funnel__icon-button {
    justify-content: flex-start;
  }
}

/* Copy Texts */
.funnel__kopiertexte {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.funnel__kopierblock {
  text-align: center;
  color: var(--grey-07);
  padding: var(--spacing-05);
  background-color: var(--teal-10);
  border-radius: 20px;
  width: 100%;
  max-width: 490px;
}

.funnel__kopierblock-headline {
  margin-top: 0;
}

.funnel__kopierblock-copy-button {
  font-weight: 600;
  color: var(--teal-06);
  padding: var(--spacing-03) var(--spacing-05);
  margin-top: var(--spacing-03);
  background-color: #0068611a;
  border: 0;
  border-radius: 16px;
}

.funnel__kopierblock-copy-button:hover {
  cursor: pointer;
  background-color: #bed5d3;
}

@media screen and (min-width: 800px) {
  .funnel__kopiertexte {
    flex-wrap: nowrap;
  }
}

/* Funnel List */
.funnel__list {
  display: grid;
  grid-template-columns: 64px auto;
  gap: 24px;
  align-items: center;
  max-width: 700px;
}

.funnel__list-num-wrap {
  position: relative;
  display: flex;
  width: 64px;
  justify-content: center;
  align-items: center;
}

.funnel__list-num {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: var(--spacing-01);
  padding-left: var(--spacing-01);
  font-family: var(--ff--serif);
  font-size: var(--fs--medium);
  color: var(--teal-06);
}

/* Funnel Link Text */
.funnel__link-text-section {
  display: flex;
  justify-content: center;
  text-align: center;
}

.funnel__text-link {
  color: var(--grey-08);
}

/* Funnel Link Button */
.funnel__link-button {
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
}

.funnel__text-block-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  min-width: 280px;
  max-width: fit-content;
  color: var(--grey-01);
  font-weight: 600;
  text-decoration: none;
  padding: var(--spacing-03) var(--spacing-04);
  background-color: var(--teal-06);
  border: 0;
  border-radius: 16px;
}

.funnel__text-block-button:hover {
  cursor: pointer;
  background-color: var(--teal-07);
}

/* Horizontale Liste */
.funnel__horizontal-steps {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.funnel__horizontal-step {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  flex: 1;
  text-align: left;
  position: relative;
  z-index: 1;
}

.funnel__horizontal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 0 var(--spacing-02);
}

.funnel__horizontal-step-number-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 14px;
  height: 100%;
}

.funnel__line-left,
.funnel__line-right {
  width: 2px;
  height: 100%;
  min-height: 10px;
}

.funnel__line-left.funnel__active,
.funnel__line-right.funnel__active {
  background-color: #316561cc;
}

.funnel__horizontal-step .funnel__list-num {
  color: var(--grey-01);
}

.funnel__horizontal-step-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  max-width: fit-content;
  font-weight: 600;
  font-size: var(--fs--micro);
  line-height: 1;
  white-space: nowrap;
  color: var(--teal-07);
  padding: var(--spacing-02) var(--spacing-03);
  border-radius: 50px;
  background: #00403c0d;
}

.funnel__horizontal-step-badge-icon {
  max-width: 12px;
}

.funnel__horizontal-step-headline {
  font-size: var(--fs--ultra-small);
  font-weight: 600;
  margin: 0;
}

.funnel__horizontal-step-text {
  font-size: var(--fs--micro);
  margin: 0;
}

@media screen and (min-width: 800px) {
  .funnel__horizontal-steps {
    flex-direction: row;
  }

  .funnel__horizontal-step {
    flex-direction: column;
    text-align: center;
  }

  .funnel__horizontal-content {
    align-items: center;
  }

  .funnel__horizontal-step-number-wrap {
    flex-direction: row;
    width: 100%;
  }

  .funnel__line-left,
  .funnel__line-right {
    width: 100%;
    height: 2px;
    min-height: auto;
  }
}

/* Validierung */

.funnel__validation {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
}

.funnel__validation-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 375px;
}

.funnel__validation-content-rezept {
  max-width: 700px;
}

.funnel__rezept-embed {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.funnel__rezept_embed_content {
  display: none;
}

.funnel__rezept-agbs {
  display: flex;
  align-items: flex-start;
  gap: 14px;
}

.funnel__rezept-agbs-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border: 1px solid var(--teal-06);
  border-radius: 2px;
  background: var(--teal-10);
  cursor: pointer;
}

.funnel__rezept-agbs-checkbox:checked {
  background-color: var(--teal-06);
  border-color: var(--teal-06);
}

.funnel__rezept-agbs-checkbox:checked::after {
  content: "";
  display: block;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: relative;
  top: 3px;
  left: 8px;
}

.funnel__rezept-agbs-label {
  font-size: var(--fs--micro);
}

.funnel__validation-input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  color: #11111399;
  padding: var(--spacing-01);
  border-radius: 8px;
  border: 1px solid #11111333;
  overflow: hidden;
}

.funnel__validation-select {
  color: #11111399;
  width: 100%;
  padding: var(--spacing-02);
  border: 0;
  background-color: transparent;
}

.funnel__validation-select:focus,
.funnel__validation-select:hover {
  cursor: pointer;
  color: var(--grey-08);
}

.funnel__validation-icon {
  padding: var(--spacing-03) var(--spacing-02) var(--spacing-03)
    var(--spacing-03);
}

.funnel__validation-input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0;
}

.funnel__validation-input::-webkit-input-placeholder {
  color: #11111399;
}

.funnel__validation-input::-moz-placeholder {
  color: #11111399;
}

.funnel__validation-input:-moz-placeholder {
  color: #11111399;
}

.funnel__validation-input:-ms-input-placeholder {
  color: #11111399;
}

.funnel__validation-info {
  font-size: var(--fs--micro);
  margin-top: var(--spacing-02);
  color: #11111399;
}

.funnel__validation-error {
  font-size: var(--fs--micro);
  margin-top: var(--spacing-02);
  color: var(--mat-stepper-header-error-state-icon-foreground-color);
}

/* Upload */
.funnel__upload-text-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  min-height: 120px;
  font-weight: 600;
  text-align: center;
  padding: var(--spacing-03);
  background: var(--teal-10);
  border-radius: 8px;
  border: 2px dashed var(--teal-06);
}

.funnel__uploaded {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  min-height: auto;
  text-align: left;
  border-color: transparent;
}

.funnel__upload-data {
  font-size: var(--fs--ultra-small);
  font-weight: 500;
  opacity: 0.5;
}

.funnel__uploaded .funnel__upload-data,
.funnel__upload-icon {
  display: none;
}

.funnel__uploaded .funnel__upload-icon {
  display: block;
  padding: var(--spacing-02);
  width: 35px;
  height: 35px;
  border-radius: 8px;
  background: var(--grey-01);
}

.funnel__browse-button {
  color: var(--teal-06);
}

.funnel__browse-button:hover {
  cursor: pointer;
}

/* Trust-Elements */
.funnel__trust {
  display: flex;
  justify-content: center;
}

.funnel__trustbar {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  max-width: 950px;
  margin: 0 auto;
}

.funnel__trustbar-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.funnel__trustbar-icon {
  max-width: 64px;
  object-fit: contain;
  object-position: center;
}

.funnel__trustbar-text {
  opacity: 0.8;
}

.funnel__trustbar-headline {
  color: var(--grey-08);
  font-size: var(--fs--ultra-small);
  margin: 0;
}

.funnel__trustbar-description {
  font-size: var(--fs--micro);
  margin: 4px 0 0;
  color: var(--grey-07);
}

/* Review */
.funnel__review {
  display: flex;
  gap: 32px;
  align-items: center;
  max-width: 550px;
  color: var(--grey-06);
  font-size: var(--fs--micro);
  padding: var(--spacing-04) var(--spacing-04);
  margin: 0 auto;
  border-radius: 20px;
  background-color: var(--teal-10);
}

.funnel__review-image {
  position: relative;
  max-width: 76px;
  min-width: 60px;
}

.funnel__review-svg {
  position: absolute;
  top: -5px;
  right: -8px;
  color: var(--teal-06);
  width: 45%;
}

.funnel__review-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0;
}

.funnel__review .funnel__review-name {
  color: var(--teal-06);
}

/* Studies */
.funnel__trust-images {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-06);
  flex-wrap: wrap;
}

.funnel__trust-studies-text {
  text-align: center;
  font-size: var(--fs--micro);
  margin-top: 0;
  margin-bottom: var(--spacing-05);
}

.funnel__trust-images .funnel__trust-img {
  max-width: max-content;
}

/* Partner */
.funnel__partner-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  max-width: 360px;
  font-size: var(--fs--micro);
  text-align: right;
}

.funnel__partner-text {
  margin: 0;
}

.funnel__partner-spacer {
  display: block;
  width: 1px;
  height: 40px;
  background-color: var(--teal-07);
  opacity: 0.1;
}

.funnel__partner-right,
.funnel__partner-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.funnel__partner-img {
  width: auto;
  max-width: 130px;
  height: auto;
  max-height: 50px;
}

.funnel__partner-right {
  text-align: left;
}

@media screen and (min-width: 800px) {
  /* Funnel buttons */
  .funnel__column-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .funnel__column-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .funnel__trustbar {
    flex-wrap: nowrap;
  }
}
