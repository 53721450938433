/* csslint-disable-next-line */
html {
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  /* csslint-disable-next-line */
  html {
    scroll-behavior: auto;
  }
}
