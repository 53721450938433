.faq-list {
  margin: 0 var(--spacing-sides) var(--spacing-08);
}

.faq-list .faq-list__section-intro {
  padding-left: 0;
  padding-right: 0;
}

.faq-list__faq {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: block;
  margin-bottom: 34px;
  padding: 26px 32px;
  position: relative;
  text-align: left;
  width: 100%;
}

.faq-list__faq::before {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 6.66699V25.3337' stroke='%2300A095' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.66602 16H25.3327' stroke='%2300A095' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  content: "";
  height: 32px;
  position: absolute;
  right: 32px;
  top: 24px;
  transition: transform 0.1s ease-in;
  width: 32px;
}

.faq-list__faq--active::before {
  transform: rotate(45deg);
}

.faq-list__faq:last-child {
  margin-bottom: 0;
}

.faq-list__headline {
  font-size: var(--fs--extra-small);
  line-height: var(--lh--150);
  margin: 0 40px 0 0;
  color: var(--grey-09);
}

.faq-list__text {
  display: none;
  max-width: 680px;
}

/* csslint-disable-next-line */
.faq-list__text > p {
  color: var(--grey-07);
  font-size: var(--fs--extra-small);
  line-height: var(--lh--150);
  margin: var(--spacing-03) 0 var(--spacing-03);
  padding: 0;
}

/* csslint-disable-next-line */
.faq-list__text > figure {
  margin: var(--spacing-04) 0 var(--spacing-04);
  padding: 0;
  overflow-y: hidden;
}

/* csslint-disable-next-line */
.faq-list__text > figure > img {
  max-width: 100%;
}

/* csslint-disable-next-line */
.faq-list__faq--active .faq-list__text {
  display: block;
}

/* csslint-disable-next-line */
.faq-list__text a {
  color: var(--teal-05);
  text-decoration: none;
}

.faq-list__button {
  background-color: var(--teal-04);
  border-radius: 16px;
  color: white;
  display: inline-block;
  font-size: var(--fs--super-small);
  font-weight: 600;
  line-height: var(--lh--150);
  min-height: 48px;
  margin: var(--spacing-07) 0 0;
  padding: 11px 24px 12px;
  text-decoration: none;
  transition: background-color 0.1s ease-in;
}

.faq-list__button:hover {
  background-color: var(--teal-05);
}

@media screen and (min-width: 800px) {
  .faq-list__button {
    margin-left: 32px;
  }
}
