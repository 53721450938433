.icon-text-list__wrap {
  text-align: center;
  margin: 0 var(--spacing-sides);
}

.icon-text-list__list-wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 750px;
  margin: var(--spacing-06) auto;
}

.icon-text-list__item {
  display: flex;
  align-items: center;
  gap: 20px;
  text-align: left;
  padding: var(--spacing-03) var(--spacing-04);
  background-color: var(--teal-11);
  border-radius: 50px;
}

.icon-text-list__item-icon {
  width: 100%;
  max-width: 60px;
  height: auto;
}

/* csslint-disable-next-line */
.icon-text-list__item-icon svg {
  width: 100%;
  height: auto;
}

.icon-text-list__item-textbox {
  width: 100%;
  text-align: left;
}

.icon-text-list__item-headline {
  font-size: var(--fs--extra-small);
  margin-top: 0;
  margin-bottom: var(--spacing-02);
}

.icon-text-list__item-text {
  font-size: var(--fs--ultra-small);
  color: var(--grey-07);
  margin: 0;
}

@media screen and (min-width: 800px) {
  .icon-text-list__item-headline {
    font-size: var(--fs--small);
  }

  .icon-text-list__item-icon {
    max-width: 88px;
  }
}
