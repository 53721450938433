.newsletter {
  margin: 0;
}

.newsletter:last-child {
  margin-bottom: 0;
}

.newsletter__wrapper {
  background-color: var(--teal-06);
  border-radius: 8px;
  padding: var(--spacing-sides);
}

.newsletter__wrapper #sib-container {
  background: transparent;
}

@media screen and (min-width: 800px) {
  .newsletter__wrapper {
    padding: 2em;
  }
}

@media screen and (min-width: 1024px) {
  .newsletter__content {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.newsletter__text {
  margin-bottom: 18px;
}

.newsletter__title {
  color: var(--grey-01);
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--small);
  font-weight: normal;
  line-height: var(--lh--150);
  margin: 0;
}

.newsletter__legal {
  width: 100%;
}

.newsletter__description {
  color: var(--grey-01);
  font-size: var(--fs--micro);
  line-height: var(--lh--150);
  opacity: 0.6;
  text-align: left;
}

/* csslint-disable-next-line */
.newsletter__description > p {
  margin: 0;
}

/* csslint-disable-next-line */
.newsletter__description > p > a {
  color: var(--teal-05);
  text-decoration: none;
}

@media screen and (min-width: 1024px) {
  .newsletter__title {
    padding-right: 40px;
  }

  .newsletter__text {
    max-width: 44%;
  }

  .newsletter__action {
    max-width: 56%;
  }
}

.newsletter__form {
  margin-bottom: 20px;
}

@media screen and (min-width: 550px) {
  .newsletter__form {
    display: flex;
    justify-content: stretch;
  }
}

@media screen and (min-width: 1024px) {
  .newsletter__form {
    max-width: none;
  }
}

.newsletter__form-input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: block;
  flex-grow: 1;
  height: 56px;
  margin-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  transition: border 0.2s ease-in;
  min-width: 320px;
}

.newsletter__form-break {
  flex-basis: 100%;
  height: 0;
}

@media screen and (min-width: 550px) {
  .newsletter__form-input {
    display: inline;
    margin-right: 13px;
  }
}

.newsletter__form-input::-webkit-input-placeholder {
  color: var(--grey-06);
}

.newsletter__form-input::-moz-placeholder {
  color: var(--grey-06);
}

.newsletter__form-input:-ms-input-placeholder {
  color: var(--grey-06);
}

.newsletter__form-input:-moz-placeholder {
  color: var(--grey-06);
}

.newsletter__form-input:focus {
  border: 1px solid var(--grey-05);
}

.newsletter__form-button {
  background-color: var(--teal-04);
  border: none;
  border-radius: 16px;
  color: white;
  cursor: pointer;
  flex-grow: 1;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--super-small);
  height: 56px;
  transition: background-color 0.1s ease-in;
  width: 100%;
}

@media screen and (min-width: 550px) {
  .newsletter__form-button {
    max-width: 217px;
    min-width: 133px;
  }
}

.newsletter__form-button:focus,
.newsletter__form-button:hover {
  background-color: var(--teal-05);
}

.g-recaptcha * {
  max-width: 100%;
}

.g-recaptcha.sib-visible-recaptcha > * {
  max-width: 100% !important;
  width: 100% !important;
}

.newsletter #sib-container {
  margin-bottom: 10px;
}

.newsletter .sib-form .form__entry {
  padding: 0 !important;
}

.newsletter .sib-input {
  flex: 1 1 100%;
  border-radius: 8px !important;
}

.newsletter .sib-button {
  flex: 1 1 100%;
}

.sib-form button,
.sib-form input {
  border-radius: 8px !important;
}

@media screen and (min-width: 800px) {
  .newsletter .sib-input {
    flex: 1 1 auto;
  }

  .newsletter .sib-button {
    flex: 1 1 auto;
  }
}
