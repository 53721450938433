.intro-feature-boxes {
  margin: 0 var(--spacing-sides) var(--spacing-08);
}

.intro-feature-boxes__text {
  margin: 0 auto var(--spacing-07);
  max-width: 736px;
  text-align: center;
}

.intro-feature-boxes__features {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
}

.intro-feature-boxes__feature {
  display: flex;
  margin: 8px;
  text-align: center;
  width: 100%;
  max-width: 352px;
}

/* csslint-disable-next-line */
.intro-feature-boxes__feature > div {
  width: 100%;
}
