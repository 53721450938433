.cookiebar {
  display: none;
}

.cookiebar--active {
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  bottom: 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  display: block;
  left: 0;
  padding: 24px 25px 32px;
  position: fixed;
  right: 0;
  z-index: 11;
}

@media screen and (min-width: 360px) {
  .cookiebar--active {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    bottom: 16px;
    left: 16px;
    right: 16px;
  }
}

@media screen and (min-width: 500px) {
  .cookiebar--active {
    bottom: 32px;
    left: unset;
    max-width: 328px;
    right: 32px;
  }
}

.cookiebar__close {
  background-color: var(--grey-03);
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6L6 18' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6 6L18 18' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  position: absolute;
  right: 16px;
  top: 16px;
  transition: background-color 0.1s ease-in;
  width: 40px;
}

.cookiebar__close:hover {
  background-color: var(--grey-04);
}

.cookiebar__headline {
  font-size: var(--fs--super-small);
  line-height: var(--lh--150);
  margin: 0 0 16px;
}

/* csslint-disable-next-line */
.cookiebar__text > p {
  color: var(--grey-06);
  font-size: var(--fs--ultra-small);
  line-height: var(--lh--150);
  margin: 0 0 20px;
  max-width: 258px;
}

/* csslint-disable-next-line */
.cookiebar__text a {
  color: var(--teal-06);
  text-decoration: none;
}

.cookiebar__buttons {
  display: flex;
}

.cookiebar__button {
  background-color: white;
  border: none;
  border-radius: 16px;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0);
  cursor: pointer;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--super-small);
  height: 48px;
  line-height: var(--lh--150);
  padding: 0;
}

.cookiebar__button--close {
  margin-right: 24px;
  min-width: 106px;
}

.cookiebar__button--agree {
  background-color: var(--grey-03);
  flex-grow: 1;
  transition: color 0.1s ease-in;
}

@media screen and (min-width: 500px) {
  .cookiebar__button--agree {
    max-width: 144px;
    min-width: 128px;
  }
}

.cookiebar__button:focus,
.cookiebar__button:hover {
  color: var(--teal-05);
}
