.media-rail {
  margin-bottom: var(--spacing-08);
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

.media-rail__content {
  padding: 0 var(--spacing-sides);
  position: relative;
}

.media-rail::after {
  background: linear-gradient(
    90.99deg,
    rgba(247, 247, 247, 0) 4.45%,
    #f7f7f7 95.14%
  );
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 96px;
  z-index: 1;
}

.media-rail__item {
  height: 380px;
  margin: 40px 0 40px 16px;
  width: 352px;
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .media-rail__item {
  position: absolute;
  top: 21px;
  opacity: 0;
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .media-rail__item:nth-child(1) {
  opacity: 1;
  top: 0;
  position: relative;
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .media-rail__item:nth-child(2) {
  opacity: 1;
  transform: translateX(calc((100% + 16px) * 1));
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .media-rail__item:nth-child(3) {
  opacity: 0.8;
  transform: translateX(calc((100% + 16px) * 2));
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .media-rail__item:nth-child(4) {
  opacity: 0.6;
  transform: translateX(calc((100% + 16px) * 3));
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .media-rail__item:nth-child(5) {
  opacity: 0.4;
  transform: translateX(calc((100% + 16px) * 4));
}

/* csslint-disable-next-line */
.rail:not(.flickity-enabled) .media-rail__item:nth-child(6) {
  opacity: 0.2;
  transform: translateX(calc((100% + 16px) * 5));
}
