:root {
  --grid-gap: 2.5vw;
  --grid-columns: repeat(4, minmax(0, 1fr));
}

@media screen and (min-width: 800px) {
  :root {
    --grid-gap: 4vw;
    --grid-columns: repeat(8, minmax(0, 1fr));
  }
}

@media screen and (min-width: 1280px) {
  :root {
    --grid-gap: 2.5vw;
    --grid-columns: repeat(12, minmax(0, 1fr));
  }
}
