.partner-list {
  margin-bottom: 128px;
}

@media screen and (min-width: 800px) {
  .partner-list__partners {
    padding: 16px;
  }
}

.partner-list__partner {
  display: flex;
  border-top: 2px solid var(--grey-04);
  padding: 32px 0;
}

@media screen and (min-width: 800px) {
  .partner-list__partner {
    padding: 48px 16px;
  }
}

.partner-list__partner-logo {
  padding-right: 16px;
}

@media screen and (min-width: 800px) {
  .partner-list__partner-logo {
    padding-right: 48px;
  }
}

@media screen and (min-width: 1200px) {
  .partner-list__partner-logo {
    padding-right: 80px;
  }
}

.partner-list__partner-logo-inner {
  padding: 16px;
  background-color: var(--grey-01);
  text-align: center;
  width: 96px;
}

@media screen and (min-width: 800px) {
  .partner-list__partner-logo-inner {
    padding: 32px;
    width: 192px;
  }
}

@media screen and (min-width: 1200px) {
  .partner-list__partner-logo-inner {
    width: 256px;
  }
}

/* csslint-disable-next-line */
.partner-list__partner-logo img {
  max-width: 100%;
  margin: 0 auto;
}

.partner-list__partner-name {
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--extra-small);
  font-weight: 500;
  line-height: var(--lh--150);
  margin-bottom: 32px;
  color: var(--grey-08);
}

.partner-list__partner-address-title {
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--micro);
  line-height: var(--lh--150);
  margin-bottom: 8px;
  color: var(--grey-06);
  text-transform: uppercase;
}

.partner-list__partner-address {
  font-family: var(--ff--sans);
  font-size: var(--fs--ultra-small);
  line-height: var(--lh--150);
  margin-bottom: var(--spacing-06);
  color: var(--grey-06);
}

/* csslint-disable-next-line */
.partner-list__partner-link a {
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--super-small);
  color: var(--grey-08);
  text-decoration: none;
}

/* csslint-disable-next-line */
.partner-list__partner-link a:hover {
  color: var(--teal-05);
}

/* csslint-disable-next-line */
.partner-list__partner-link a::after {
  content: " →";
}
