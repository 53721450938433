.courses-carousel {
  position: relative;
  display: block;
  margin-bottom: var(--spacing-08);
  padding: 0 var(--spacing-sides);
}

.courses-carousel::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  height: calc(var(--waves-height) * 3.5);
  background: url("../../../assets/img/backgrounds/waves.svg") no-repeat;
  background-position: left;
  background-size: auto 100%;
  z-index: -1;
}

.courses-carousel__inner {
  width: 100%;
  max-width: var(--width-regular);
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.courses-carousel__text {
  width: 100%;
  margin-bottom: var(--spacing-06);
}

.courses-carousel__images {
  flex: 1 1 100%;
  max-width: 100%;
  margin: var(--spacing-03) 0;
}

/* csslint-disable-next-line */
.courses-carousel__images .rail-courses {
  position: relative;
  overflow: hidden;
  padding-bottom: 30px;
}

/* csslint-disable-next-line */
.courses-carousel__images .rail-courses .flickity-page-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
}

.courses-carousel__list {
  flex: 1 1 100%;
  max-width: 100%;
  margin: var(--spacing-03) 0;
}

.courses-carousel__rail-item {
  width: 100%;
}

.courses-carousel__rail-item-image {
  width: 100%;
  height: auto;
}

/* csslint-disable-next-line */
.courses-carousel__list ul {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
  min-height: 100%;
}

/* csslint-disable-next-line */
.courses-carousel__list ul li {
  margin: 0.25em 0;
  padding: 0;
  font-size: var(--fs--extra-small);
}

.courses-carousel__list-item {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

/* csslint-disable-next-line */
.courses-carousel__list-item span {
  font-family: var(--ff--sans-medium);
}

/* csslint-disable-next-line */
.courses-carousel__list-item i {
  margin-right: 0.75em;
}

/* csslint-disable-next-line */
.courses-carousel__list-item i * {
  stroke: var(--grey-06);
}

/* csslint-disable-next-line */
.courses-carousel__list-item.active i * {
  stroke: var(--teal-05);
}

@media screen and (min-width: 800px) {
  .courses-carousel__images {
    flex: 1 1 55%;
    max-width: 55%;
    padding-right: 1em;
    margin: 0;
  }

  .courses-carousel__list {
    flex: 1 1 45%;
    max-width: 45%;
    padding-left: 1em;
    margin: 0;
  }
}

/* Prevent CLS */

/* csslint-disable-next-line */
.rail-courses:not(.flickity-enabled) .courses-carousel__rail-item {
  position: absolute;
  top: 0;
  opacity: 0;
}

/* csslint-disable-next-line */
.rail-courses:not(.flickity-enabled) .courses-carousel__rail-item:nth-child(1) {
  opacity: 1;
  top: 0;
  position: relative;
}
