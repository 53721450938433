.article-hero {
  background-color: white;
}

@media screen and (min-width: 800px) {
  .article-hero {
    display: block;
    margin-top: 0;
    position: relative;
  }
}

@media screen and (min-width: 800px) {
  .article-hero::after {
    background-image: url("data:image/svg+xml,%3Csvg width='1920' height='48' viewBox='0 0 1920 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1920 25.3503V48H0.00134277C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0C1737.74 0 1810.67 10.1541 1889.44 21.1217C1899.51 22.5244 1909.68 23.9405 1920 25.3503Z' fill='%23FFFFFF'/%3E%3Cpath d='M0.00132628 48C110.501 7.00092 239.001 0.000488281 388 0.000488281C480.87 0.000488281 566.862 9.96506 658.676 20.6044C764.048 32.8147 877.089 45.9138 1017 45.8587C1156.91 45.9138 1269.95 32.8146 1375.32 20.6041C1467.14 9.96468 1553.13 0 1646 0H0V48H0.00132628Z' fill='red' fill-opacity='0' /%3E%3Cpath d='M1920 25.3503C1909.68 23.9405 1899.51 22.5244 1889.44 21.1217C1810.67 10.1541 1737.74 0 1646 0H1920V25.3503Z' fill='%23F7F7F7'/%3E%3C/svg%3E%0A");
    background-position: left bottom;
    background-repeat: no-repeat;
    content: "";
    display: block;
    height: 48px;
    width: 100%;
    z-index: 2;
    background-size: cover;
    bottom: 0;
    position: absolute;
  }
}

.article-hero__content {
  display: flex;
  flex-direction: column-reverse;
}

@media screen and (min-width: 800px) {
  .article-hero__content {
    align-items: center;
    column-gap: var(--grid-gap);
    display: grid;
    grid-template-columns: var(--grid-columns);
    height: 100vh;
    justify-content: center;
    margin: 0 var(--spacing-sides);
    max-height: 650px;
    min-height: 650px;
    padding-bottom: 0;
  }
}

.article-hero__intro {
  margin: 0 var(--spacing-sides);
  order: 1;
  position: relative;
  text-align: center;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 800px) {
  .article-hero__intro {
    grid-column: 1 / 7;
    grid-row: 1;
    margin: 0;
    text-align: left;
  }
}

@media screen and (min-width: 1000px) {
  .article-hero__intro {
    padding-left: var(--spacing-05);
  }
}

@media screen and (min-width: 1280px) {
  .article-hero__intro {
    grid-column: 1 / 9;
  }
}

.article-hero__back {
  align-items: center;
  background-color: var(--grey-02);
  border-radius: 60px;
  display: inline-flex;
  margin-bottom: var(--spacing-08);
  padding: 12px;
  text-decoration: none;
  position: relative;
  padding-left: 40px;
  padding-right: 16px;
}

.article-hero__back:hover {
  background-color: var(--teal-05);
}

.article-hero__back::before {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml;base64,PHN2ZwogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKPgogIDxwYXRoCiAgICBkPSJNMTkgMTJINSIKICAgIHN0cm9rZT0iIzNEM0QzRCIKICAgIHN0cm9rZS13aWR0aD0iMS41IgogICAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogICAgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIKICAvPgogIDxwYXRoCiAgICBkPSJNMTIgNUw1IDEyTDEyIDE5IgogICAgc3Ryb2tlPSIjM0QzRDNEIgogICAgc3Ryb2tlLXdpZHRoPSIxLjUiCiAgICBzdHJva2UtbGluZWNhcD0icm91bmQiCiAgICBzdHJva2UtbGluZWpvaW49InJvdW5kIgogIC8+Cjwvc3ZnPgo=");
  content: "";
  height: 24px;
  margin-right: 12px;
  width: 24px;
}

.article-hero__back:hover::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZwogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKPgogIDxwYXRoCiAgICBkPSJNMTkgMTJINSIKICAgIHN0cm9rZT0iI0ZGRkZGRiIKICAgIHN0cm9rZS13aWR0aD0iMS41IgogICAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogICAgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIKICAvPgogIDxwYXRoCiAgICBkPSJNMTIgNUw1IDEyTDEyIDE5IgogICAgc3Ryb2tlPSIjRkZGRkZGIgogICAgc3Ryb2tlLXdpZHRoPSIxLjUiCiAgICBzdHJva2UtbGluZWNhcD0icm91bmQiCiAgICBzdHJva2UtbGluZWpvaW49InJvdW5kIgogIC8+Cjwvc3ZnPgo=");
}

.article-hero__back-text {
  color: var(--grey-08);
  line-height: 0.9;
}

.article-hero__back:hover .article-hero__back-text {
  color: var(--grey-01);
}

/* csslint-disable-next-line */
.article-hero__back + .text-blockarticle-hero__text-block {
  width: 100%;
}

.article-hero__date {
  color: var(--grey-05);
  display: block;
  font-family: var(--ff--sans-medium);
  font-size: var(--fs--ultra-small);
  letter-spacing: 0.06em;
  margin: 24px 0 50px;
  text-transform: uppercase;
  width: 100%;
}

.article-hero__authors-container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 50px;
}

@media screen and (min-width: 800px) {
  .article-hero__authors-container {
    padding-right: 3em;
    width: auto;
    margin-bottom: 0;
  }
}

.article-hero__share-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

@media screen and (min-width: 800px) {
  .article-hero__share-container {
    width: auto;
  }
}

.article-hero__share-title {
  margin-right: 15px;
}

.article-hero__share-icons {
  display: flex;
  align-items: center;
}

.article-hero__share-icons-link {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.article-hero__share-icons-icon {
  height: 26px;
  width: 26px;
}

/* csslint-disable-next-line */
.article-hero__share-icons-link svg {
  fill: var(--teal-04);
}

/* csslint-disable-next-line */
.article-hero__share-icons-link:hover svg {
  fill: var(--teal-05);
}

.article-hero__authors-pictures {
  align-items: center;
  display: flex;
}

.article-hero__author-picture {
  border: 4px solid white;
  border-radius: 50%;
  height: 54px;
  width: 54px;
}

.article-hero__author-picture:first-child {
  position: relative;
  z-index: 2;
}

.article-hero__author-picture:nth-child(2) {
  margin-left: -14px;
}

.article-hero__authors-names {
  display: block;
  margin-left: var(--spacing-03);
}

@media screen and (max-width: 767px) {
  .article-hero__authors-names {
    line-height: 21px;
  }
}

/* csslint-disable-next-line */
.article-hero__authors-names strong {
  font-family: var(--ff--sans-medium);
  font-weight: 500;
}

.article-hero__image-container {
  position: relative;
  height: 50vh;
  margin-top: var(--spacing-08);
}

@media screen and (min-width: 800px) {
  .article-hero__image-container {
    height: 100%;
    margin-top: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 580px;
  }
}

@media screen and (min-width: 800px) {
  .article-hero__image-container::before {
    left: 0;
    display: block;
    z-index: 1;
    content: "";
    position: absolute;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 30%,
        rgba(255, 255, 255, 0) 100.08%
      ),
      linear-gradient(90deg, #ffffff 13.31%, rgba(255, 255, 255, 0) 100%);
    bottom: 0;
    height: 100%;
    top: 0;
    width: 50%;
  }
}

.article-hero__image {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
