.embed {
  margin-bottom: var(--spacing-08);
  position: relative;
}

.embed__content {
  margin: 0 var(--spacing-sides);
}

.embed__text {
  margin: 0 auto var(--spacing-06);
  max-width: 736px;
  text-align: center;
}

.embed__code {
  position: relative;
  width: 100%;
}

.embed__video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

/* csslint-disable-next-line */
.embed__video-container iframe,
/* csslint-disable-next-line */
.embed__video-container object,
/* csslint-disable-next-line */
.embed__video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
