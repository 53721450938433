.footer {
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  width: 100%;
}

/* csslint-disable-next-line */
.footer.group {
  padding: 0;
  margin-bottom: 0;
}

/* csslint-disable-next-line */
.footer.group.group--has-waves-top {
  margin-top: var(--waves-height);
}

.footer__background {
  background-color: var(--teal-07);
}

.footer__content {
  margin: 0 var(--spacing-sides) 0;
  padding: var(--spacing-06) 0;
  text-align: center;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 800px) {
  .footer__content {
    column-gap: var(--grid-gap);
    row-gap: var(--grid-gap);
    display: grid;
    grid-column: span 3;
    grid-template-columns: var(--grid-columns);
    text-align: left;
  }
}

@media screen and (min-width: 1024px) {
  .footer__content {
    column-gap: var(--grid-gap);
    display: grid;
    grid-column: span 3;
    grid-template-columns: var(--grid-columns);
    text-align: left;
  }
}

.footer__logo {
  display: inline-block;
  margin-top: var(--spacing-08);
  margin-bottom: var(--spacing-08);
}

@media screen and (min-width: 800px) {
  .footer__logo {
    grid-column: span 2;
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .footer__logo {
    grid-column: span 2;
  }
}

@media screen and (min-width: 1280px) {
  .footer__logo {
    grid-column: span 3;
  }
}

.footer__newsletter {
  position: relative;
  width: 100%;
  order: -1;
}

@media screen and (min-width: 800px) {
  .footer__newsletter {
    grid-column: span 6;
    margin-bottom: 0;
    order: unset;
  }
}

@media screen and (min-width: 1024px) {
  .footer__newsletter {
    grid-column: span 6;
  }
}

@media screen and (min-width: 1280px) {
  .footer__newsletter {
    grid-column: span 9;
  }
}

.footer__side-links {
  font-size: var(--fs--small);
  line-height: var(--lh--150);
  margin-bottom: var(--spacing-08);
}

@media screen and (min-width: 800px) {
  .footer__side-links {
    align-self: unset;
    grid-column: span 2;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .footer__side-links {
    grid-column: span 2;
  }
}

@media screen and (min-width: 1280px) {
  .footer__side-links {
    grid-column: span 3;
  }
}

.footer__side-links-list {
  margin: 0;
  padding: 0;
}

.footer__side-links-list-item {
  list-style: none;
  margin-bottom: 1.3em;
}

.footer__side-links-list-item:last-child {
  margin-bottom: 0;
}

.footer__side-links-list-item-link {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: color 0.1s ease-in;
}

.footer__side-links-list-item-link:hover {
  color: var(--teal-04);
}

.footer__nav {
  font-size: var(--fs--super-small);
  line-height: var(--lh--150);
  margin-bottom: var(--spacing-08);
}

@media screen and (min-width: 800px) {
  .footer__nav {
    grid-column: span 6;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .footer__nav {
    grid-column: span 6;
  }
}

@media screen and (min-width: 1280px) {
  .footer__nav {
    grid-column: span 9;
  }
}

.footer__nav-subtitle {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 0 var(--spacing-04) 0 0;
  position: relative;
  color: rgba(255, 255, 255, 0.8);
}

@media screen and (min-width: 1024px) {
  .footer__nav-subtitle {
    margin-bottom: 1.4em;
  }
}

.footer__nav-subtitle::after {
  background-image: var(--arrow-white);
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  height: 100%;
  position: absolute;
  right: 0.3em;
  top: 0;
  width: 8px;
}

@media screen and (min-width: 800px) {
  .footer__nav-subtitle::after {
    display: none;
  }
}

.footer__nav-subtitle--active::after {
  transform: rotate(180deg);
}

.footer__nav-list {
  margin: 0;
  padding: 0;
}

.footer__nav-list-item--first-level .footer__nav-list {
  height: 0;
  overflow: hidden;
}

@media screen and (min-width: 800px) {
  .footer__nav-list-item--first-level .footer__nav-list {
    height: auto;
    overflow: unset;
  }
}

.footer__nav-list-item--first-level .footer__nav-list--active {
  height: auto;
  overflow: unset;
}

@media screen and (min-width: 800px) {
  .footer__nav-list {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .footer__nav-list--first-level {
    column-gap: var(--grid-gap);
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.footer__nav-list-item {
  list-style: none;
}

.footer__nav-list-item--first-level {
  margin-bottom: 2em;
}

.footer__nav-list-item--first-level:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 800px) {
  .footer__nav-list-item--first-level {
    margin-bottom: 2em;
  }
}

.footer__nav-list-item--second-level {
  margin-bottom: 1em;
}

.footer__nav-list-item:last-child {
  margin-bottom: 0;
}

.footer__nav-list-item-link {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: color 0.1s ease-in;
}

.footer__nav-list-item-link:hover {
  color: var(--teal-04);
}

.footer__social-media-nav {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  margin-bottom: var(--spacing-08);
}

@media screen and (min-width: 800px) {
  .footer__social-media-nav {
    grid-column: span 8;
    justify-content: left;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .footer__social-media-nav {
    grid-column: span 8;
  }
}

@media screen and (min-width: 1280px) {
  .footer__social-media-nav {
    grid-column: span 12;
  }
}

.footer__social-media-list {
  display: flex;
  margin: 0;
  padding: 0;
}

.footer__social-media-list-item {
  list-style: none;
  margin-right: 1.9em;
}

.footer__social-media-list-item:last-child {
  margin-right: 0;
}

.footer__social-media-icon {
  fill: white;
  height: 27px;
  transition: fill 100ms ease-in-out;
  width: 27px;
}

.footer__help-emergency {
  margin: 0 auto var(--spacing-08);
  max-width: 296px;
}

@media screen and (min-width: 800px) {
  .footer__help-emergency {
    align-items: flex-end;
    display: flex;
    grid-column: 3 / span 3;
    margin: 0;
    max-width: none;
  }
}

@media screen and (min-width: 1024px) {
  .footer__help-emergency {
    grid-column: 3 / span 3;
  }
}

@media screen and (min-width: 1280px) {
  .footer__help-emergency {
    grid-column: 4 / span 4;
  }
}

.footer__help-emergency-title {
  font-size: var(--fs--super-small);
  line-height: var(--lh--150);
  margin: 0 0 1em;
  color: rgba(255, 255, 255, 0.8);
}

/* csslint-disable-next-line */
.footer__help-emergency > div > p {
  font-size: var(--fs--ultra-small);
  line-height: var(--lh--150);
  margin: 0 0 1em;
  padding: 0;
}

/* csslint-disable-next-line */
.footer__help-emergency > div > p:last-child {
  margin-bottom: 0;
}

/* csslint-disable-next-line */
.footer__help-emergency > div > p a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: all 0.1s ease-in;
}

/* csslint-disable-next-line */
.footer__help-emergency > div > p a:hover {
  color: var(--teal-04);
}

.footer__test-winner,
.footer__brand-of-the-year,
.footer__iso-certification {
  margin-bottom: var(--spacing-08);
}

.footer__iso-certification {
  grid-column-start: 6;
}

.footer__test-winner {
  grid-column-start: 7;
}

@media screen and (min-width: 800px) {
  .footer__test-winner,
  .footer__brand-of-the-year,
  .footer__iso-certification {
    margin-bottom: 0;
  }

  .footer__iso-certification {
    justify-self: center;
  }
}

.footer__breadcrumb {
  font-size: var(--fs--micro);
  line-height: var(--lh--150);
  margin-bottom: var(--spacing-03);
}

@media screen and (min-width: 800px) {
  .footer__breadcrumb {
    grid-column: span 2;
    margin-bottom: 0;
    margin-top: var(--spacing-05);
  }
}

@media screen and (min-width: 1024px) {
  .footer__breadcrumb {
    grid-column: span 2;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1280px) {
  .footer__breadcrumb {
    grid-column: span 3;
  }
}

.footer__breadcrumb-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 800px) {
  .footer__breadcrumb-list {
    grid-column: 1 / span 3;
    justify-content: left;
    flex-wrap: nowrap;
    margin-bottom: 0;
    overflow-x: hidden;
    position: relative;
  }
}

@media screen and (min-width: 800px) {
  .footer__breadcrumb-list::after {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    width: 1em;
    background: linear-gradient(
      90deg,
      rgba(0, 64, 60, 0) 0%,
      rgba(0, 64, 60, 1) 100%
    );
  }
}

.footer__breadcrumb-list-item {
  list-style: none;
}

@media screen and (min-width: 800px) {
  .footer__breadcrumb-list-item {
    flex-shrink: 0;
  }
}

.footer__breadcrumb-list-item::after {
  color: white;
  content: "›";
  margin-left: 0.1em;
  margin-right: 0.3em;
  opacity: 0.7;
}

.footer__breadcrumb-list-item:last-child::after {
  content: "";
}

.footer__breadcrumb-list-item-link {
  color: white;
  opacity: 0.7;
  text-decoration: none;
  transition: all 0.1s ease-in;
}

.footer__breadcrumb-list-item-link:hover {
  color: var(--teal-04);
  opacity: 1;
}

.footer__imprint {
  font-size: var(--fs--micro);
  line-height: var(--lh--150);
  margin-bottom: var(--spacing-03);
}

@media screen and (min-width: 800px) {
  .footer__imprint {
    grid-column: span 3;
    margin-bottom: 0;
    margin-top: var(--spacing-05);
  }
}

@media screen and (min-width: 1024px) {
  .footer__imprint {
    grid-column: span 3;
  }
}

@media screen and (min-width: 1280px) {
  .footer__imprint {
    grid-column: span 4;
  }

  .footer__iso-certification {
    justify-self: end;
    grid-column-start: 10;
  }

  .footer__test-winner {
    grid-column-start: 11;
  }
}

.footer__imprint-list {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 800px) {
  .footer__imprint-list {
    justify-content: left;
  }
}

.footer__imprint-list-item {
  list-style: none;
}

.footer__imprint-list-item::after {
  color: white;
  content: "|";
  margin-left: 0.3em;
  margin-right: 0.6em;
  opacity: 0.7;
}

.footer__imprint-list-item:last-child::after {
  display: none;
}

.footer__imprint-list-item:first-child {
  padding-left: 0;
}

.footer__imprint-list-item-link {
  color: white;
  opacity: 0.7;
  text-decoration: none;
  transition: all 0.1s ease-in;
}

.footer__imprint-list-item-link:hover {
  color: var(--teal-04);
  opacity: 1;
}

.footer__copyright {
  color: white;
  font-size: var(--fs--micro);
  line-height: var(--lh--150);
  margin: 0;
  opacity: 0.7;
  padding: 0;
  margin-bottom: var(--spacing-03);
}

@media screen and (min-width: 800px) {
  .footer__copyright {
    grid-column: span 3;
    text-align: right;
    margin-bottom: 0;
    margin-top: var(--spacing-05);
  }
}

@media screen and (min-width: 1024px) {
  .footer__copyright {
    grid-column: span 3;
  }
}

@media screen and (min-width: 1280px) {
  .footer__copyright {
    grid-column: span 5;
  }
}
