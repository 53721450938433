.callout-box {
  margin-bottom: 128px;
}

.callout-box__box {
  background-color: var(--grey-01);
  border-radius: 8px;
  padding: 32px;
}

@media screen and (min-width: 800px) {
  .callout-box__box {
    padding: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.callout-box__message {
  font-size: var(--fs--super-small);
  line-height: var(--lh--150);
}

@media screen and (min-width: 800px) {
  .callout-box__message {
    margin-left: 32px;
  }
}

.callout-box__icon {
  width: 48px;
  min-width: 48px;
  height: 48px;
  margin: 12px 0;
}

@media screen and (min-width: 800px) {
  .callout-box__icon {
    margin: 12px;
  }
}

/* csslint-disable-next-line */
.callout-box__message a {
  color: var(--grey-07);
  border-bottom: 2px solid var(--teal-04);
  text-decoration: none;
  transition: color var(--link-transition) ease-in-out;
}

/* csslint-disable-next-line */
.callout-box__message a:hover {
  color: var(--teal-05);
}
